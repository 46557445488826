<!-- components/TawkToChat.vue -->
<template>
    <!-- <div id="tawkto-chat"></div> -->
    <h1>hii</h1>
  </template>
  
  <!-- <script>
  export default {
    mounted() {
      const s1 = document.createElement("script");
      const s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/661e1b5f65e07e/1hnlmkj";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    }
  };
  </script> -->
  