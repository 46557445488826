<template>
  <v-app v-if="loading">
    <v-main>
      <div   v-if="domaintrue"  style="    background-color: #005672;
    color: white;
    padding: 10px 50px;
    font-size: 16px;
   ">
       <div>

You have reached to the  {{countryname}} Site of 
<span style="text-transform: capitalize;">{{ searchDomaintitle  }}</span>. 
Continue to the {{ visitcountryname }} Site at 
<span @click="routerDomain" style=" border-bottom: 1px solid;cursor: pointer;font-weight:500">
         {{ dataDomain }}
        </span>

       </div>
      </div>
      <div v-if="datashow">
        <div id="navbar">
          <component :is="selectedHeader" />
        </div>
        <div class="bg-pages">
          <router-view />
        </div>

        <div id="food">
          <component :is="selectedFooter" />
        </div>
      </div>
    </v-main>
</v-app>
</template>


<script>
import axios from "axios";
import headerCom1 from '@/components/headerCom1.vue'
import aboutUs from '@/components/aboutUs.vue'
import country1 from "@/coundtrycode.json";
import CryptoJS from 'crypto-js';


import headerCom from '@/components/headerCom.vue'
import headerCom2 from '@/components/headerCom2.vue'
import headerCom3 from '@/components/headerCom3.vue'

import footerCom from "@/components/footerCom.vue"
import footerCom1 from '@/components/footerCom1.vue'
import footerCom2 from '@/components/footerCom2.vue'
import footerCom3 from '@/components/footerCom3.vue'

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';


export default {
  name: 'App',
  components: {
    headerCom1,
    aboutUs,
    dataDomain: "",
    dataDomainData:"",
    SearchDomain: "",
  searchDomaintitle:"",
    headerCom,
    headerCom2,
    headerCom3,
domaintrue:false,
    footerCom,
    footerCom1,
    footerCom2,
    footerCom3,
  },
  data: () => ({
    headerStyles: ['headerCom', 'headerCom1', 'headerCom2', 'headerCom3'],
    footerStyles: ['footerCom', 'footerCom1', 'footerCom2', 'footerCom3'],
    navbar: true,
    food: true,
    datashow: false,
    countryname:"",
    selectedHeader: 'headerCom1',
    selectedFooter: 'footerCom',
    loading: false,
visitcountryname:"",
tawktoApi:"",

    configReq: {
      "service_type": "GetPortalConfigRQ",
      "api_ver": "1",
      "payload": {
        "domain_name":"bmtpre.wistirna.com",
        "env": "CERT",
        "language": "EN",
        "token": "jsdjOJDKJ7675"
      }
    },

    configUser_Id:"",


  }),

  methods: {


    routerDomain() {
      console.log(this.dataDomain ,'this.SearchDomain this.SearchDomain ')
      if (this.dataDomainData == "buymytrip.com") {
    window.location.href = "https://buymytrip.com";
      }
      else {
     window.location.href = "https://buymytrip.ca";
  }
},
    indexHeader() {
        const getConfigData = getConfigDataFromLocalStorage();

      if(getConfigData){
        let portalTitle = getConfigData.payload.portal_configuration.site_title
      let portalFavicon = getConfigData.payload.portal_configuration.favicon


      let pluginTawkto = ""
      pluginTawkto = getConfigData.payload.portal_configuration.menus.enabled.plugins
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')
        if (pluginTawkto.includes("tawkto")) {
          this.tawktoApi = getConfigData.payload.portal_configuration.API_endpoints.tawkto
        }
        else {
          this.tawktoApi = ""
        }

      document.getElementById('siteTitle').innerText = portalTitle
      document.getElementById('faviconPortal').href = portalFavicon


      // Tawk.to code
      const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = this.tawktoApi; // Assign the API endpoint to s1.src
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
      }

    },


    envPortal() {

      let hostname = window.location.hostname
      // console.log(hostname, "hshshshs....")
      if (hostname=="localhost") {
             console.log(hostname, "localhost....")
      }
      else {
        this.configReq.payload.domain_name = hostname
      }
    


      axios.get("https://dl.buymytrip.com/api/getportalconfig", {
        params: this.configReq,
        headers: {
          "Content-Type": "application/json",
        },
      })

        .then((response) => {
          if (response) {
            this.loading = true
            let configResponse = response.data
            this.dataDomainData= configResponse.payload.portal_configuration.geoip_location.country_domain.replace("https://", "") 
            this.dataDomain =this.dataDomainData.charAt(0).toUpperCase() + this.dataDomainData.slice(1);
            this.SearchDomain = this.configReq.payload.domain_name
            this.searchDomaintitle=configResponse.payload.portal_configuration.site_title
            this.countryname = configResponse.payload.portal_configuration.country.name
            let $dataname=configResponse.payload.portal_configuration.geoip_location.country_iso_code
            // console.log(this.dataDomain,this.SearchDomain, 'response.data')
            country1.forEach(v => {
              if ($dataname=="US" || $dataname=="CA") {
   if (v.code == $dataname) {
                this.visitcountryname=v.name
  }
              }
              else {
                this.visitcountryname="Global"
              }
   
            })


            // console.log(t$data,this.SearchDomain,'this.dataDomain')
            if (this.dataDomainData.includes(this.SearchDomain)) {
              console.log('pppppppaduhdh')
                this.domaintrue=false
            }
            else {
               this.domaintrue=true
               }

            // Encrypt data using CryptoJS
          const secretKey = 'portal_config_datas';
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(configResponse), secretKey).toString();
          
          localStorage.setItem('portalData', encryptedData);


            // localStorage.setItem("portalData", JSON.stringify(configResponse))

            if (configResponse) {
              this.datashow = true
            }
            this.indexHeader();
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          alert('Something went difficulties...')
          this.loading = false
        });

    },



    // getRandomChars(length) {
    //   let result = "";
    //   for (let i = 0; i < length; i++) {
    //     const randomIndex = Math.floor(Math.random() * this.strResult.length);
    //     result += this.strResult[randomIndex];
    //   }
    //   console.log(result, "result");
    //   return result;
    // },
    // getRandomInt(min, max) {
    //   return Math.floor(Math.random() * (max - min + 1)) + min;
    // },

    // generatePortalConfig_id() {
    //   const randomChars1 = this.getRandomChars(3);
    //   const randomNum = this.getRandomInt(10, 99);
    //   const randomChars2 = this.getRandomChars(3);

    //   this.configUser_Id = randomChars1 + randomNum + randomChars2;
    //   console.log(this.configUser_Id, 'configUser_IdconfigUser_Id')
    // },


    // async envPortal() {
    //   try {
    //     const response = await axios.get("https://dl.buymytrip.com/api/getportalconfig", {
    //       params: this.configReq,
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     });

    //     if (response) {
    //       let configResponse = response.data;
    //       console.log(configResponse, 'sdfdhfiadfadh');
    //       localStorage.setItem('portalData', JSON.stringify(configResponse));
    //       this.indexHeader();
    //     }
    //   } catch (error) {
    //     console.log("Error fetching data:", error);
    //     alert('Something went wrong...');
    //   }
    // },
  },


  created() {
    this.envPortal();
    // this.getRandomChars();

    setTimeout(() => {
      // this.indexHeader();
    }, 2000)
  },




  watch: {
    '$route.path': {
      immediate: true,
      handler(newValue) {
        if (newValue === '/loader') {
          this.navbar = false;
          this.food = false
        }
        else {
          this.navbar = true;
          this.food = true
        }
        // console.log(newValue, '4545');
      },
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&family=Poppins:wght@300;400;500&display=swap');

/* .bg-pages {
background: rgb(230, 217, 26);
background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193));
} */


* {
  font-family: 'Poppins', sans-serif;
}



#navbar {
  display: block;
}

#food {
  display: block;
}

/* .bg-pages{
    background-image:url('./assets/bmbg2.svg');
  } */



/* .bg-pages{
    background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);
  } */
/* .bg-pages { */

/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* background: rgb(230, 217, 26); */
/* background: linear-gradient(277deg, rgba(230,217,26,1) 0%, rgba(51,175,167,1) 58%, rgba(253,45,164,1) 100%); */
/* background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193)); */

/* margin-top:70px; */
/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* } */
</style>