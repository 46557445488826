<template>
  <div v-if="searchresult" class="mb-4">
    <div class="bg-image" style="padding: 20px 0px !important">
      <div class="container">
        <v-card rounded="3" style="overflow: unset">
          <v-card rounded="3" style="padding: 10px 10px; overflow: unset">
            <v-card rounded="3" class="round-mode" v-if="roundModify">
              <div class="row d-flex">
                <div class="col-lg-5 col-12 d-flex justify-content-evenly">
                  <div>
                    <h5 class="text-center">{{ tripData.from }}</h5>
                    <p class="text-center">{{ tripData.dedate }}</p>
                  </div>
                  <v-icon size="30">mdi-swap-horizontal</v-icon>
                  <div>
                    <h5 class="text-center">{{ tripData.to }}</h5>
                    <p class="text-center">{{ tripData.redate }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">Class Type</h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ tripData.class }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">Passengers</h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls > 0"
                      >{{ tripData.aduls }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls1 > 0"
                      >, {{ tripData.aduls1 }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls2 > 0"
                      >, {{ tripData.aduls2 }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <v-btn
                    color="#ff6b0a"
                    @click="modifySection = !modifySection"
                    style="color: white"
                    >Modify
                    <v-icon size="25" class="ps-2">
                      {{
                        modifySection
                          ? "mdi-arrow-up-drop-circle-outline"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>

            <v-card
              rounded="0"
              class="oneway-mode"
              style="padding: 10px 10px; overflow: unset"
              v-if="onewayModify"
            >
              <div class="row d-flex">
                <div class="col-lg-5 col-12">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-evenly">
                      <div>
                        <h5 class="text-center">{{ tripData.from }}</h5>
                      </div>
                      <v-icon size="30">mdi-arrow-right</v-icon>
                      <div>
                        <h5 class="text-center">{{ tripData.to }}</h5>
                      </div>
                    </div>

                    <div class="col-12 d-flex justify-content-evenly">
                      <p class="text-center m-0 mt-2">
                        {{ tripData.dedate }} / Oneway
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">Class Type</h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ tripData.class }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">Passengers</h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls > 0"
                      >{{ tripData.aduls }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls1 > 0"
                      >, {{ tripData.aduls1 }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls2 > 0"
                      >, {{ tripData.aduls2 }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <v-btn color="#ff6b0a" @click="modifySection = !modifySection"
                    >Modify
                    <v-icon size="25" class="ps-2">
                      {{
                        modifySection
                          ? "mdi-arrow-up-drop-circle-outline"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>

            <v-card rounded="3" class="round-mode" v-if="multiModify">
              <div class="row d-flex">
                <div class="col-lg-5 col-12 d-flex justify-content-evenly">
                  <div>
                    <h5 class="text-center">{{ getMultifrom(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate1(multi_city) }}</p>
                    <!-- <h5 class="text-center">{{ getMultifrom(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate1(multi_city) }}</p> -->
                  </div>
                  <div class="d-flex flex-column mt-3">
                    <v-icon size="18" style="transform: rotate(45deg)"
                      >mdi-arrow-top-right</v-icon
                    >
                    <v-icon size="18" style="transform: rotate(45deg)"
                      >mdi-arrow-top-right</v-icon
                    >
                    <!-- <v-icon size="18" style="transform: rotate(45deg);">mdi-arrow-top-right</v-icon> -->
                  </div>
                  <div>
                    <h5 class="text-center">{{ getMultiTo(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate2(multi_city) }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">Class Type</h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ getMultiClass(multi_city) }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">Passengers</h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiAdult(multi_city) > 0"
                    >
                      {{ getMultiAdult(multi_city) }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiChild(multi_city) > 0"
                      >, {{ getMultiChild(multi_city) }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiInfrant(multi_city) > 0"
                      >, {{ getMultiInfrant(multi_city) }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <v-btn
                    color="#ff6b0a"
                    @click="modifySection = !modifySection"
                    style="color: white"
                    >Modify
                    <v-icon size="25" class="ps-2">
                      {{
                        modifySection
                          ? "mdi-arrow-up-drop-circle-outline"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-card>

          <v-card
            rounded="0"
            style="padding: 10px 10px; overflow: unset"
            v-if="modifySection"
          >
            <div class="row" align="center">
              <h6 style="color: #ff5400">FIND CHEAP TICKETS-SAVE BIG</h6>
            </div>
            <div class="row mt-1 px-3 px-sm-0">
              <div class="col-lg-4 col-md-6">
                <v-radio-group
                  inline
                  color="FFB80A"
                  v-model="red"
                  style="position: relative; top: 0px"
                >
                  <v-radio
                    label="Round Trip"
                    value="1"
                    class="normal-radio"
                    :class="{ radio: red1 == 1 }"
                    @click="act(1)"
                  ></v-radio>
                  <v-radio
                    label="Oneway"
                    value="2"
                    class="normal-radio"
                    :class="{ radio: red1 == 2 }"
                    @click="act(2)"
                  ></v-radio>
                  <!-- <v-radio label="Multi-city" value="3" class="normal-radio" :class="{ radio: red1 == 3 }"
                    @click="act(3)"></v-radio> -->
                </v-radio-group>
              </div>

              <div class="col-lg-5 col-md-6 mt-3 mt-sm-0">
                <div class="row justify-content-sm-start justify-center">
                  <!-- <div class="col-1">
                  <span style="
                      font-size: 18px;
                      color: gray;
                      position: relative;
                      top: 5px;
                    ">
                    <v-icon> mdi-account-outline </v-icon>
                  </span>
                </div> -->
                  <div class="col-lg-10 col-12">
                    <div class="d-flex input-styles">
                      <span
                        class="d-flex me-2"
                        style="font-size: 18px; color: gray"
                      >
                        <v-icon> mdi-account-outline </v-icon>
                      </span>

                      <v-select
                        v-model="selectedCountry"
                        :items="countries"
                        ref="countrySelect"
                        variant="underlined"
                        class="select-1"
                      >
                        <!-- <template #item="{ item }"> -->
                        <template #item="{}">
                          <div class="dropdown_section" style="cursor: pointer">
                            <div
                              class="row d-flex align-center my-2 mx-1"
                              style="height: 37px"
                              @click.stop
                            >
                              <div class="col-3" style="width: 38%">
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >Adult</span
                                >
                              </div>
                              <div class="col-3 px-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-human-male-female
                                </v-icon>
                              </div>
                              <div
                                class="col-6 adult"
                                style="width: 50%; height: auto"
                              >
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg1()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls }}</div>
                                  </div>
                                  <div class="col-4" @click="add1()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div>{{ item.name }}</div> -->
                            </div>

                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div
                                class="col-4 d-flex flex-column pe-0"
                                style="width: 38%"
                              >
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >Children</span
                                >
                                <span style="font-size: 11px; color: #4e4d4d"
                                  >2-11(yrs)</span
                                >
                              </div>
                              <div class="col-2 px-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-human-child
                                </v-icon>
                              </div>
                              <div class="col-6 adult" style="width: 50%">
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg2()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls1 }}</div>
                                  </div>
                                  <div class="col-4" @click="add2()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div>{{ item.name }}</div> -->
                            </div>

                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div
                                class="col-5 d-flex flex-column pe-0"
                                style="width: 38%"
                              >
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >Lap Infant</span
                                >
                                <span style="font-size: 11px; color: #4e4d4d"
                                  >2(yrs)</span
                                >
                              </div>

                              <div class="col-1 ps-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-baby-buggy
                                </v-icon>
                              </div>
                              <div class="col-6 adult" style="width: 50%">
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg3()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls2 }}</div>
                                  </div>
                                  <div class="col-4" @click="add3()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div class="col-4">
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: grey;
                                  "
                                  >Cabin</span
                                >
                              </div>

                              <div class="col-2" align="center">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-seat-passenger
                                </v-icon>
                              </div>
                              <div class="col-6">
                                <div
                                  class="input-styles-1 select-option select-2"
                                >
                                  <v-select
                                    v-model="classType"
                                    ref="mySelect"
                                    @click="selectFunction()"
                                    :items="[
                                      'Economy',
                                      'Business',
                                      'First Class',
                                    ]"
                                    variant="underlined"
                                    style="color: gray"
                                  ></v-select>
                                </div>
                              </div>
                            </div>

                            <div
                              align="end"
                              class="mt-4 pe-3 d-flex justify-end"
                              @click="
                                (travels = false), localdone(), closeDropdown()
                              "
                            >
                              <v-btn
                                rounded="3"
                                color="#ff6b0a"
                                width="100px"
                                height="30px"
                                style="color: white"
                              >
                                Done
                              </v-btn>
                            </div>
                          </div>
                        </template>

                        <template #selection="{}">
                          <div class="pax-fonts">
                            <span>{{ aduls }} Adt</span>
                            <span v-if="aduls1 > 0">, {{ aduls1 }} Ch</span>
                            <span v-if="aduls2 > 0">, {{ aduls2 }} Inf</span>

                            <span>, {{ classType }}</span>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="rount">
              <div class="row my-4 px-3 gx-0">
                <div class="col-lg-11 col-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-takeoff</v-icon>
                        </span>
                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.from"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeFrom(oneway.from)"
                            @click="fromClick(oneway)"
                            @complete="search"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                            ref="fromAutoComplete"
                          >
                            <template #option="slotProps">
                              <div
                                class="flex align-items-center dropdown-box"
                                @click="focus1()"
                              >
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">From</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromErrorMessage"
                          >Departure is required</span
                        >
                        <br v-if="fromErrorMessage && maximumletter" />
                        <span class="p-error" v-if="maximumletter"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-landing</v-icon>
                        </span>

                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.to"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeTo()"
                            @click="toClick(oneway)"
                            @complete="search"
                            ref="secondAutoComplete"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                          >
                            <template #option="slotProps">
                              <div class="dropdown-container">
                                <div
                                  class="flex align-items-center dropdown-box"
                                  @click="focus2()"
                                >
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">To</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toErrorMessage"
                          >Arrival is required</span
                        >
                        <br v-if="toErrorMessage && maximumletter1" />
                        <span class="p-error" v-if="maximumletter1"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>

                        <span class="p-float-label">
                          <span
                            :class="clicked1 ? 'label-top' : 'datepicker-label'"
                          >
                            Departure
                          </span>

                          <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.dedate"
                            :min-date="
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                0,
                                0,
                                0
                              )
                            "
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate(),
                                23,
                                59,
                                59
                              )
                            "
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            :multi-calendars="multiCalender"
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            :month-change-on-scroll="false"
                            class="px-0"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromDateError"
                          >Dep. Date is required</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mt-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <span
                            :class="
                              clicked2 ? 'label-top' : 'datepicker-label2'
                            "
                          >
                            Return
                          </span>
                          <VueDatePicker
                            ref="dpRef2"
                            v-model="oneway.redate"
                            :format="dateFormat"
                            :min-date="oneway.dedate"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="toDateChanged"
                            :multi-calendars="multiCalender"
                            :on-click-outside="ClickOutReturn"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toDateError"
                          >Arr. Date is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-1 mt-3 mt-lg-0 d-flex align-center justify-center justify-content-lg-start magnify-btn"
                >
                  <div @click="sended()">
                    <v-btn
                      color="#ff6b0a"
                      height="40px"
                      width="80px"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                    >
                      <v-icon color="white">mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="Oneway">
              <div class="row my-4 px-3 gx-0">
                <div class="col-lg-11 col-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-takeoff</v-icon>
                        </span>
                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.from"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeFrom()"
                            @click="fromClick(oneway)"
                            @complete="search"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                          >
                            <template #option="slotProps">
                              <div class="flex align-items-center dropdown-box">
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">From</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromErrorMessage"
                          >Departure is required</span
                        >
                        <br v-if="fromErrorMessage && maximumletter" />
                        <span class="p-error" v-if="maximumletter"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-landing</v-icon>
                        </span>

                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.to"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeTo()"
                            @click="toClick(oneway)"
                            @complete="search"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                          >
                            <template #option="slotProps">
                              <div class="dropdown-container">
                                <div
                                  class="flex align-items-center dropdown-box"
                                >
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">To</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toErrorMessage"
                          >Arrival is required</span
                        >
                        <br v-if="toErrorMessage && maximumletter1" />
                        <span class="p-error" v-if="maximumletter1"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>

                        <span class="p-float-label">
                          <span
                            :class="clicked1 ? 'label-top' : 'datepicker-label'"
                          >
                            Departure
                          </span>

                          <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.dedate"
                            :min-date="new Date()"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            :multi-calendars="multiCalender"
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            class="px-0"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromDateError"
                          >Dep. Date is required</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-lg-3 col-sm-6 mt-3"
                      :disabled="true"
                      style="pointer-events: none; opacity: 0.5"
                    >
                      <div
                        class="d-flex input-styles"
                        @click="triggerFunction()"
                      >
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <span
                            style="
                              font-size: 14px;
                              color: #242424;
                              font-weight: 600;
                            "
                            :class="
                              clicked2 ? 'label-top' : 'datepicker-label2'
                            "
                          >
                            Return
                          </span>
                          <VueDatePicker
                            disabled
                            :format="dateFormat"
                            :min-date="oneway.dedate"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="toDateChanged"
                            :multi-calendars="multiCalender"
                            :on-click-outside="ClickOutReturn"
                            :month-change-on-scroll="false"
                          />

                          <!-- <VueDatePicker ref="dpRef2" v-model="oneway.redate" :format="dateFormat" :min-date="oneway.dedate"
                        :max-date="new Date(
                          new Date().getFullYear() + 1,
                          new Date().getMonth(),
                          new Date().getDate()
                        )
                          " :hide-navigation="['time']" auto-apply @update:modelValue="toDateChanged"
                        :on-click-outside="ClickOutReturn" /> -->
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toDateError"
                          >Arr. Date is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-1 mt-3 mt-lg-0 d-flex align-center justify-center justify-content-lg-start magnify-btn"
                >
                  <div @click="sended()">
                    <v-btn
                      color="#ff6b0a"
                      height="40px"
                      width="80"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                    >
                      <v-icon color="white">mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="multicity">
              <div class="row my-4 px-3 gx-0">
                <div class="col-lg-8 col-12">
                  <div
                    v-for="(datas, index) of multi_city.itinery"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 my-3">
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-takeoff</v-icon>
                          </span>
                          <span class="p-float-label">
                            <AutoComplete
                              v-model="datas.from"
                              optionLabel="name"
                              dropdown
                              :suggestions="filteredCountries"
                              @change="onChangeMultiFrom(index)"
                              @click="multiFromClick(index)"
                              @complete="search"
                              style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              "
                            >
                              <template #option="slotProps">
                                <div
                                  class="flex align-items-center dropdown-box"
                                  @click="Multifocus1(index)"
                                >
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">From</label>
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="fromErrorMessage"
                            >Departure is required</span
                          >
                          <br v-if="fromErrorMessage && maximumletter" />
                          <span class="p-error" v-if="maximumletter"
                            >Enter minimum 3 letters</span
                          >
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 my-3">
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-landing</v-icon>
                          </span>

                          <span class="p-float-label">
                            <AutoComplete
                              v-model="datas.to"
                              ref="multiTo"
                              optionLabel="name"
                              dropdown
                              :suggestions="filteredCountries"
                              @change="onChangeMultiTo(index)"
                              @click="multiToClick(index)"
                              @complete="search"
                              style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              "
                            >
                              <template #option="slotProps">
                                <div class="dropdown-container">
                                  <div
                                    class="flex align-items-center dropdown-box"
                                    @click="Multifocus2(datas, index)"
                                  >
                                    <div>
                                      {{ slotProps.option.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">To</label>
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="toErrorMessage"
                            >Arrival is required</span
                          >
                          <br v-if="toErrorMessage && maximumletter1" />
                          <span class="p-error" v-if="maximumletter1"
                            >Enter minimum 3 letters</span
                          >
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 my-3">
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-calendar</v-icon>
                          </span>

                          <span class="p-float-label">
                            <span
                              :class="
                                clicked1 ? 'label-top' : 'datepicker-label'
                              "
                            >
                              Departure
                            </span>

                            <VueDatePicker
                              ref="dpRef4"
                              v-model="datas.depdata"
                              :start-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :min-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :max-date="
                                new Date(
                                  new Date().getFullYear() + 1,
                                  new Date().getMonth(),
                                  new Date().getDate()
                                )
                              "
                              :format="dateFormat"
                              :hide-navigation="['time']"
                              auto-apply
                              :multi-calendars="multiCalender"
                              @update:modelValue="
                                (newValue) => multiDateChanged(newValue, index)
                              "
                              :on-click-outside="multiClickOutside()"
                              :month-change-on-scroll="false"
                              class="px-0"
                            />
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="fromDateError"
                            >Dep. Date is required</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 d-grid">
                  <div
                    class="row justify-content-between"
                    style="margin-bottom: 16px"
                  >
                    <div
                      class="col-md-8 d-flex align-end justify-content-sm-start justify-content-end mb-1"
                    >
                      <span
                        class="d-flex align-center ms-1 mb-1"
                        style="cursor: pointer"
                        @click="deleteSegment(index)"
                        v-if="deleteSeg"
                      >
                        <v-icon color="red" size="20">mdi-close-octagon</v-icon>
                      </span>
                      <span
                        class="d-flex align-center add_style ms-4 order-sm-0 order-1"
                        @click="multiSend(index)"
                        v-if="addmore"
                      >
                        <v-icon color="green" size="20">mdi-plus-circle</v-icon>
                        <span
                          class="ms-1"
                          style="font-size: 14; font-weight: 600"
                          >Add More</span
                        >
                      </span>
                      <span
                        class="d-flex align-center clear_style ms-3 order-sm-1 order-0"
                        @click="clearall1()"
                        v-if="clearall"
                      >
                        <v-icon color="red" size="20"
                          >mdi-close-box-multiple</v-icon
                        >
                        <span
                          class="ms-1"
                          style="font-size: 14; font-weight: 600"
                          >Clear All</span
                        >
                      </span>

                      <!-- <span class="d-flex align-center add_style" @click="send(index)" v-if="addmore">
                        <v-icon color="green" size="20" style="width: auto;">mdi-plus-circle</v-icon>
                        <span class="ms-1" style="font-size:14; font-weight: 600;">Add More</span>
                      </span> -->
                    </div>
                    <div
                      class="col-lg-4 mt-lg-0 mt-4 d-flex pe-2 align-end justify-center justify-content-lg-end magnify-btn"
                    >
                      <div @click="sendedMulti()">
                        <v-btn
                          color="#ff6b0a"
                          height="40px"
                          width="130px"
                          class="ms-lg-3 ms-0"
                          rounded="1"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-card>
      </div>
    </div>

    <!-- <div class="container mt-5">
    <v-card rounded="0" style="position: inherit">
      <div class="air-title">
        <h6 style="margin-left: 15px">Pick your preferred Airline</h6>
      </div>
      <div class="row mt-3 ms-2">
        <div class="col-md-4">
          <p>Compare and Choose preffered Airlines</p>
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              Non Stop
            </v-btn>
          </div>
          <br />
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              1+ Stops
            </v-btn>
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <v-card class="cart1">

            <h6 class="mt-2 ms-2">Airline</h6>
            <hr>
            <p>USD 100</p>
            <hr />
            <p>USD 100</p>
          </v-card>
        </div>
      </div>
      <p class="ms-3 mt-2" style="font-size: 13px; font-weight: 500">
        Fares incl. taxes & fees. Airfares include an applied Booking Bonus.
        Additional baggage fees may apply. Flights displayed may be for
        alternate dates and/or airports. Certain results may be outside your
        search criteria.
      </p>
      <br />
    </v-card>
  </div> -->

    <div class="container">
      <div class="row mt-4">
        <div class="col-lg-3 mb-2 mb-lg-0">
          <v-card rounded="3">
            <div
              style="
                background-color: #ff6b0a;
                padding: 3px;
                border-radius: 10px 10px 0px 0px;
              "
            >
              <div class="row p-2 align-center" style="color: white">
                <div class="col-6">
                  <div>
                    <v-icon size="20px"> mdi-filter </v-icon><span>|</span>
                    <span style="font-size: 16px; font-weight: 500">
                      Filter By</span
                    >
                  </div>
                </div>
                <div class="col-4" align="center">
                  <!-- <div>Reset All</div> -->
                </div>
                <div class="col-2 filter-toggle" align="center">
                  <v-icon
                    size="30"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    @click="isIcon = !isIcon"
                    style="overflow-anchor: none"
                    >{{
                      isIcon
                        ? "mdi-chevron-up-circle-outline"
                        : "mdi-chevron-down-circle-outline"
                    }}</v-icon
                  >
                </div>
              </div>
            </div>

            <div
              class="filter_icons"
              style="padding: 10px"
              :class="{ collapse: !isIcon, show: isIcon }"
              aria-expanded="false"
              :id="{ 'collapse-Example': isIcon }"
            >
              <div>
                <br />

                <div class="row gy-0" v-if="showNonStop">
                  <div class="col-8">
                    <v-checkbox
                      label="Non Stop"
                      v-model="NonStopFil"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneStop">
                  <div class="col-8">
                    <v-checkbox
                      label="1 Stop"
                      v-model="OneStopFil"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneplusStop">
                  <div class="col-8">
                    <v-checkbox
                      label="1+ Stop"
                      v-model="OnePlusFil"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <!-- <hr />
                <div class="stop mb-3">Baggage</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox label="Baggage Inclusive" color="primary"></v-checkbox>
                  </div>
                  <div class="col-4" align="center">
                  <div>(100)</div>
                </div>
                </div> -->

                <hr />
                <div class="stop mb-3">Fare Type</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox
                      label="Refundable"
                      color="primary"
                      v-model="RefundableData"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox
                      label="Non Refundable"
                      color="primary"
                      v-model="nonRefundableData"
                    ></v-checkbox>
                  </div>
                </div>

                <hr />
                <div class="stop">
                  Price Range
                  <span style="font-size: 13px"> (Avg.per pax)</span>
                </div>
                <div class="row me-1">
                  <v-range-slider
                    v-model="changevalue"
                    :min="minimum"
                    :max="maximum"
                    :step="steps"
                    thumb-label
                    class="price_slider"
                    color="#0c4ca3"
                  ></v-range-slider>
                </div>
                <div class="row">
                  <div class="col-6" align="center">
                    <p>{{ currencyCode }} {{ changevalue[0] }}</p>
                  </div>
                  <div class="col-6" align="center">
                    <p>{{ currencyCode }} {{ changevalue[1] }}</p>
                  </div>
                </div>

                <hr />

                <div
                  class="my-2"
                  v-if="this.multicityData.length > 0 ? false : true"
                >
                  <h5>Onward Journey</h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Departure From {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilter1()">
                      <v-card
                        :color="this.timeFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="this.timeFilterData1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter2()">
                      <v-card
                        :color="this.timeFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="this.timeFilterData2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter3()">
                      <v-card
                        :color="this.timeFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="this.timeFilterData3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Arrival at {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilterarr1()">
                      <v-card
                        :color="this.timeFilterArrData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterarr2()">
                      <v-card
                        :color="this.timeFilterArrData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterarr3()">
                      <v-card
                        :color="this.timeFilterArrData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div
                  class="my-2"
                  v-if="this.multicityData.length > 0 ? false : true"
                >
                  <h5>Return Journey</h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Departure From {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilter1()">
                      <v-card
                        :color="this.timearrFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter2()">
                      <v-card
                        :color="this.timearrFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter3()">
                      <v-card
                        :color="this.timearrFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Arrival at {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilterarr1()">
                      <v-card
                        :color="
                          this.timearrFilterArrData1 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterArrData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr2()">
                      <v-card
                        :color="
                          this.timearrFilterArrData2 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterArrData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr3()">
                      <v-card
                        :color="
                          this.timearrFilterArrData3 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterArrData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="stop"  v-if="this.multicityData.length > 0 ? false : true">Airlines</div>
                <div class="row mb-4" style="font-size: 14px; cursor: pointer"  v-if="this.multicityData.length > 0 ? false : true">
                  <div class="col-md-6" @click="airlineSelect()">
                    Select All
                  </div>
                  <div class="col-md-6" @click="airlineClear()">Clear All</div>
                </div>

                <div
                  class="row"
                  v-for="(data, index) of airlineList.slice(0, maxItemsToShow)"
                  :key="index"
                >
                  <div class="col-8">
                    <v-checkbox
                      class="text-truncate"
                      :label="data.airline"
                      color="primary"
                      v-model="data.active"
                      @click="selectAirline(data, index)"
                    >
                    </v-checkbox>
                  </div>
                </div>

                <div v-if="airlineList.length > 5">
                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="!readMore"
                  >
                    ( +{{ airlineList.length - maxItemsToShow }} More )
                  </p>

                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="readMore"
                  >
                    ( Show less )
                  </p>
                </div>
                <br />
              </div>
            </div>
          </v-card>
        </div>
        <div class="col-lg-9">
          <div class="row mb-3 gx-0" style="cursor: pointer">
            <div
              class="col-md-3 col-6"
              style="
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              "
              :class="active ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Recommend()"
              >
                <div>
                  <v-icon
                    :style="active ? 'color:#ff6b0a' : 'color:grey'"
                    size="40"
                    >mdi-seal</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Recommended</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active1 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="bestlayTime(1)"
              >
                <div>
                  <v-icon
                    :style="active1 ? 'color:#ff6b0a' : 'color:grey'"
                    size="40"
                    >mdi-star</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Best</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ layperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active2 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Cheapest(1)"
              >
                <div>
                  <v-icon
                    :style="active2 ? 'color:#ff6b0a' : 'color:grey'"
                    size="40"
                    >mdi-currency-usd</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Cheapest</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active3 ? 'recment' : 'normal-rec'"
              style="
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Shortest(1)"
              >
                <div>
                  <v-icon
                    :style="active3 ? 'color:#ff6b0a' : 'color:grey'"
                    size="40"
                    >mdi-clock</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Shortest</div>
                  <div class="top_filterprice">
                    {{ shorCurrecy }} {{ shotperperson }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3" v-for="(res, index) of rountresult" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <!-- <br />
                      {{ getid(res.Depature) }} -
                      {{ getNumber(res.Depature) }} -->
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        color="#ff6b0a"
                        width="140px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 Timing-section" style="padding: 10px 5px">
                  <div class="mt-2">
                    <div class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radioData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>

                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px; width: 100%"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getAirportCode(res.Depature) }} -->
                                {{ getCountryName1(tripData.from) }}
                              </p>
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getTime(res.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getArrivalTime(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getArrivalCode(res.Depature) }} -->
                                {{ getCountryName2(tripData.to) }}
                              </p>
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameTo(res.Return) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-start"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>

                      <div v-if="res.multipleDatas">
                        <div
                          v-for="(data, multindex) of multiDepatureData"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multione(data, multindex, index)">
                              <v-radio-group v-model="data.radios">
                                <v-radio :value="true"></v-radio>
                              </v-radio-group>
                            </div>
                            <div
                              style="
                                width: 100%;
                                box-shadow: 0px 0px 1px 0px black;
                                border-radius: 10px;
                              "
                            >
                              <div class="row p-2">
                                <div
                                  class="col-md-3"
                                  style="border-right: 1px dotted gray"
                                >
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName1(tripData.from) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getAirportNameFrom(data.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-6 col-8"
                                  style="border-right: 1px dotted gray"
                                >
                                  <div class="row g-0 mt-1 justify-center">
                                    <div class="col-md-4 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getDate(data.Depature) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getDateDeparture(data.Depature) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getTime(data.Depature) }}
                                      </p>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops == 0"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        Direct
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                      >
                                        <hr
                                          style="
                                            width: 87%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops == 1"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Depaturejourney.Stops }} Stop
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Depature.length >= 2"
                                          class="hoverData1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Depature)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops >= 2"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Depaturejourney.Stops }} Stops
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Depature.length >= 2"
                                          class="hoverDataStop1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Depature)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 15%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails2(data, index)"
                                          @mouseout="hideDetails2(data)"
                                          >mdi-circle</v-icon
                                        >
                                        <div
                                          v-if="data.Depature.length >= 3"
                                          class="hoverDataStop2"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName2,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              get1arrtime(data.Depature, index)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              get2arrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getArrivaldate(data.Depature) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{
                                          getArrivaldateReturn(data.Depature)
                                        }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getArrivalTime(data.Depature) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName2(tripData.to) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getretairname(data.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />

                    <div class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radiretData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>

                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px; width: 100%"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getreturnAirportCode(res.Return) }} -->
                                {{ getCountryName2(tripData.to) }}
                              </p>

                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameTo(res.Return) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getreturnAirportCode(res.Return) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getreturnDate(res.Return) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getreturnDateDeparture(res.Return) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getreturnTime(res.Return) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>
                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Returnjourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName3 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getreturntime(res.Return)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Returnjourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName3 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getreturntime(res.Return)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails4(res, index)"
                                      @mouseout="hideDetails4(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName4 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1returntime(res.Return, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2returntime(res.Return)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getreturnArrivaldate(res.Return) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getreturnArrivaldateReturn(res.Return) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getreturnArrivalTime(res.Return) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="res-time"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getreturnArrivalcode(res.Return) }} -->
                                {{ getCountryName1(tripData.from) }}
                              </p>

                              <p
                                class="res-time"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="res-time text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getreturnArrivalcode(res.Return) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>
                      <div v-if="res.multipleDatas">
                        <div
                          v-for="(data, multindex) of multireturnData"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multiredone(data, multindex, index)">
                              <v-radio-group v-model="data.radiosret">
                                <v-radio :value="true"></v-radio>
                              </v-radio-group>
                            </div>
                            <div
                              style="
                                width: 100%;
                                box-shadow: 0px 0px 1px 0px black;
                                border-radius: 10px;
                              "
                            >
                              <div class="row p-2">
                                <div
                                  class="col-md-3"
                                  style="border-right: 1px dotted gray"
                                >
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName1(tripData.to) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getAirportNameFrom(data.Return) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-6 col-8"
                                  style="border-right: 1px dotted gray"
                                >
                                  <div class="row g-0 mt-1 justify-center">
                                    <div class="col-md-4 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getDate(data.Return) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getDateDeparture(data.Return) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getTime(data.Return) }}
                                      </p>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops == 0"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        Direct
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                      >
                                        <hr
                                          style="
                                            width: 87%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops == 1"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Returnjourney.Stops }} Stop
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Return.length >= 2"
                                          class="hoverData1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Return)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops >= 2"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Returnjourney.Stops }} Stops
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Return.length >= 2"
                                          class="hoverDataStop1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Return)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 15%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails2(data, index)"
                                          @mouseout="hideDetails2(data)"
                                          >mdi-circle</v-icon
                                        >
                                        <div
                                          v-if="data.Return.length >= 3"
                                          class="hoverDataStop2"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName2,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              get1arrtime(data.Return, index)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              get2arrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getArrivaldate(data.Return) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getArrivaldateReturn(data.Return) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getArrivalTime(data.Return) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName2(tripData.from) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getretairname(data.Return) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{
                          getdepseat(res.Depature) >= getretseat(res.Return)
                            ? getdepseat(res.Return)
                            : getretseat(res.Depature)
                        }}
                        Seats</span
                      >
                    </div> -->

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex justify-center align-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        this.oneway.class
                      }}</span>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-sm-center"
                      @click="flighted1(res, index)"
                    >
                      <span
                        style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        "
                      >
                        Flight Details</span
                      >

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>
                    <div
                      class="col-md-3 col-4"
                      @click="addmoreroundData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>
                    <div class="text-center col-6 fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />
                            <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-content-between select-section-hide"
                  style="
                    background-color: rgb(186 233 229 / 37%);
                    border-radius: 0px 10px 10px 0px !important;
                  "
                >
                  <div>
                    <p
                      class="tect-center"
                      style="
                        color: #36c;
                        font-size: 14px;
                        font-weight: 600;
                        margin-top: 2px;
                        cursor: pointer;
                        visibility: hidden;
                      "
                    >
                      Price Details
                    </p>
                  </div>

                  <div
                    class="d-flex flex-column align-center justify-center"
                    style="margin-top: 35px; margin-bottom: 26px"
                  >
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          ><span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >Per Person</span
                    >
                    <v-btn
                      color="#ff6b0a"
                      width="140px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >Select</v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          v-bind="props"
                        >
                          Fare Details
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>Fare Details</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              Total Price
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span style="font-size: 14px; font-weight: 500"
                                  >Passengers</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                          <hr class="mt-1 mb-2" />

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                Base Price<br />
                                Taxes & Fees
                              </div>
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div>
                            </div>
                          </div>

                          <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="col-md-12 col-12">
                  <v-card class="mt-2" v-if="res.Flightdepature">
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Depature) }} -
                              {{ getArrivalCode(res.Depature) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >Total Trip Duration :
                              {{ flightdetailsData.Depaturejourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of flightdetailsData.Depature"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Economy
                                  </span>
                                </div>

                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >Layover {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size"
                                v-if="res.Depature.length - 1 !== index" style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} Layover
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="res.Flightdepature">
                      <div class="mt-2">
                        <div
                          class="d-flex p-2 justify-content-between"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(-90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getreturnAirportCode(res.Return) }} -
                              {{ getreturnArrivalcode(res.Return) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >Total Trip Duration :
                              {{ flightdetailsData.Returnjourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of flightdetailsData.Return"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated2(data.Departure.Date)
                                    }}</span>

                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep1(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >,
                                    {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated3(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarrival(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500">{{
                                      data.Arrival.AirportCode
                                    }}</span>
                                    , {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Economy
                                  </span>
                                </div>

                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div class="d-flex position-relative mt-3">
                              <hr
                                v-if="index !== res.Return.length - 1"
                                style="
                                  width: 100%;
                                  position: absolute;
                                  margin-top: 8px !important;
                                  margin-bottom: 0px !important;
                                "
                              />
                              <div
                                class="d-flex justify-center"
                                v-if="index !== res.Return.length - 1"
                                style="
                                  width: 180px;
                                  background: antiquewhite;
                                  border-radius: 18px;
                                  z-index: 1;
                                  margin: auto;
                                "
                              >
                                <div class="d-flex align-center">
                                  <span class="f-size-10"
                                    >Layover {{ data.layoverTimes }} ({{
                                      data.Arrival.AirportCode
                                    }})</span
                                  >
                                </div>
                              </div>
                            </div>

                            <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="index !== res.Return.length - 1" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size" v-if="index !== res.Return.length - 1"
                                style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} Layover
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="index !== res.Return.length - 1" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card>
          </div>

          <div class="mb-3" v-for="(res, index) of onewayresulted" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="60px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        color="#ff6b0a"
                        width="140px"
                        height="40px"
                        style="color: white"
                        @click.prevent="onewayselect(res, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 Timing-section" style="padding: 10px 5px">
                  <div class="mutilti_data">
                    <div
                      class="mt-2 d-flex"
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="display: flex; align-item: center"
                        @click="radiosData = 0"
                      >
                        <v-radio-group v-model="res.radios">
                          <v-radio :value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div style="width: 100%">
                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getCountryName1(tripData.from) }}
                              </p>
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getTime(res.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getArrivalTime(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getCountryName2(tripData.to) }}
                              </p>
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getretairname(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-start"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <!-- ------------multi Start-------- -->

                    <div v-if="res.multipleDatas">
                      <div
                        v-for="(data, multindex) of multiplesameData"
                        :key="multindex"
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            margin-top: 8px;
                          "
                          v-if="multindex !== 0"
                        >
                          <div @click="multione1(data, multindex, index)">
                            <v-radio-group v-model="radiosData">
                              <v-radio :value="multindex + 1"></v-radio>
                            </v-radio-group>
                          </div>
                          <div
                            style="
                              width: 100%;
                              box-shadow: 0px 0px 1px 0px black;
                              border-radius: 10px;
                            "
                          >
                            <div class="row p-2">
                              <div
                                class="col-md-3"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName1(tripData.from) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getAirportNameFrom(data.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-6 col-8"
                                style="border-right: 1px dotted gray"
                              >
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(data.Depature) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(data, index)"
                                        @mouseout="hideDetails2(data)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="data.Depature.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(data.Depature, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(data.Depature) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName2(tripData.to) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getretairname(data.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ------------multi end-------- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{ getdepseat(res.Depature) }} Seats</span
                      >
                    </div> -->

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        this.oneway.class
                      }}</span>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-sm-center"
                      @click="flighted2(res, index)"
                    >
                      <span
                        style="color: #36c; font-size: 12px; cursor: pointer"
                      >
                        Flight Details</span
                      >

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>

                    <div
                      class="col-md-3 col-4"
                      @click="addmoreData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>

                    <div class="text-center col-6 fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />
                            <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-content-between select-section-hide"
                  style="background-color: rgb(186 233 229 / 37%)"
                >
                  <div class="d-flex flex-column align-center justify-center">
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          ><span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >Per Person</span
                    >
                    <v-btn
                      color="#ff6b0a"
                      width="140px"
                      height="40px"
                      style="color: white"
                      @click.prevent="onewayselect(res, index)"
                      >Select</v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          class="p-0 pt-1"
                          v-bind="props"
                        >
                          Fare Details
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>Fare Details</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              Total Price
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span style="font-size: 14px; font-weight: 500"
                                  >Passengers</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                          <hr class="mt-1 mb-2" />

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                Base Price<br />
                                Taxes & Fees
                              </div>
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div>
                            </div>
                          </div>

                          <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="col-md-12 col-12">
                  <v-card class="mt-2" v-if="res.Flightdepature">
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Depature) }} -
                              {{ getArrivalCode(res.Depature) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >Total Trip Duration:
                              {{ flightdetailsData.Depaturejourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of flightdetailsData.Depature"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Economy
                                  </span>
                                </div>

                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >Layover {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size"
                                v-if="res.Depature.length - 1 !== index" style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} Layover
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card>
          </div>

          <div class="mb-3" v-for="(res, index) of multicityData" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <br />
                      {{ getid(res.Flight1) }} -
                      {{ getNumber(res.Flight1) }}
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <!-- <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <span class="price-Amount">{{ res.perperson }}</span>
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{res.find1}}</span>

                            <div class="d-flex">
                              <span style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                ">.</span>
                                <span id="f1" style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                ">{{ res.find2 }}</span>
                            </div>
                          </div>
                        </div> -->

                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                "
                                >.</span
                              >
                              <span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>

                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        color="#ff6b0a"
                        width="140px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-7 Timing-section" style="padding: 10px 5px">
                  <!-- ------flight1 start----- -->
                  <div class="mt-2">
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[0].from }}
                            </p>
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight1) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight1) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight1) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight1) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight1) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight1JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight1JourneyTime.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight1JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight1JourneyTime.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight1.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight1, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight1JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight1) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight1) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight1) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getArrivalCode(res.Depature) }} -->
                              {{ codeData[0].to }}
                            </p>
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight1) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight1) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight1 end----- -->

                  <!-- ------flight2 start----- -->
                  <div class="mt-2">
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[1].from }}
                            </p>
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight2) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight2) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight2) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight2) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight2) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight2JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight2JourneyTime.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight2JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight2JourneyTime.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight2.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight2, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight2JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight2) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight2) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight2) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[1].to }}
                            </p>
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight2) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight2) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight2 end----- -->

                  <!-- ------flight3 start----- -->
                  <div class="mt-2" v-if="res.Flight3 ? true : false">
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[2].from }}
                            </p>
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight3) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight3) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight3) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight3) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight3) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight3JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight3JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight3JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight3JourneyTime.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight3.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight3)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight3JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight3JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight3JourneyTime.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight3)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight3.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight3, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight3JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight3) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight3) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight3) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[2].to }}
                            </p>
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight3) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight3) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight3 end----- -->

                  <!-- ------flight4 start----- -->
                  <div class="mt-2" v-if="res.Flight4 ? true : false">
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[3].from }}
                            </p>
                            <p
                              class="text-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight4) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight4) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight4) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight4) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight4) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight4JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight4JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight4JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight4JourneyTime.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight4.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight4)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight4JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight4JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight4JourneyTime.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight4.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight4)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight4.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight4, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight4JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight4) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight4) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight4) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[3].to }}
                            </p>
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight4) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight4) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight4 end----- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{
                          getdepseat(res.Depature) >= getretseat(res.Return)
                            ? getdepseat(res.Return)
                            : getretseat(res.Depature)
                        }}
                        Seats</span
                      >
                    </div> -->

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-center"
                      style="width: 37%"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex justify-center align-center"
                      style="width: 30%"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        classType
                      }}</span>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-sm-center"
                      style="width: 33%"
                      @click="flighted3(res, index)"
                    >
                      <span
                        style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        "
                      >
                        Flight Details</span
                      >

                      <v-icon style="color: #36c" size="16">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>

                    <div class="text-center col-6 fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                          <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />
                            <div class="d-flex align-end mb-2">
                              <span
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                style="
                                  padding-right: 5px;
                                  cursor: pointer;
                                  font-size: 14px;
                                  letter-spacing: 1px;
                                "
                              >
                                <a href="#">Fare Summary</a></span
                              >

                              <v-icon
                                size="18"
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                v-model="fareIcon"
                                style="
                                  color: 0D6EFD;
                                  cursor: pointer;
                                  overflow-anchor: none;
                                "
                                >{{
                                  fareIcon
                                    ? "mdi-chevron-up-box-outline"
                                    : "mdi-chevron-down-box-outline"
                                }}</v-icon
                              >
                            </div>

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <br />
                            <p style="font-size: 12px">
                              All fares are quoted in USD .Some airlines may
                              charge bag gage fees. Your credit/debit card may
                              be billed in multiple charges totaling the final
                              total price.
                            </p>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-3 d-flex flex-column align-center justify-center select-section-hide1"
                  style="background-color: rgb(186 233 229 / 37%)"
                >
                  <div
                    class="d-flex flex-column align-center justify-center"
                    style="margin-top: 35px; margin-bottom: 26px"
                  >
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          >
                          <span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >Per Person</span
                    >
                    <v-btn
                      color="#ff6b0a"
                      width="140px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >Select</v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          v-bind="props"
                        >
                          Fare Details
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>Fare Details</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              Total Price
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span style="font-size: 14px; font-weight: 500"
                                  >Passengers</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <div class="d-flex align-end mb-2">
                            <span
                              class="not-collapsed"
                              data-bs-toggle="collapse"
                              href="#collapse-Example"
                              aria-expanded="true"
                              aria-controls="collapse-Example"
                              style="
                                padding-right: 5px;
                                cursor: pointer;
                                font-size: 14px;
                                letter-spacing: 1px;
                              "
                            >
                              <a href="#">Fare Summary</a></span
                            >

                            <v-icon
                              size="18"
                              class="not-collapsed"
                              data-bs-toggle="collapse"
                              href="#collapse-Example"
                              aria-expanded="true"
                              aria-controls="collapse-Example"
                              v-model="fareIcon"
                              style="
                                color: 0D6EFD;
                                cursor: pointer;
                                overflow-anchor: none;
                              "
                              >{{
                                fareIcon
                                  ? "mdi-chevron-up-box-outline"
                                  : "mdi-chevron-down-box-outline"
                              }}</v-icon
                            >
                          </div>

                          <hr class="mt-1 mb-2" />

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                Base Price<br />
                                Taxes & Fees
                              </div>
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div>
                            </div>
                          </div>

                          <br />
                          <p style="font-size: 12px">
                            All fares are quoted in USD .Some airlines may
                            charge bag gage fees. Your credit/debit card may be
                            billed in multiple charges totaling the final total
                            price.
                          </p>
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="col-md-12 col-12">
                  <v-card class="mt-2 pb-2" v-if="res.Flightdepature">
                    <!-- FLight1 Details   -->
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight1) }} -
                              {{ getArrivalCode(res.Flight1) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >Total Trip Duration :
                              {{ res.Flight1JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight1"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Economy
                                  </span>
                                </div>

                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight1.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight1.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >Layover {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight1 Details End  -->

                    <!-- FLight2 Details   -->
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight2) }} -
                              {{ getArrivalCode(res.Flight2) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >Total Trip Duration :
                              {{ res.Flight2JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight2"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Economy
                                  </span>
                                </div>

                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight2.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight2.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >Layover {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight2 Details End  -->

                    <!-- FLight3 Details   -->
                    <div v-if="res.Flightdepature">
                      <div v-if="res.Flight3 ? true : false">
                        <div
                          class="d-flex p-2 justify-content-between"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight3) }} -
                              {{ getArrivalCode(res.Flight3) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >Total Trip Duration :
                              {{ res.Flight3JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight3"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Economy
                                  </span>
                                </div>

                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight3.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight3.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >Layover {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight3 Details End  -->

                    <!-- FLight4 Details   -->
                    <div v-if="res.Flightdepature">
                      <div v-if="res.Flight4 ? true : false">
                        <div
                          class="d-flex p-2 justify-content-between"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight4) }} -
                              {{ getArrivalCode(res.Flight4) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >Total Trip Duration :
                              {{ res.Flight4JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight4"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Economy
                                  </span>
                                </div>

                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight4.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight4.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >Layover {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight4 Details End  -->
                  </v-card>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="searchloader">
    <loader></loader>
  </div>
  <div v-if="pageError">
    <pageError></pageError>
  </div>
</template>

<script>
import loader from "@/components/loaderComponent.vue";
// import onewayService from '@/service/onewayService'
import multicityService from "@/service/multicityService";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
// import userData from "@/airport_icao.json";
import axios from "axios";
import airLogo from "@/airlinesLogo.json";
const totalPricefilter = require("lodash");
import pageError from "@/components/pageError.vue";
// import userData from '@/airport_icao.json';

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    VueDatePicker,
    AutoComplete,
    loader,
    pageError,
  },

  data() {
    return {
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",

      pageError: false,
      dialog: false,
      fareOpen: false,
      multiCalender: true,
      totaladult: 0,
      classSelect: false,
      triggerRadio: false,
      vj: "",
      modifySection: false,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: " dd MMM, yyyy",
      detail: {
        from: "",
        to: "",
        dedate: "",
        redate: "",
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },
      items: [],
      airlogodata: [],
      oneway1: false,
      roundtrip: false,
      multitrip: false,
      multicityTotalData: [],
      searchresult: false,
      searchloader: true,
      isIcon1: true,
      fareIcon: true,
      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      red: 1,
      red1: 1,
      // paxhide: true,

      roundModify: true,
      onewayModify: false,
      multiModify: false,

      find1: [],
      find2: [],

      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      offerData5: [],

      returnData: [],
      Oneway: false,
      rount: true,
      multicity: false,
      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      uniqueId: [],
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      codeData: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      multicityData: [],
      multicityData1: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      localData: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      multiFilterData: [],
      routtime: [],
      airportname: "",
      traveltime: "",
      Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],

      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      addition: 0,
      classType: "Economy",

      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      data_id: "",
      roundOfferId: [],
      airlineList: [],
      airlineArr: [],
      oneway_id: "",
      onewayOfferId: [],

      responseData: [],
      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      storefilterData: [],

      showNonStop: false,
      showOneStop: false,
      showOneplusStop: false,
      recperperson: "",
      recCurrecy: "",
      shotperperson: "",
      layperperson: "",
      shorCurrecy: "",
      active: false,
      active1: false,
      active2: false,
      active3: false,
      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,
      RefundableData: false,
      nonRefundableData: false,
      radiosData: 0,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      flightdetailsData: [],
      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      totalpassenger: [],
      onewaymultiData: [],
      rountmultiData: [],
      airCanada: false,
      qatarAirline: false,
      // layoverTimes: [],
      // layoverTimes1: [],
      // input: [
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      // ],

      input: [
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
        },
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
        },
      ],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight5: [],
      resulted: [],
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      multiplesameData: [],
      multiDepatureData: [],
      multireturnData: [],
      timearrFilterData1: false,
      timearrFilterData2: false,
      timearrFilterData3: false,
      timearrFilterArrData1: false,
      timearrFilterArrData2: false,
      timearrFilterArrData3: false,
      totalMinutesT1: "",
      totalMinutesT2: "",
      preferCurrency: "",
      metaCurrency: "",

      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      MulticityReq: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [],
              },
            },
            Preference: {
              TripType: "MULTICITY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      itinaryRequest: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "RoundTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      itinaryRequestoneway: {
        SearchClass: "",
        OriginCity1: "",
        DestinationCity1: "",
        OriginCity2: "",
        DestinationCity2: "",
        TripType: "Oneway",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      formMultiData: [],
      FilterTimeDepname: "",
      FilterTimearrname: "",
      multi_city: {
        itinery: [
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
          },
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      },

      showMulti_data: {},

      // multi_city: [
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   }
      // ],
      addmore: true,
      clearall: false,
      deleteSeg: true,

      makeSearchUrl: "",
      airLogoApi: [],

      airline_logoData: [],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      readMore: false,
      maxItemsToShow: 5,
      filterAirline: [],
    };
  },

  methods: {
    showMore() {
      this.readMore = !this.readMore;

      // let $data = []
      console.log(this.filterAirline, "this.filterAirline ");

      if (this.readMore) {
        this.maxItemsToShow = this.airlineList.length;
      } else {
        this.maxItemsToShow = 5;
      }
    },

    // showLess() {
    //   this.readMore = false;
    // },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      // console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(8);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(8);

      this.uniqueId = randomChars1 + randomNum + randomChars2;
      // console.log(
      //   this.uniqueId,
      //   "bookingRefIdbookingRefIdbookingRefId"
      // );
    },

    showDetails1(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }

      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails1(res) {
      res.hoveredName1 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails2(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName2 = true;
        } else {
          v.hoveredName2 = false;
        }
      });
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails2(res) {
      res.hoveredName2 = false;
    },

    showDetails3(res, index) {
      this.rountresult.map((v, i) => {
        // console.log(v, "ppppp");
        if (i == index) {
          v.hoveredName3 = true;
        } else {
          v.hoveredName3 = false;
        }
      });
    },
    hideDetails3(res) {
      res.hoveredName3 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails4(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName4 = true;
        } else {
          v.hoveredName4 = false;
        }
      });
    },
    hideDetails4(res) {
      res.hoveredName4 = false;
    },

    act(data) {
      this.red1 = data;
      // this.oneway.city = this.red1;
      // console.log(this.red1, "pppppppppppppppppppppppppp");

      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
      }
      if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
      }
      if (this.red1 == 3) {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    actActive() {
      // console.log(this.red1, "TRTRTRTR1");
      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.roundModify = false;
        this.onewayModify = true;
      } else if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
        this.roundModify = true;
        this.onewayModify = false;
      } else {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    deg1() {
      // this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
    },
    add1() {
      console.log(this.aduls, "1111");
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls = this.aduls + 1;
        console.log(this.aduls, "2222");
        // this.adulted();
        this.added();
      }
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        // this.Childed1();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;

        // console.log(this.aduls1, "2222");

        // this.Childed();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        // this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;

        // console.log(this.aduls2, "3333");

        // this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );

      // console.log(this.concatenatedArray, "concatenatedArray");
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // console.log(this.classSelect, "ffffaaaallllssseeee");
      if (!this.classSelect) {
        this.$refs.countrySelect.focus();
      }
    },

    closeDropdown() {
      setTimeout(() => {
        this.$refs.mySelect.blur();
        if (this.classType) {
          this.$refs.countrySelect.blur();
          console.log("working:");
        }
      }, 50);
    },

    multiSend() {
      if (this.multi_city.itinery.length < 4) {
        this.multi_city.itinery.push({
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        });

        if (this.multi_city.itinery.length == 4) {
          console.log(
            this.multi_city,
            this.multi_city.itinery.length,
            "lengthlen.....gthlength"
          );

          this.addmore = false;
        }
        // else{
        //   this.addmore = true;
        // }
      }

      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
        this.deleteSeg = true;
      }

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    deleteSegment() {
      if (this.multi_city.itinery.length == 3) {
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = false;
        this.addmore = true;
      } else if (this.multi_city.itinery.length > 2) {
        console.log(this.multi_city.itinery.length, "lengthlengthl,..111..");
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = true;
        this.addmore = true;
      } else {
        this.deleteSeg = false;
      }
    },

    clearall1() {
      this.multi_city.itinery.splice(2, 5);

      (this.multi_city.itinery = [
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },

    // getprice(res) {
    //   return (((res.TotalAmount) / this.totaladult).toFixed(2));
    // },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    // getlogo($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    // getlogo1($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },

    // All Date Section
    getDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // All Date Section End

    // Mobile Date Format Section

    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // Mobile Date Show End

    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getCountryName1($event) {
      this.FilterTimeDepname = $event.split("(")[0];
      return $event.split("(")[0];
    },
    getCountryName2($event) {
      this.FilterTimearrname = $event.split("(")[0];
      return $event.split("(")[0];
    },

    getAirportNameFrom($event) {
      return $event[0].Departure.AirportName;
    },

    getAirportNameTo($event) {
      return $event[0].Departure.AirportName;
    },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event, '$event')

      return $event[$event.length - 1].Departure.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Departure.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Departure.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Departure.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getretairname($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },
    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },

    getdeparDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "ddd MMM DD"
      );
    },

    getdated($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    //  --------filter Start---------------------

    Recommend() {
      this.active = true;
      this.active1 = false;
      this.active2 = false;
      this.active3 = false;

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData = this.multiFilterData;
      }
    },
    Cheapest(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = false;
        this.active2 = true;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        // this.onewayfilterData.sort((a, b) => a.perperson - b.perperson)
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData = this.multiFilterData;
      }
    },

    Shortest(data) {
      if (data == "1") {
        this.active = false;
        this.active1 = false;
        this.active2 = false;
        this.active3 = true;
      } else {
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) =>
          a.Depaturejourney.Time.localeCompare(
            b.Depaturejourney.Time,
            undefined,
            { numeric: true }
          )
        );
        this.shorCurrecy = this.onewayresulted[0].currency;
        this.shotperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort(
          (a, b) =>
            a.Depaturejourney.Time.localeCompare(
              b.Depaturejourney.Time,
              undefined,
              { numeric: true }
            ) &&
            a.Returnjourney.Time.localeCompare(
              b.Returnjourney.Time,
              undefined,
              { numeric: true }
            )
        );
        this.shorCurrecy = this.rountresult[0].currency;
        this.shotperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData = this.multiFilterData;
      }
    },

    bestlayTime(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = true;
        this.active2 = false;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData = this.multiFilterData;
      }
    },

    //  --------filter end---------------------

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    flighted1(res, index) {
      this.flightdetailsData = res;

      this.rountresult.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },
    flighted2(res, index) {
      console.log(index, res, "pppppppiiithis");
      this.flightdetailsData = res;
      // console.log(this.flightdetailsData.Depature,'this.flightdetailsDatathis.flightdetailsData')

      this.onewayresulted.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },

    flighted3(res, index) {
      console.log(index, res);
      this.multicityData.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },

    closeDep(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
    },

    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightdep1(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarrival(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    onewayselect(result, index) {
      if (this.flightdetailsData.TotalPrice) {
        // console.log(this.flightdetailsData, 'ppp');
      } else {
        this.flightdetailsData = result;
        // console.log(this.flightdetailsData, 'ptttttttttttttttpp');
      }
      localStorage.removeItem("itineryData");
      localStorage.removeItem("uniId");
      console.log(result, index);
      localStorage.setItem(
        `${result.currency}-${result.selectId}`,
        JSON.stringify(this.flightdetailsData)
      );
      this.$router.push({
        path: "/flightinfo",
        query: { pass_id1: `${result.currency}-${result.selectId}` },
      });
    },

    multidata() {
      multicityService.getAllUser().then((v) => {
        this.offerpay = v.data.OffersGroup.AirlineOffers[0].Offer;

        this.Journey = v.data.DataLists.FlightList.Flight;

        this.Journey1 = v.data.DataLists.FlightSegmentList.FlightSegment;
        this.Journey.forEach((a) => {
          this.flightsegmet.push(a.SegmentReferences.split(" "));
        });
        this.Journey1.forEach((t) => {
          this.flightsegmetkey.push(t);
        });

        this.Journey.forEach((q) => {
          this.airstime.push(q.Journey.Time);
          this.airstop.push(q.Journey.Stops);
        });
        for (let i = 0; i <= this.offerpay.length - 1; i++) {
          let item = [];
          item = this.offerpay[i].OfferItem;
          item.forEach((t) => {
            this.airprice.push(
              t.TotalPriceDetail.TotalAmount.BookingCurrencyPrice
            );
            if (
              t.PassengerType === "ADT" ||
              t.PassengerType == "ChD" ||
              t.PassengerType == "INF"
            ) {
              if (t.Refundable === "true") {
                this.airref.push("Refundable");
              } else {
                this.airref.push("NON Refundable");
              }
            }
          });
        }

        this.airprice.forEach((c) => {
          this.multicityData1.push({ airprice: c });
        });
      });
    },

    mydata() {
      try {
        this.resulted = JSON.parse(localStorage.getItem("myData"));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    deatailData() {
      if (this.detail.city == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.oneway1 = true;
      }
      if (this.detail.city == 1) {
        this.roundtrip = true;
        this.Oneway = false;
        (this.rount = true), (this.multicity = false);
      }
      if (this.detail.city == 3) {
        this.multitrip = true;
        this.Oneway = false;
        (this.rount = false), (this.multicity = true);
      }
    },

    // RoundTrip datePicker

    getDatepicker($data, index) {
      if (index > 0) {
        // console.log($data, index, '$data,index....1')

        return $data[index - 1].depdata;
      } else {
        // console.log($data, index, '$data,index....2')

        return `${new Date()}`;
      }
    },

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.clicked2 = false;
        this.fromDateError = false;
        this.oneway.redate = null;
        this.$refs.dpRef2.openMenu();

        // console.log(this.oneway.dedate, "ddddddddd...1....eeeeeeeeeee");
      } else {
        this.clicked1 = false;
        this.fromDateError = true;

        // console.log(this.oneway.dedate, "ddddddddd...2....eeeeeeeeeee");
      }
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // console.log(this.oneway.redate, "ttttttttttt");

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
      } else {
        this.clicked2 = false;
        this.toDateError = true;
      }
    },

    triggerFunction() {
      alert("Hiii...");
      // this.triggerRadio = !this.triggerRadio

      // if (this.triggerRadio) {
      //   console.log(this.triggerRadio)
      //   this.$refs.radioSelect2.focus();
      // }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();
      // console.log(this.oneway.redate, "kkkkkkkkk");

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();
      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    multiDateChanged(newValue, index) {
      // console.log(index, newValue, "didididid...");

      this.multi_city.itinery.map((v, i) => {
        if (index == i) {
          if (newValue) {
            v.clicked3 = true;
            v.multiDateError = false;
          } else {
            v.clicked3 = false;
            v.multiDateError = true;
          }
        }
      });

      this.multi_city.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },

    multiClickOutside() {
      this.multi_city.itinery.clicked3 = false;
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End

    roundData() {
      this.oneway.class = this.$route.query.class;

      if (this.$route.query.from.name) {
        this.oneway.from = this.$route.query.from.name;
        this.tripData.from = this.$route.query.from.name;
      } else {
        this.oneway.from = this.$route.query.from;
        this.tripData.from = this.$route.query.from;
      }
      if (this.$route.query.to.name) {
        this.oneway.to = this.$route.query.to.name;
        this.tripData.to = this.$route.query.to.name;
      } else {
        this.oneway.to = this.$route.query.to;
        this.tripData.to = this.$route.query.to;
      }
      this.oneway.dedate = moment(this.$route.query.dedate).format(
        "DD MMM-YYYY"
      );
      this.tripData.dedate = moment(this.$route.query.dedate).format(
        "DD MMM-YYYY"
      );
      this.oneway.redate = moment(this.$route.query.redate).format(
        "DD MMM-YYYY"
      );
      this.tripData.redate = moment(this.$route.query.redate).format(
        "DD MMM-YYYY"
      );
      this.oneway.class = this.$route.query.class;
      this.tripData.class = this.$route.query.class;

      if (this.$route.query.adult) {
        for (let i = 0; i < this.$route.query.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.$route.query.child) {
        for (let i = 0; i < this.$route.query.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.$route.query.infrant) {
        for (let i = 0; i < this.$route.query.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];

      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });
      // console.log(this.totalpassenger, "aadjj");
      this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
      this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;

      this.aduls = parseFloat(this.$route.query.adult);
      this.tripData.aduls = parseFloat(this.$route.query.adult);

      this.aduls2 = parseFloat(this.$route.query.infrant);
      this.tripData.aduls2 = parseFloat(this.$route.query.infrant);

      this.aduls1 = parseFloat(this.$route.query.child);
      this.tripData.aduls1 = parseFloat(this.$route.query.child);
      this.totaladult = this.aduls + this.aduls1 + this.aduls2;
      this.select = this.$route.query.class;
      this.tripData.select = this.$route.query.class;
    },

    multiDataConvert() {
      console.log(this.multi_city, "this.$route.querythis.$route.query.....3");
      this.red = this.multi_city.city;
      this.red1 = this.multi_city.city;
      if (this.multi_city.adult) {
        for (let i = 0; i < this.multi_city.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.multi_city.child) {
        for (let i = 0; i < this.multi_city.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.multi_city.infrant) {
        for (let i = 0; i < this.multi_city.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });
      this.multi_city.itinery.forEach((s) => {
        let $result = {
          Departure: {
            AirportCode: "",
            Date: "",
          },
          Arrival: {
            AirportCode: "",
          },
        };
        let tripData = {
          from: "",
          to: "",
        };
        if (s.from) {
          let res = "";
          res = s.from.split("(");
          console.log(res);
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
        } else {
          let res = "";
          res = s.from.name.split("(");
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
        }

        if (s.to) {
          let res = "";
          res = s.to.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
        } else {
          let res = "";
          res = s.to.name.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
        }
        if (s.depdata) {
          $result.Departure.Date = moment(s.depdata).format("YYYY-MM-DD");
        }
        // console.log($result, '$result$result')
        this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push(
          $result
        );
        this.codeData.push(tripData);
      });

      console.log(this.codeData, "this.$route.querythis.$route.query.....2");

      console.log(this.totalpassenger, "aadjj");
      this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
    },

    multicityresult() {
      this.multiDataConvert();

      this.multi_city.class = this.classType;
      let multiCabin_type = this.multi_city.class;

      console.log(
        multiCabin_type,
        "multiCabin_typemultiCabin_typemultiCabin_type"
      );

      if (multiCabin_type == "Economy") {
        multiCabin_type = "Y";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Business") {
        multiCabin_type = "C";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else {
        multiCabin_type = "F";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      }

      this.MulticityReq.request.AirShoppingRQ.Preference.Currency =
        this.preferCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency =
        this.metaCurrency;

      console.log(
        this.MulticityReq,
        "MulticityReqMulticityReqMulticityReqMulticityReq"
      );

      axios
        .post(this.makeSearchUrl, this.MulticityReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          console.log(response, "multi_citymulti_citymulti_city");
          if (response.data) {
            this.multicityTotalData.push(response.data);
            this.multicityconvert();
          }
        });
    },

    multicityconvert() {
      for (let i = 0; i < this.multicityTotalData.length; i++) {
        console.log(this.multicityTotalData[i], "power");
        let shoppingId = this.multicityTotalData[i].ShoppingResponseId;
        let Journey = [];
        let flightsegment = [];
        Journey = this.multicityTotalData[i].DataLists.FlightList.Flight;
        console.log(Journey, "Journey");
        flightsegment =
          this.multicityTotalData[i].DataLists.FlightSegmentList.FlightSegment;
        // console.log(flightsegment, "flightsegment");
        let $data = [];
        $data = this.multicityTotalData[i].OffersGroup.AirlineOffers.Offer;

        let source1 = [];
        let source2 = [];
        let source3 = [];
        let source4 = [];
        let source5 = [];

        $data.forEach((v) => {
          v.OfferItem[0][0].FareComponent.forEach((s, index) => {
            if (index == 0) {
              this.offerData1.push(s.SegmentRefs.split(" "));

              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source1.push(Journey[i].Journey);
                }
              }
            }
            if (index == 1) {
              this.offerData2.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source2.push(Journey[i].Journey);
                }
              }
            }
            if (index == 2) {
              this.offerData3.push(s.SegmentRefs.split(" "));
              console.log(this.offerData3, "this.offerData3this.offerData3");
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source3.push(Journey[i].Journey);
                }
              }
            }
            if (index == 3) {
              this.offerData4.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source4.push(Journey[i].Journey);
                }
              }
            }
            if (index == 4) {
              this.offerData5.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source5.push(Journey[i].Journey);
                }
              }
            }
          });
        });
        console.log(flightsegment, "this.flightsegment");

        if (this.offerData1.length > 0) {
          for (let i = 0; i < this.offerData1.length; i++) {
            let seg = [];
            this.offerData1[i].forEach((f, index) => {
              let items = [];
              items = flightsegment.filter((v) => v.SegmentKey == f);
              if (items.length > 0) {
                seg.push(items[0]);
              }
              if (index == this.offerData1[i].length - 1) {
                this.multicityData.push({ Flight1: seg });
              }
            });
          }
        }

        if (this.offerData2.length > 0) {
          for (let i = 0; i < this.offerData2.length; i++) {
            let seg = [];
            this.offerData2[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData2[i].length - 1) {
                this.Flight2.push(seg);
              }
            });
          }
        }

        if (this.offerData3.length > 0) {
          // console.log(this.offerData3,'this.offerData3this.offerData36557')
          for (let i = 0; i < this.offerData3.length; i++) {
            let seg = [];
            this.offerData3[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              console.log(
                index,
                this.offerData3[i].length - 1,
                "this.offerData2[i].length - 1"
              );
              if (index == this.offerData3[i].length - 1) {
                this.Flight3.push(seg);
                // console.log(this.Flight3,'this.Flight3this.Flight3this.Flight3this.Flight3')
              }
            });
          }
        }

        if (this.offerData4.length > 0) {
          for (let i = 0; i < this.offerData4.length; i++) {
            let seg = [];
            this.offerData4[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData4[i].length - 1) {
                this.Flight4.push(seg);
              }
            });
          }
        }

        if (this.offerData5.length > 0) {
          for (let i = 0; i < this.offerData5.length; i++) {
            let seg = [];
            this.offerData5[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData5[i].length - 1) {
                this.Flight5.push(seg);
              }
            });
          }
        }
        for (let i = 0; i < this.airlogodata.length - 1; i++) {
          for (let j = 0; j < flightsegment.length - 1; j++) {
            if (
              flightsegment[j].MarketingCarrier.AirlineID ==
              this.airlogodata[i].id
            ) {
              flightsegment[j].MarketingCarrier.logo = this.airlogodata[i].logo;
            }
          }
        }

        let currency = [];
        let Baseprice = [];
        let Tax = [];
        let perperson = [];
        let ownername = [];
        let ownerlogo = [];
        let offerId = [];
        let TotalPrice = [];
        let refund = [];

        $data.forEach((v) => {
          // console.log(v, "offeriddatataa");
          offerId.push(v.OfferID);
        });

        $data.forEach((s) => {
          if (s.ReqCurrency == "USD") {
            currency.push("US$");
          } else if (s.ReqCurrency == "CAD") {
            currency.push("CA$");
          } else {
            currency.push("US$");
          }

          ownername.push(s.OwnerName);
          for (let i = 0; i < this.airline_logoData.length - 1; i++) {
            if (s.Owner == this.airline_logoData[i].id) {
              ownerlogo.push(this.airline_logoData[i].logo);
            }
          }

          Baseprice.push(
            parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
          );
          Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
          perperson.push(
            parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
          );
          TotalPrice.push(
            parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
          );

          s.OfferItem.forEach((v) => {
            console.log(
              v[0].FareDetail[0].Price.NonRefundable,
              "Price.RefundablePrice.Refundable"
            );

            if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
              refund.push("Refundable");
            } else {
              refund.push("Non Refundable");
            }
          });
        });

        for (let i = 0; i < perperson.length; i++) {
          let nume1 = perperson[i];
          // console.log(nume1, "llldlldldldldld")
          this.find1.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]
          );
          this.find2.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]
          );
        }

        for (let i = 0; i < this.multicityData.length; i++) {
          this.multicityData[i].selectId = offerId[i];
          this.multicityData[i].currency = currency[i];
          this.multicityData[i].Baseprice = Baseprice[i];
          this.multicityData[i].TotalPrice = TotalPrice[i];
          this.multicityData[i].Taxprice = Tax[i];
          this.multicityData[i].perperson = perperson[i];
          this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].find1 = this.find1[i];
          this.multicityData[i].find2 = this.find2[i];
          this.multicityData[i].Flightdepature = false;
          this.multicityData[i].hoveredName1 = false;
          this.multicityData[i].ShoppingResponseId = shoppingId;
          this.multicityData[i].Adult = this.aduls;
          this.multicityData[i].Child = this.aduls1;
          this.multicityData[i].Inf = this.aduls2;
          // this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].ownerlogo = ownerlogo[i];
          this.multicityData[i].ownername = ownername[i];
          this.multicityData[i].Flight1JourneyTime = source1[i];
          this.multicityData[i].Flight2JourneyTime = source2[i];
          this.multicityData[i].Refund = refund[i];
          this.multicityData[i].cabinClass = this.multi_city.class;
          if (this.Flight3.length > 0) {
            this.multicityData[i].Flight3 = this.Flight3[i];
            this.multicityData[i].Flight3JourneyTime = source3[i];
          }

          if (this.Flight4.length > 0) {
            this.multicityData[i].Flight4 = this.Flight4[i];
            this.multicityData[i].Flight4JourneyTime = source4[i];
          }
          if (this.Flight5.length > 0) {
            this.multicityData[i].Flight5 = this.Flight5[i];
            this.multicityData[i].Flight5JourneyTime = source5[i];
          }
        }
        this.getmultitime1();

        console.log(
          this.multicityData,
          "multicityDatamulticityDatamulticityData...."
        );

        this.multiFilterData = this.multicityData;
        this.priceRange();

        this.multicityData = totalPricefilter.sortBy(this.multiFilterData, [
          "TotalAmount",
        ]);

        if (this.multicityData) {
          this.searchresult = true;
          this.searchloader = false;

          document.getElementById("navbar").style.display = "block";
          document.getElementById("food").style.display = "block";
        }
        // console.log(this.multicityData, "this.multicityDatathis.multicityData");
      }
    },

    async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airLogoApi.url;
      airlogoType = this.airLogoApi.type;

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },

    roundedtrip() {
      for (let i = 0; i < this.localData.length; i++) {
        // console.log(this.localData[i],"this.localData[i]this.localData[i]this.localData[i]");

        if (this.localData[i]) {
          this.localData[i].forEach((v) => {
            // console.log(v.DataLists, "good");
            // let seats = [];
            this.Journey = v.DataLists.FlightList.Flight;
            let shoppingId = v.ShoppingResponseId;
            // console.log(shoppingId, "shoppingIdshoppingId");
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;

            let offerId = [];
            // $data.forEach((v=>{
            //   offerId.push(v.OffersGroup.AirlineOffers.Offer.offerId)
            // }))
            // offerId.push(v.OffersGroup.AirlineOffers.Offer)

            let arrsplit = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];

            $data.forEach((v) => {
              // console.log(v, i, "offeriddatataa");
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }

              // console.log(this.airline_logoData,"vlvlvlvlvvl")

              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);

                  // console.log(ownerlogo,'owmpownwonwown')
                }
              }
              // console.log(s.ReqCurrency, "currency");
              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );
              pricedetail.push(s.OfferItem);

              s.OfferItem.forEach((v) => {
                // console.log(v, "kannannaaannnanannananannanan");
                resultdata1 = v[0].FareComponent[0].SegmentRefs;
                resultdata = v[0].FareComponent[1].SegmentRefs;

                // console.log(
                //   v[0].FareDetail[0].Price.NonRefundable,
                //   "kannannaaannnanannananannanan"
                // );

                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }
              });
              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));
            });
            // console.log(arrsplit, "$data");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              // console.log(a,'thesplitvalueaaaaaaaaaaaaaa')
              this.flightsegmet.push(a.SegmentReferences.toString().split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
              // console.log(this.flightsegmetkey, "vasanthjourney");
            });

            // seats = v.DataLists.PriceClassList.PriceClass;
            // console.log(seats, "seatsseatsseats");
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              let result1 = $data[j].OfferItem[0][0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey;
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }
            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }
            // console.log(this.find1, "this.find1this.find1");
            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].Baseprice = Baseprice[i];
              this.rountresult[i].TotalPrice = TotalPrice[i];
              this.rountresult[i].Taxprice = Tax[i];
              this.rountresult[i].pricedetail = pricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].multipleDatas = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].currency = currency[i];
              this.rountresult[i].ownerlogo = ownerlogo[i];
              this.rountresult[i].ownername = ownername[i];
              this.rountresult[i].Returnjourney = depjourney[i];
              this.rountresult[i].Depaturejourney = Arrjourney[i];
              this.rountresult[i].perperson = perperson[i];
              this.rountresult[i].cabinClass = this.oneway.class;
              this.rountresult[i].find1 = this.find1[i];
              this.rountresult[i].find2 = this.find2[i];
              this.rountresult[i].ShoppingResponseId = shoppingId;
              this.rountresult[i].selectId = offerId[i];
              this.rountresult[i].Adult = this.aduls;
              this.rountresult[i].Child = this.aduls1;
              this.rountresult[i].Inf = this.aduls2;
              this.rountresult[i].radioData = true;
              this.rountresult[i].radiretData = true;
              this.rountresult[i].radios = false;
              this.rountresult[i].radiosret = false;
            }
            this.rountmultiData = this.rountresult;
            console.log(this.rountresult, "resulppppppppppppppppppppppppt");
            this.gettimedep();
            this.gettimeret();
            this.singlepriceRountData();
            // this.filterData = this.rountresult;
            this.priceRange();
            this.calculateTotalTime();
            this.Shortest();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();
            this.airlineFilter();
            this.stopFilter();

            if (this.rountresult) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            // console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            // this.filterShowing();

            // console.log(this.rountresult, 'result222222222222222222222222')

            this.filterData.sort((a, b) => a.perperson - b.perperson);
            this.rountresult = this.filterData;
          });
        }
      }
    },
    // -------depTime start------

    singlepriceRountData() {
      let $event = [];
      // let $event1 = [];
      let $data = this.rountresult;
      this.rountresult.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.Baseprice == v.Baseprice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.rountresult = $event.sort((a, b) => a.perperson - b.perperson);

      this.rountresult.forEach((a, i) => {
        let count = 0;

        $data.forEach((b, j) => {
          if (
            a.Baseprice == b.Baseprice &&
            a.ownername == b.ownername &&
            (a.Depaturejourney.Time !== b.Depaturejourney.Time ||
              a.Returnjourney.Time !== b.Returnjourney.Time)
          ) {
            console.log(
              a.ownername,
              a.Baseprice,
              a.Depaturejourney.Time,
              i,
              "aaaaaaaaaa"
            );
            console.log(
              b.ownername,
              b.Baseprice,
              b.Depaturejourney.Time,
              j,
              "bbbbbbbbb"
            );
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.rountresult[i].multi = "Show more Options";
        }
      });

      this.filterData = this.rountresult;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    addmoreroundData(data, index) {
      console.log(data, index, "vasanrhtttt");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = false;
          v.radiosret = false;
          v.multipleDatas = !v.multipleDatas;
        } else {
          v.multipleDatas = false;
        }
      });
      let $event = [];

      this.rountmultiData.forEach((v) => {
        if (data.Baseprice == v.Baseprice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });

      let $result = [];
      $event.forEach((a) => {
        if (
          !$result.some(
            (b) =>
              b.Depaturejourney.Time == a.Depaturejourney.Time &&
              b.Returnjourney.Time == a.Returnjourney.Time
          )
        ) {
          $result.push(a);
        }
      });

      this.multiplesameData = $result;
      let $data = [];
      let $data1 = [];
      this.multiplesameData.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
        if (!$data1.some((b) => b.Returnjourney.Time == k.Returnjourney.Time)) {
          $data1.push(k);
        }
      });
      this.multiDepatureData = $data;
      this.multireturnData = $data1;
    },

    radiodep(res, index) {
      console.log(res, index, "res, indexrrrrrr");
      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radioData = true;
          v.radiretData = true;

          this.multireturnData.forEach((d) => {
            d.radiosret = false;
          });
          this.multiDepatureData.forEach((d) => {
            d.radios = false;
          });
        }
      });
    },

    multione(data, multindex, index) {
      //  console.log(data, multindex,index,'data, multindex,indexuuuuufdfdjjb')

      this.multiDepatureData.find((e, j) => {
        console.log(j, multindex, index, "nanduuuuu");
        if (multindex == j) {
          console.log(e, "wwwwwwwwww");
          e.radios = true;
        } else {
          console.log(e, "wwwwwtttttwwwww");
          e.radios = false;
        }
      });

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = true;
          v.radioData = false;
          v.radiretData = false;
          this.multireturnData.forEach((c, k) => {
            if (c.Returnjourney.Time == data.Returnjourney.Time) {
              console.log(c, k, (c.radiosret = false), "wwww");
              c.radiosret = false;
              v.radiosret = true;
              k == 0 ? (v.radiretData = true) : (c.radiosret = false);

              this.multireturnData.forEach((d, j) => {
                if (k == j) {
                  d.radiosret = true;
                } else {
                  d.radiosret = false;
                }
              });
            } else {
              c.radiosret = false;
            }
          });
        }
      });

      this.flightdetailsData = data;
    },

    multiredone(data, multindex, index) {
      console.log(data, multindex, index, "data, multindex,indexrrrrrrrr");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radiosret = true;
          v.radioData = false;
          v.radiretData = false;
          this.multiDepatureData.forEach((c, k) => {
            if (c.Depaturejourney.Time == data.Depaturejourney.Time) {
              if (k == 0) {
                console.log(c, k, "wwww");
                v.radioData = true;
              } else {
                this.multiDepatureData.forEach((d, j) => {
                  if (k == j) {
                    console.log(d, "konoo");
                    d.radios = true;
                  } else {
                    d.radios = false;
                  }
                });
              }
            }
          });
        } else {
          v.radiosret = false;
        }
      });
      this.multireturnData.find((e, j) => {
        console.log(e, j, "ppppprrrrrrrrr");
        if (multindex == j) {
          e.radiosret = true;
        } else {
          e.radiosret = false;
        }
      });
      this.flightdetailsData = data;
    },

    TimeFilter1() {
      this.timeFilterData1 = !this.timeFilterData1;

      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData1 && this.timeFilterData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterData1) {
        this.TimeFilterData6();
      } else {
        this.removeFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeFilter2() {
      this.timeFilterData2 = !this.timeFilterData2;
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        // console('2222')
        this.timetwoFilter1();
      } else if (this.timeFilterData3 && this.timeFilterData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterData2) {
        this.TimeFilterDataafter12();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeFilter2();
      }
    },
    TimeFilter3() {
      this.timeFilterData3 = !this.timeFilterData3;
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData3 && this.timeFilterData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterData3 && this.timeFilterData1) {
        this.timetwoFilter2();
      } else if (this.timeFilterData3) {
        this.TimeFilterDataafter18();
      } else {
        this.removeFilter3();
      }
    },

    // -----------deptime  end-------

    releseFilter() {
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData1 && this.timeFilterData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterData1) {
        this.TimeFilterData6();
      } else if (this.timeFilterData2) {
        this.TimeFilterDataafter12();
      } else if (this.timeFilterData3) {
        this.TimeFilterDataafter18();
      }
    },

    // -------arrtime start ---------

    TimeFilterarr1() {
      this.timeFilterArrData1 = !this.timeFilterArrData1;

      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData2 && this.timeFilterArrData1) {
        console.log("ttttttt");
        this.timetwoFilter1();
      } else if (this.timeFilterArrData1 && this.timeFilterArrData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterArrData1) {
        this.TimeFilterData6();
      } else {
        this.removeFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeFilterarr2() {
      this.timeFilterArrData2 = !this.timeFilterArrData2;
      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData1 && this.timeFilterArrData2) {
        // console('2222')
        this.timetwoFilter1();
      } else if (this.timeFilterArrData3 && this.timeFilterArrData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterArrData2) {
        this.TimeFilterDataafter12();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeFilter2();
      }
    },
    TimeFilterarr3() {
      this.timeFilterArrData3 = !this.timeFilterArrData3;
      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData3 && this.timeFilterArrData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterArrData3 && this.timeFilterArrData1) {
        this.timetwoFilter2();
      } else if (this.timeFilterArrData3) {
        //  console.log('oopopoo')
        this.TimeFilterDataafter18();
      } else {
        this.removeFilter3();
      }
    },
    // --------arrtime end------

    TimeFilterData6() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          console.log("vasanth");
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          console.log("keerthi");
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        console.log($twoFilter, "$twoFilter$twoFilter");
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    TimeFilterDataafter12() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    TimeFilterDataafter18() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            // console.log('pp')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  console.log('pp')
          } else {
            $result.push(v);
          }
        }
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
          //  console.log('keerthi',this.filterData)
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Depature.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "18:00") {
              $result.push(v);
            }
          }

          if (
            (i == v.Depature.length - 1 && this.timeFilterArrData1) ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "18:00") {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "6:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "6:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
          console.log("keerthi", "$event ");
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "6:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "6:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    removeFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          console.log("vasanth");
          $event = this.rountresult;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
          $twoFilter = false;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    removeFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
          }
          //        else {
          //        $result.push(v)
          //  }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
          //        else {
          //        $result.push(v)
          //  }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    totalTimeData() {
      let $event = [];

      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }

      if (this.filterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.rountresult = $event;
      }
      if (this.onewayfilterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.onewayresulted = $event;
      }
    },

    // -----------------return journey time filter start--------------

    TimeretFilter1() {
      this.timearrFilterData1 = !this.timearrFilterData1;

      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData1 && this.timearrFilterData2) {
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterData1 && this.timearrFilterData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterData1) {
        this.TimeFilterDataArrival6();
      } else {
        this.removeReturnFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeretFilter2() {
      this.timearrFilterData2 = !this.timearrFilterData2;
      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData1 && this.timearrFilterData2) {
        // console('2222')
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterData2 && this.timearrFilterData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterData2) {
        this.TimeFilterDataArrival2();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeReturnFilter2();
      }
    },
    TimeretFilter3() {
      this.timearrFilterData3 = !this.timearrFilterData3;
      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData3 && this.timearrFilterData2) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterData3 && this.timearrFilterData1) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterData3) {
        this.TimeFilterDataArriva18();
      } else {
        this.removeReturnFilter3();
      }
    },

    TimeretFilterarr1() {
      this.timearrFilterArrData1 = !this.timearrFilterArrData1;

      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData2) {
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterArrData1) {
        this.TimeFilterDataArrival6();
      } else {
        this.removeReturnFilter1();
      }
      // console.log(this.rountresult,'443')
    },
    TimeretFilterarr2() {
      this.timearrFilterArrData2 = !this.timearrFilterArrData2;
      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData2) {
        // console('2222')
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterArrData2 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterArrData2) {
        this.TimeFilterDataArrival2();
        // console('1111')
        // console.log(this.rountresult, '443')
      } else {
        this.removeReturnFilter2();
      }
    },
    TimeretFilterarr3() {
      this.timearrFilterArrData3 = !this.timearrFilterArrData3;
      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData2 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterArrData3) {
        this.TimeFilterDataArriva18();
      } else {
        this.removeReturnFilter3();
      }
    },

    TimeFilterDataArrival6() {
      console.log("sgninngin");
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        console.log(v, "pp");
        if ($twoFilter) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "12:00"
          ) {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "12:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    TimeFilterDataArrival2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        console.log(v, "pp");
        if ($twoFilter) {
          if (
            v.Return[0].Departure.Time > "12:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "12:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    TimeFilterDataArriva18() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        console.log(v, "pp");
        if ($twoFilter) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            // $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoReturnFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "18:00") {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "18:00") {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoReturnFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "12:00" && departureTime < "18:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "12:00" && arrivalTime < "18:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoReturnFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "12:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "12:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    totalReturnTimeData() {
      let $event = [];

      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          //  $twoFilter=false
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          //  $twoFilter=false
        } else {
          $event = this.onewayfilterData;
        }
      }

      if (this.filterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.rountresult = $event;
      }
      if (this.onewayfilterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.onewayresulted = $event;
      }
    },

    removeReturnFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Return[0].Departure.Time > "6:00" &&
            v.Return[0].Departure.Time < "12:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "6:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "12:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeReturnFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Return[0].Departure.Time > "12:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "12:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeReturnFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Return[0].Departure.Time > "6:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "6:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    airlineFilter() {
      let storeAirline = [];
      let $event = [];
      if (this.filterData.length > 0) {
        $event = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $event = this.onewayfilterData;
      }

      $event.forEach((v) => {
        if (!storeAirline.includes(v.ownername)) {
          storeAirline.push(v.ownername);
        }
      });
      storeAirline.forEach((s) => {
        let obj = {
          airline: s,
          active: true,
        };
        this.airlineList.push(obj);
      });

      this.filterAirline = this.airlineList;
    },

    selectAirline(data, index) {
      let $data = this.filterData;
      let $result = [];
      this.airlineList.map((a, i) => {
        if (i == index) {
          a.active = !a.active;
        }
        if (a.active) {
          // console.log(count,'count')
          $data.forEach((c) => {
            if (c.ownername == a.airline) {
              $result.push(c);
            }
          });
        }

        $result.sort((a, b) => a.perperson - b.perperson);
        if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
      });

      //  this.Cheapest()
      //   this.Shortest()
      // this.bestlayTime()
      //  this.Recommend()
      $result.sort((a, b) => a.perperson - b.perperson);
      this.rountresult = $result;
    },

    airlineClear() {
      let $result = [];
      let $data = [];

      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }

      this.airlineList.forEach((v, i) => {
        if (i !== 0) {
          v.active = false;
        } else {
          v.active = true;
          $data.forEach((c) => {
            if (c.ownername == v.airline) {
              $result.push(c);
            }
          });
        }
      });

      $result.sort((a, b) => a.perperson - b.perperson);
      this.rountresult = $result;
    },

    airlineSelect() {
      this.airlineList.forEach((v) => {
        v.active = true;
      });
      this.rountresult = this.filterData;
    },

    stopFilter() {
      this.filterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1 || v.Returnjourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1 || v.Returnjourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    gettimedep() {
      for (let i = 0; i < this.rountresult.length; i++) {
        // console.log(this.rountresult, "result222222222222222222222222");
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.rountresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },
    calculateTotalTime() {
      let $data = [];

      if (this.rountresult.length > 0) {
        this.rountresult.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.rountresult.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      } else if (this.onewayresulted.length > 0) {
        this.onewayresulted.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.onewayresulted.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      }
    },
    gettimeret() {
      for (let i = 0; i < this.rountresult.length; i++) {
        // console.log(this.rountresult, "result222222222222222222222222");
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.rountresult[i].Return[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    gettimedep1() {
      for (let i = 0; i < this.onewayresulted.length; i++) {
        // console.log(this.rountresult, "result222222222222222222222222");
        for (let j = 0; j < this.onewayresulted[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresulted[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresulted[i].Depature[currentIndex].Arrival.Date}T${this.onewayresulted[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresulted[i].Depature[nextIndex].Departure.Date}T${this.onewayresulted[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.onewayresulted[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    getmultitime1() {
      for (let i = 0; i < this.multicityData.length; i++) {
        for (let j = 0; j < this.multicityData[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight1.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight1[currentIndex].Arrival.Date}T${this.multicityData[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight1[nextIndex].Departure.Date}T${this.multicityData[i].Flight1[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multicityData[i].Flight1[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        for (let j = 0; j < this.multicityData[i].Flight2.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight2.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight2[currentIndex].Arrival.Date}T${this.multicityData[i].Flight2[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight2[nextIndex].Departure.Date}T${this.multicityData[i].Flight2[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multicityData[i].Flight2[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        if (this.multicityData[i].Flight3) {
          for (let j = 0; j < this.multicityData[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight3.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight3[currentIndex].Arrival.Date}T${this.multicityData[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight3[nextIndex].Departure.Date}T${this.multicityData[i].Flight3[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multicityData[i].Flight3[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }

        if (this.multicityData[i].Flight4) {
          for (let j = 0; j < this.multicityData[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight4.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight4[currentIndex].Arrival.Date}T${this.multicityData[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight4[nextIndex].Departure.Date}T${this.multicityData[i].Flight4[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multicityData[i].Flight4[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }
      }
    },

    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        const totalAmount = parseFloat(this.onewayfilterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.multiFilterData.length; i++) {
        const totalAmount = parseFloat(this.multiFilterData[i].perperson);

        console.log(totalAmount, "tatatatat,,,.....");
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min.toFixed(2);
      this.changevalue[1] = max.toFixed(2);

      this.currencyCode = this.preferCurrency;
    },

    // Filter initailly Working ------->

    onewaytrip() {
      let onewayresult = [];
      onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));

      if (onewayresult) {
        for (let i = 0; i < onewayresult.length; i++) {
          if (onewayresult[i]) {
            this.Journey = onewayresult[i].DataLists.FlightList.Flight;
            let shoppingId = onewayresult[i].ShoppingResponseId;
            let refund = [];
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = onewayresult[i].OffersGroup.AirlineOffers.Offer;

            let $data_onewayId =
              onewayresult[i].OffersGroup.AirlineOffers.Offer;

            // console.log($data_onewayId,'onewaaayyyyyyy....1...')

            let offerId = [];
            let depsplit = [];
            let resultdata1 = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];
            $data_onewayId.forEach((v) => {
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }
              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              pricedetail.push(s.OfferItem);

              s.OfferItem.forEach((v) => {
                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }

                if (v[0].FareComponent[0].SegmentRefs) {
                  resultdata1 = v[0].FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));
            });

            // console.log(depsplit, '$data')
            this.Journey1 =
              onewayresult[i].DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
            });
            // let seats = [];
            // seats = onewayresult[i].DataLists.PriceClassList.PriceClass;
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            let depjourney = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];

              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                }
              }

              depjourney.push(source);
            }

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].Baseprice = Baseprice[i];
              this.onewayresulted[i].TotalPrice = TotalPrice[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].multipleDatas = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].onewaymulticheck = true;
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].currency = currency[i];
              this.onewayresulted[i].ownerlogo = ownerlogo[i];
              this.onewayresulted[i].ownername = ownername[i];
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].perperson = perperson[i];
              this.onewayresulted[i].cabinClass = this.oneway.class;
              this.onewayresulted[i].find1 = this.find1[i];
              this.onewayresulted[i].find2 = this.find2[i];
              this.onewayresulted[i].ShoppingResponseId = shoppingId;
              this.onewayresulted[i].selectId = offerId[i];
              this.onewayresulted[i].Adult = this.aduls;
              this.onewayresulted[i].Child = this.aduls1;
              this.onewayresulted[i].Inf = this.aduls2;
              this.onewayresulted[i].radios = true;
              // this.onewayresulted[i].radiosData =false;
            }

            console.log(this.onewayresulted, "oneway.....w...w..w..w");
            this.onewaymultiData = this.onewayresulted;
            this.gettimedep1();
            this.gettimeret();
            this.calculateTotalTime();
            this.singlepriceData();
            this.airlineFilter();
            this.priceRange();
            this.Shortest();
            this.stopFilterOneway();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();

            if (this.onewayfilterData) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
          }
        }
      }
    },

    multione1(data, multindex, index) {
      // console.log(data, multindex, index, 'data, multindex,index')
      this.radiosData = 1;
      this.onewayresulted.find((c, i) => {
        if (i == index) {
          c.radios = false;
        }
      });

      this.flightdetailsData = data;
    },

    singlepriceData() {
      let $event = [];
      let $data = this.onewayresulted;
      this.onewayresulted.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.Baseprice == v.Baseprice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.onewayresulted = $event.sort((a, b) => a.perperson - b.perperson);

      this.onewayresulted.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (
            a.Baseprice == b.Baseprice &&
            a.ownername == b.ownername &&
            a.Depaturejourney.Time !== b.Depaturejourney.Time
          ) {
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.onewayresulted[i].multi = "Show more Options";
        }
      });

      this.onewayfilterData = this.onewayresulted;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    addmoreData(data, index) {
      console.log(data, index, "vasanrh");
      this.radios = 1;
      this.onewayresulted.find((v, i) => {
        if (i == index) {
          v.multipleDatas = !v.multipleDatas;
          v.onewaymulticheck = !v.onewaymulticheck;
        } else {
          v.multipleDatas = false;
          v.onewaymulticheck = true;
        }
      });

      let $event = [];
      this.onewaymultiData.forEach((v) => {
        if (data.Baseprice == v.Baseprice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });
      let $data = [];
      $event.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
      });

      this.multiplesameData = $data;
    },

    stopFilterOneway() {
      this.onewayfilterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    sended() {
      // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
      this.onewayfilterData = [];
      this.rountresult = [];
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;
        let result1 = [];

        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY-MM-DD");
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });

        // this.sended1()
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      this.onewayfilterData = [];
      this.rountresult = [];

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        let result1 = [];
        // console.log(this.oneway, "onewayonewayonewayonewayoneway");
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");

        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });

        // this.sended1()
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sended1() {
      // console.log("kkkkkkkkkkkkkkkkkk");
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      // if (this.concatenatedArray) {
      //   this.concatenatedArray.forEach((v) => {
      //     console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
      //     resul.push(v);
      //   });
      // } else {
      //   console.log(this.concatenatedArray);
      // }

      this.oneway.city = this.red1;
      this.oneway.adult = this.aduls;
      this.oneway.infrant = this.aduls2;
      this.oneway.child = this.aduls1;
      // this.oneway.class = this.select;
      let rountdetail = {
        from: null,
        to: null,
        dedate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infrant: this.oneway.infrant,
        city: this.oneway.city,
      };
      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;

          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(
            this.oneway.redate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.Rountdata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.Rountdata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;

        // console.log(
        //   this.Rountdata,
        //   "this.Rountdatathis.Rountdatathis.Rountdatathis.Rountdatathis.Rountdata"
        // );

        axios
          .post(this.makeSearchUrl, this.Rountdata, {
            headers: {
              "Content-Type": "application/json",
            },
          })

          .then((response) => {
            // console.log(response," this.responseData this.responseData this.responseData");

            this.responseData.push(response.data);

            console.log(
              this.responseData,
              "this.responseData this.responseData this.responseData"
            );

            let rountData = [];
            rountData = JSON.stringify(this.responseData);
            localStorage.setItem("rounttrip", rountData);

            let result = "";
            result = localStorage.getItem("rounttrip");
            this.localData.push(JSON.parse(result));
            // console.log(this.localData, " this.localData this.localData");
            if (this.localData) {
              this.roundedtrip();
            }

            // if (response.data.Errors =='Errors') {
            //   this.searchresult = false;
            //   this.searchloader = true;
            //   setTimeout(() => {
            //     this.pageError = true;
            //     this.searchresult = false;
            //   this.searchloader = false;
            //   }, 7000);
            // }

            // else {

            //   this.responseData.push(response.data.AirShoppingRS);
            //   console.log(this.responseData,'this.responseDatathis.responseData')
            //   console.log(
            //     this.responseData,
            //     " this.responseData this.responseData this.responseData"
            //   );
            //   let rountData = [];
            //   rountData = JSON.stringify(this.responseData);
            //   localStorage.setItem("rounttrip", rountData);
            //   let result = "";
            //   result = localStorage.getItem("rounttrip");
            //   this.localData.push(JSON.parse(result));
            //   console.log(this.localData, " this.localData this.localData");
            //   if (this.localData) {
            //     this.roundedtrip();
            //   }
            // }
          })

          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    sendedone1() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      let resul =
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(resul, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        let res = "";

        if (this.oneway.from.name) {
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate, "DD MMM-YYYY").format(
          "YYYY-MM-DD"
        );
        // let rountdetail = {
        //   from: this.roundfrom,
        //   to: this.roundto,
        //   dedate: this.urldedate,
        //   redate: this.urlredate,
        //   class: this.oneway.class,
        //   child: this.oneway.child,
        //   adult: this.oneway.adult,
        //   infrant: this.oneway.infrant,
        //   city: this.oneway.city,
        // };

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.onewaydata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.onewaydata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;

        console.log(this.onewaydata, "this.onewaydatathis.onewaydata");

        axios
          .post(this.makeSearchUrl, this.onewaydata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            // console.log(response, "responseresponseresponse");
            this.responseData.push(response.data);
            // console.log(
            //   this.responseData,
            //   "this.responseDatathis.responseData"
            // );
            let rountData = [];
            rountData = JSON.stringify(this.responseData);

            localStorage.setItem("onewaytrip", rountData);

            if (rountData) {
              this.onewaytrip();
            }

            //             if (response.data.AirShoppingRS.Errors) {
            //               this.searchresult = false;
            //               this.searchloader = true;
            //               setTimeout(() => {
            //                 this.pageError = true;
            //                 this.searchresult = false;
            //               this.searchloader = false;
            //               }, 7000);
            //             }

            // else{
            //   this.responseData.push(response.data.AirShoppingRS);
            // console.og(this.responseData,'this.responseDatathis.responseData')
            // let rountData = [];
            // rountData = JSON.stringify(this.responseData);

            // localStorage.setItem("onewaytrip", rountData);
            // this.$router.push({ path: "/search", query: rountdetail });

            // if (rountData) {
            //   this.onewaytrip();
            // }
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedMulti() {
      this.multi_city.adult = this.aduls;
      this.multi_city.child = this.aduls1;
      this.multi_city.infrant = this.aduls2;
      this.multi_city.class = this.classType;

      console.log(this.multi_city, "datasdatasdatas");

      let multicityData = {
        itinery: [],
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      };

      multicityData.class = this.multi_city.class;
      multicityData.adult = this.multi_city.adult;
      multicityData.child = this.multi_city.child;
      multicityData.infrant = this.multi_city.infrant;
      multicityData.city = this.multi_city.city;
      this.multi_city.itinery.forEach((v) => {
        console.log(v, "multy");
        let itineraryObject = {
          from: v.from.name ? v.from.name : v.from,
          to: v.to.name ? v.to.name : v.to,
          depdata: moment(v.depdata).format("YYYY-MM-DD"),
        };
        multicityData.itinery.push(itineraryObject);
      });

      console.log(multicityData, "this.multicityData5555555555555");

      setTimeout(() => {
        location.reload();
      }, 500);

      let muti_city = JSON.stringify(multicityData);
      this.$router.push({ path: "/search", query: { muti_city } });
    },

    sendedMulticity() {
      for (let i = 0; i < this.multi_city.itinery.length; i++) {
        if (this.multi_city.itinery[i].from) {
          this.multi_city.itinery[i].multiFromError = false;

          // console.log(this.multi_city.itinery[i].from, "mememememe.....");
        } else {
          this.multi_city.itinery[i].multiFromError = true;
        }

        if (this.multi_city.itinery[i].to) {
          this.multi_city.itinery[i].multiToError = false;
        } else {
          this.multi_city.itinery[i].multiToError = true;
        }

        if (this.multi_city.itinery[i].depdata) {
          this.multi_city.itinery[i].multiDateError = false;
        } else {
          this.multi_city.itinery[i].multiDateError = true;
        }
      }
    },

    selected(result) {
      console.log(result, "ppppp");
      if (result.Flight1) {
        let obj = {
          adult: this.multi_city.adult,
          child: this.multi_city.child,
          inf: this.multi_city.infrant,
          class: this.multi_city.class,
        };
        console.log(obj, "ppp");
        localStorage.setItem(
          `${"pass"}-${result.selectId}`,
          JSON.stringify(obj)
        );
        localStorage.setItem(
          `${"multi"}-${result.selectId}`,
          JSON.stringify(result)
        );
        this.$router.push({
          path: "/flightinfo",
          query: { pass_id: `${"multi"}-${result.selectId}` },
        });
      } else {
        localStorage.setItem(
          `${result.currency}-${result.selectId}`,
          JSON.stringify(result)
        );
        this.$router.push({
          path: "/flightinfo",
          query: { pass_id: `${result.currency}-${result.selectId}` },
        });
      }
    },

    fromClick($event) {
      // console.log($event, "p.....1");
      if ($event.from) {
        $event.from = "";
        this.fromErrorMessage = true;

        // console.log(this.fromErrorMessage, "p.....2");
      }
    },

    toClick($event) {
      // console.log($event, "p.....");
      if ($event.to) {
        $event.to = "";
        this.toErrorMessage = true;

        // console.log(this.toErrorMessage, "p.....3");
      }
    },

    multiFromClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;

            // console.log("vvvvv......4444");
            if (!v.from) {
              v.multiFromError = true;

              // console.log("vvvvv......555");
            } else {
              v.multiFromError = false;

              // console.log("vvvvv......666");
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;

            // console.log("vvvvv......111");
            if (!v.to) {
              // console.log("vvvvv......222");
              v.multiToError = true;
            } else {
              v.multiToError = false;

              // console.log("vvvvv......333");
            }
          }
        }
      });
    },

    onChangeFrom(event) {
      if (event.length >= 3) {
        this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      }
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
          // this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          // this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    onChangeMultiFrom(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
          } else {
            v.multiToError = true;
          }
        }
      });

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    autocompletJson() {
      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)

      const getConfigData = getConfigDataFromLocalStorage();

      this.airlineIcaoUrl =
        getConfigData.payload.portal_configuration.content_data.airports_icao.url;
      this.airlineIcaoType =
        getConfigData.payload.portal_configuration.content_data.airports_icao.type;

      axios
        .get(this.airlineIcaoUrl, {
          headers: {
            "Content-Type": this.airlineIcaoType,
          },
        })
        .then((response) => {
          let airlineIcao = response.data;
          // console.log(airlineIcao, 'respomsedrespomsedr.......')

          if (airlineIcao) {
            // console.log(airlineIcao, 'respomsedrespomsedr.......')
            Object.keys(airlineIcao).forEach((icao) => {
              const airport = airlineIcao[icao];

              if (airport.iata !== "") {
                this.fromAirports.push({
                  iata: airport.iata,
                  icao: airport.icao,
                  places: ` ${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                });
              }
            });
          }
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },

    // fetchItems() {
    //   Object.keys(userData).forEach((icao) => {
    //     const airport = userData[icao];
    //     if (airport.iata !== "") {
    //       this.fromAirports.push({
    //         iata: airport.iata,
    //         icao: airport.icao,
    //         places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //       });
    //     }
    //   });
    // },

    search(event) {
      if (event.query.length >= 3) {
        // this.maximumletter = false
        // this.maximumletter1 = false
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 50);
      } else {
        if (this.oneway.from) {
          this.maximumletter = true;
        }
        if (this.oneway.to) {
          this.maximumletter1 = true;
        }
      }
    },

    focus1() {
      setTimeout(() => {
        this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.to) {
        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        }, 100);
      }
    },

    focus2() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.dedate) {
        this.$refs.dpRef1.openMenu();
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          // console.log(this.multi_city.itinery[0].to, "bfbfbfbf.....");
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    Multifocus1(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    Multifocus2(data, index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        console.log(data, "bfbfbfbf.....");
        // return data.to.name

        // for (let i = 0; i < 1; i++) {
        //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
        //   this.input[1].from = this.input[0].to;
        // }

        for (let i = 0; i < 1; i++) {
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.isIcon = false;
      } else {
        this.isIcon = true;
      }
    },
    handleCalenderResize() {
      if (window.innerWidth < 400) {
        this.multiCalender = false;
      } else {
        this.multiCalender = true;
      }
    },

    // Form Header section

    getMultifrom(data) {
      // console.log(data, 'datatatetee')

      if (data.itinery[0].from === null) {
        return null;
      } else {
        const $data = data.itinery[0]?.from?.name;
        return $data || data.itinery[0].from;
      }

      // if (data.itinery[0].from.name) {
      //   return data.itinery[0].from.name
      // }
      // else {
      //   return data.itinery[0].from
      // }
    },
    getMultiDedate1(data) {
      if (data.itinery[0].depdata) {
        // return data.itinery[0].depdata
        return moment(data.itinery[0].depdata, "DD-MMM-YYYY").format(
          "DD-MMM-YYYY"
        );
      } else {
        return "";
      }
    },

    getMultiTo(data) {
      // console.log(data, "Testing...1...1...1.");

      if (data.itinery[data.itinery.length - 1].to === null) {
        return null;
      } else {
        const $data = data.itinery[data.itinery.length - 1]?.to?.name;
        return $data || data.itinery[data.itinery.length - 1].to;
      }
    },

    getMultiDedate2(data) {
      if (data.itinery[data.itinery.length - 1].depdata) {
        // return data.itinery[data.itinery.length - 1].depdata
        return moment(
          data.itinery[data.itinery.length - 1].depdata,
          "DD-MMM-YYYY"
        ).format("DD-MMM-YYYY");
      } else {
        return "";
      }
    },

    getMultiClass(data) {
      console.log(data, "mdatatiCadata");
      if (data.class) {
        return data.class;
      } else {
        return "";
      }
    },

    //     getMultifrom(data) {
    //       if (data.length>0) {

    //   console.log(data,'wrthofjsdosjkdjk')

    //    if (data.itinery[0].from) {
    //           return data.itinery[0].from
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate1(data) {

    //          if (data.length>0) {
    //    if (data.itinery[0].depdata) {
    //           return data.itinery[0].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },

    //     getMultiTo(data) {

    //         if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].to) {
    //           return data.itinery[data.itinery.length - 1].to
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate2(data) {

    //          if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].depdata) {
    //           return data.itinery[data.itinery.length - 1].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }
    //     },

    //     getMultiClass(data) {
    //       if (data.classes) {
    //         return data.classes
    //       }
    //       else {
    //         return ''
    //       }

    //     },
    getMultiAdult(data) {
      // console.log(data, 'lkllklklklk...22..2.2.2')
      if (data.adult > 0) {
        return data.adult;
      } else {
        return "";
      }
    },
    getMultiChild(data) {
      if (data.child > 0) {
        return data.child;
      } else {
        return "";
      }
    },
    getMultiInfrant(data) {
      if (data.infrant > 0) {
        return data.infrant;
      } else {
        return "";
      }
    },

    // Form Header section End

    getConfig() {
      // console.log(this.$store.state.data,'homehoemehojoke...')

      // let getConfigData = this.$store.state.data
      // let portalLocal = localStorage.getItem("portalData")

      // let getConfigData1 = JSON.parse(portalLocal)

      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
        this.makeSearchUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.search;
        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;
        this.preferCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;

        // console.log(this.makeSearchUrl, 'head...sea..1..')

        // this.getConfigFunc();
      }
    },
  },

  // AutoComplete Functions End

  watch: {
    classType(newSelectValue) {
      console.log("select property changed to:", newSelectValue);

      if (newSelectValue) {
        setTimeout(() => {
          const focusDrop = this.$refs.countrySelect.focus();
          if (focusDrop) {
            this.$refs.countrySelect.focus();
          }

          this.classSelect = false;
          console.log(this.classSelect);
        }, 100);
      }
    },

    RefundableData(data) {
      if (
        (data && this.nonRefundableData) ||
        (!data && !this.nonRefundableData)
      ) {
        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = this.onewayfilterData;
        } else if (this.filterData.length > 0) {
          this.rountresult = this.filterData;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = this.multiFilterData;
        }
      } else if (data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayfilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }

        if (this.filterData.length > 0) {
          let $data = this.filterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.rountresult = $result;
        }

        if (this.multiFilterData.length > 0) {
          let $data = this.multiFilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.multicityData = $result;
        }
      } else if (!data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayresulted;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }
        if (this.filterData.length > 0) {
          let $data = this.rountresult;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multicityData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          this.multicityData = $result;
        }
      }
    },

    nonRefundableData(data) {
      if ((data && this.RefundableData) || (!data && !this.RefundableData)) {
        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = this.onewayfilterData;
        } else if (this.filterData.length > 0) {
          this.rountresult = this.filterData;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = this.multiFilterData;
        }
      } else if (data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayfilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }
        if (this.filterData.length > 0) {
          console.log("ppppp", this.filterData);
          let $data = this.filterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result555");
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multiFilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.multicityData = $result;
        }
      } else if (!data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayresulted;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }

        if (this.filterData.length > 0) {
          let $data = this.rountresult;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== " Non Refundable") {
              $result.push(v);
            }
          });
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multicityData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Non Refundable") {
              $result.push(v);
            }
          });
          this.multicityData = $result;
        }
      }
    },

    changevalue(newVal) {
      this.rountresult = [];
      this.onewayresulted = [];
      let filterData = [];
      let sortAmount = [];

      console.log("Slider value changed:", newVal);

      for (let i = 0; i < this.filterData.length; i++) {
        if (
          this.filterData[i].perperson >= newVal[0] &&
          this.filterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.filterData[i]);
        }
      }
      // this.rountresult = totalPricefilter.sortBy(sortAmount, ["perperson"]);
      // console.log(this.rountresult,'pppppppppppppppppppp')

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        if (
          this.onewayfilterData[i].perperson >= newVal[0] &&
          this.onewayfilterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.onewayfilterData[i]);
        }
      }
      // this.onewayresulted = totalPricefilter.sortBy(sortAmount, ["perperson"]);

      for (let i = 0; i < this.multiFilterData.length; i++) {
        if (
          this.multiFilterData[i].perperson >= newVal[0] &&
          this.multiFilterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.multiFilterData[i]);
        }
      }
      filterData = sortAmount;
      filterData.sort((a, b) => a.perperson - b.perperson);

      if (this.multiFilterData.length > 0) {
        this.multicityData = filterData;
      } else if (this.onewayfilterData.length > 0) {
        this.onewayresulted = filterData;
      } else if (this.filterData.length > 0) {
        this.rountresult = filterData;

        console.log(this.rountresult, "0000000");
      }

      this.Shortest();
      this.Recommend();
      this.Cheapest();
      this.bestlayTime();
    },

    NonStopFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let multifilterData = [];
      let oneway = false;
      let round = false;
      let multi = false;
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      } else if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      } else {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }

      if (newVal && this.OneStopFil && this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.OneStopFil && !this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.OneStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 3) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 3) <= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OnePlusFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1 || v.Returnjourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        //  console.log('one',roundfilterData)
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 0) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops == 0 ||
              v.Flight2JourneyTime.Stops == 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) == 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) == 0
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWayncbgdghghg");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
              //  console.log("good")
            } else {
              $result.push(v);
            }
            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 0 ||
              v.Flight2JourneyTime.Stops !== 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) !== 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) !== 0
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },

    OneStopFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let multifilterData = [];
      let multi = false;
      let oneway = false;
      let round = false;
      // console.log(this.rountresult, 'this.rountresult')
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      } else if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      } else {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }

      if (newVal && this.NonStopFil && this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.NonStopFil && !this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.NonStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 4) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 4) <= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OnePlusFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0 || v.Returnjourney.Stops !== 0) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 0 ||
              v.Flight2JourneyTime.Stops !== 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) !== 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) !== 0
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        console.log("one", roundfilterData);
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 1 || v.Returnjourney.Stops == 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops == 1 ||
              v.Flight2JourneyTime.Stops == 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) == 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) == 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (!v.Depaturejourney.Stops == 1 || !v.Returnjourney.Stops == 1) {
              //  $result.push(v)
            } else {
              $result.push(v);
            }

            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },

    OnePlusFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let oneway = false;
      let round = false;
      let multifilterData = [];
      let multi = false;
      // console.log(this.rountresult, 'this.rountresult')
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      }
      if (this.filterData.length > 0) {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }
      if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      }

      if (newVal && this.NonStopFil && this.OneStopFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.NonStopFil && !this.OneStopFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.NonStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1 || v.Returnjourney.Stops !== 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OneStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops >= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops >= 1 || v.Returnjourney.Stops >= 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops >= 1 ||
              v.Flight2JourneyTime.Stops >= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) >= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) >= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        console.log("one", roundfilterData);
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops > 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (roundfilterData.length > 0) {
          console.log(newVal, "newVal");
          console.log(
            roundfilterData,
            "roundfilterDataroundfilterDataroundfilterData"
          );
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1) {
              console.log(v.Depaturejourney.Stops, "v.Depaturejourney.Stops");
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWayFilterDataWay");
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops > 1 ||
              v.Flight2JourneyTime.Stops > 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) > 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) > 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              $result.push(v);
            }
            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 3) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 3) <= 1
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },
  },

  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },

  created() {
    this.getConfig();

    this.airlineLogo_func();

    localStorage.removeItem("rounttrip");
    localStorage.removeItem("onewaytrip");

    this.red = this.$route.query.city;
    this.red1 = this.$route.query.city;

    // console.log(this.red,'nandupillai',this.red1,'nandupillai')

    this.autocompletJson();

    if (this.red || this.red1) {
      this.roundData();
      this.mydata();
      // this.filterShowing();
    } else {
      this.roundModify = false;
      this.onewayModify = false;
      this.multiModify = true;
      this.red1 = 3;
      console.log(this.red1, "reeddd111....");
      // this.paxhide = false
      localStorage.removeItem("rountData");
      this.multi_city = JSON.parse(this.$route.query.muti_city);

      this.classType = this.multi_city.class;

      console.log(
        this.multi_city,
        "this.showMulti_datauerthis.showMulti_datay"
      );

      // this.showMulti_data = this.multi_city
      // this.formMultiData = JSON.parse(this.$route.query.muti_city);
      this.multicityresult();

      // this.filterShowing();

      console.log(this.multi_city, "this.$route.querythis.$route.query....1");
      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
      }

      if (this.multi_city.itinery.length == 4) {
        console.log(
          this.multi_city,
          this.multi_city.itinery.length,
          "lengthlen.....gthlength"
        );
        this.addmore = false;
      }
    }

    // console.log(this.red1, "this.red1 this.red1 this.red1 this.red1 ");
    if (this.red1 == 1) {
      this.sended1();
    }
    if (this.red1 == 2) {
      this.sendedone1();
    }
    this.generateBookingRefId();
    this.actActive();
    this.airlogodata = airLogo;
    this.deatailData();
    this.clicked1 = true;
    this.clicked2 = true;
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleCalenderResize);
  },

  mounted() {
    document.getElementById("navbar").style.display = "none";
    document.getElementById("food").style.display = "none";

    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleCalenderResize);
    // this.autocompletJson();
    // this.fetchItems();
    this.handleResize();
    this.handleCalenderResize();
  },
};
</script>

<style scoped>
/* Filter By Section*/

>>> .filter_icons .v-selection-control--density-default {
  --v-selection-control-size: 30px !important;
}

>>> .filter_icons .v-selection-control .v-label {
  font-size: 14px;
  font-weight: 500 !important;
}

>>> .filter_icons .v-selection-control__input .v-icon {
  font-size: 20px !important;
}

>>> .filter_icons .price_slider .v-slider-track {
  --v-slider-track-size: 3px !important;
}

>>> .filter_icons .price_slider .v-slider-thumb {
  --v-slider-thumb-size: 16px !important;
}

/* Filter By Section End*/

.bg-image {
  height: auto;
  background-image: url("../assets/tourist_vector.jpg");
  background-size: 100% 100%;
  background-position: left;
}

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100%;
}

>>> .dp__input_icon {
  cursor: pointer;
  position: absolute;
  top: 64%;
  left: 82%;
  color: black !important;
  transform: translateY(-50%);
  color: var(--dp-icon-color);
}

.mdi-menu-down::before {
  content: "\F035D222";
  display: none !important;
}

.v-input__append > .v-icon {
  position: relative;
  right: 43px;
  top: -4px;
}

.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
  top: -10px;
}

.labeling {
  position: relative;
  bottom: 9px;
}
/* 
.v-input--density-default {
  --v-input-control-height: 38px;
  position: relative;
  top: -22px;
} */

.v-slider.v-input--horizontal {
  position: relative;
  top: 0px;
}

.cart-adult {
  padding: 15px;
  width: 500px;
  cursor: pointer;
  position: relative;
  top: -65px;
}

>>> .dp__clear_icon {
  display: black;
  position: absolute;
  top: 50%;
  /* right: 0; */
  transform: translateY(-50%);
  cursor: pointer;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.price-head .price-currency {
  font-size: 18px;
  font-weight: 600;
  color: black;
  padding-top: 17px;
  padding-right: 6px;
  /* font-size: 25px;
  font-weight: 600;
  color: black;
  padding-top: 6px;
  padding-right: 6px; */
}

.price-head .price-Amount {
  font-size: 36px;
  font-weight: 600;
  color: black;
}

.radio {
  /* background-color: #FFB80A; */
  border-radius: 5px;
  color: black !important;
  width: 120px;
  font-size: 15px !important;
  font-weight: 400px;
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400;
  height: 30px;
  width: auto;
  /* width: 150px; */
}

.air-title {
  background-color: #ff5400;
  color: white;
  padding: 5px;
}

.cart1 {
  box-shadow: none;
  border: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  max-width: 208px;
  border-radius: 5px;
}

.cart1 p {
  text-align: center;
}

hr {
  margin: 0rem 0 !important;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.rec-num {
  font-size: 14px;
  font-weight: 600;
  color: navy;
}

.bord {
  border-right: 2px solid gray;
  position: relative;
  top: -2px;
  height: 63px;
}

.depar {
  background-color: lightgray;
  padding: 3px;
}

.res-name {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
  padding: 0px 0px 0px 12px;
}

.res-stop {
  font-size: 12px;
  font-weight: 600;
  color: grey;
}

/* .res-stop {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
  right: 12px;
  color: grey;
} */

.res-time {
  font-size: 11px;
  font-weight: 500;
}

.dot-1 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 42%;
  left: 30%;
  z-index: 1;
}

.dot-2 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 18%;
  left: 50%;
  z-index: 1;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 14px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

>>> .v-input__details {
  display: none;
}

.v-input--density-default {
  --v-input-control-height: 38px;
}

.stop {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
}

.v-slider.v-input--horizontal .v-slider-thumb {
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 3px solid #3c3cde;
  border-radius: 50px;
}

.v-slider.v-input--horizontal .v-slider-track__fill {
  height: 3px;
  background-color: #7474e0;
}

/*  */
.v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}

.v-checkbox .v-selection-control {
  min-height: var(--v-input-control-height);
  position: relative;
  top: 12px;
}

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.mdi-airplane-takeoff {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

.mdi-airplane-landing {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

.p-error {
  font-size: 12px;
  color: red;
  position: absolute;
}

.dropdown-box {
  padding: 8px 10px;
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.p-float-label label {
  position: absolute;
  pointer-events: none;
  top: 14px !important;
  left: 0px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  /* font-size: 16px;
  color: #242424;
  font-weight:600; */
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 4px;
  padding-bottom: 4px; */
}

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

.dp__icon {
  display: block;
}

.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  justify-content: flex-start;
}

>>> .dp__input {
  border: none;
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding-left: 2px;
  padding-right: 2px;
  height: 24px;
  font-family: "Poppins", sans-serif !important;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  position: relative;
}

.label-top {
  position: absolute;
  top: -10px !important;
  left: -48px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.label-top1 {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #242424;
  font-size: 16px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  left: -8px !important;
  bottom: 0px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: #242424;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute !important;
  top: 28px !important;
  left: -9px !important;
  transform: translateY(-100%);
  background-color: transparent !important;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.hoverData1 {
  position: absolute;
  left: 50%;
  top: -33px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop1 {
  position: absolute;
  left: 38.4%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.hoverDataStop2 {
  position: absolute;
  left: 62%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* cursor: pointer; */
}

/* .content:hover{
  width:100%;
  font-size:13px;
} */
.hoverData2 {
  position: absolute;
  left: 63%;
  bottom: 63px;
  border-radius: 5px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverData1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverData2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.refunt {
  color: blue;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 1px;
}

>>> .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.filter-toggle {
  display: none;
}

.v-card {
  border-radius: 10px !important;
  z-index: revert-layer;
  overflow: unset;
}

.hoveredCard:hover {
  cursor: pointer;
  /* box-shadow: 0px 0px 4px 0px black; */
  /* box-shadow: none; */
  /* border: 2px solid lightgray; */
  /* border-radius: 8px !important; */
}

.card-in {
  box-shadow: none;
}

/* .card-in:hover {
  box-shadow: 0px 0px 1px 0px black;
} */

.card-in {
  box-shadow: 0px 0px 1px 0px black;
}

.time-section .v-icon {
  font-size: 12px !important;
  color: grey;
}

.time-section span {
  font-size: 9px;
  margin-left: 5px;
  font-weight: 500;
  color: grey;
}

.clear_style {
  border: 1px solid #ffa0a0;
  border-radius: 5px;
  padding: 4px 6px;
  height: 30px;
  cursor: pointer;
}

.clear_style:hover {
  background-color: #ffe9e9;
}

.add_style {
  border: 1px solid #a0ffa0;
  border-radius: 5px;
  padding: 4px;
  height: 30px;
  cursor: pointer;
}

.add_style:hover {
  background-color: #e9ffe9;
}

@media only screen and (max-width: 991px) {
  .filter-toggle {
    display: block;
  }

  .magnify-btn .v-btn {
    width: 140px !important;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
    padding: 0px 0px 0px 12px;
  }

  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */

  .time-section .v-icon {
    font-size: 12px !important;
    color: grey;
  }

  .time-section span {
    font-size: 9px !important;
    font-weight: 500 !important;
    color: grey;
  }

  .airline-logo {
    padding: 5px;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}

@media only screen and (min-width: 479px) {
  .aircode-hide {
    display: block;
  }

  .aircode-show {
    display: none;
  }

  .airlineLogo-hide {
    display: flex !important;
  }

  .airlineLogo-show {
    display: none !important;
  }

  .select-section-hide {
    display: flex !important;
  }

  .select-section-hide1 {
    display: flex !important;
  }

  .date-section-hide {
    display: block;
  }

  .date-section-show {
    display: none;
  }

  .fare-detail-mobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .hoverDataStop1 {
    left: 38%;
  }

  .hoverDataStop2 {
    left: 59%;
  }

  .airlineLogo-hide {
    display: none !important;
  }

  .airlineLogo-show {
    display: block !important;
  }

  .pax-fonts span[data-v-92fd54d6] {
    font-size: 12px;
    color: #242424;
    font-weight: 700;
  }

  .aircode-hide {
    display: none;
  }

  .aircode-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-section-hide {
    display: none !important;
  }

  .select-section-hide1 {
    display: none !important;
  }

  .res-name {
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }

  .airlineLogo-show .mbl-price-section {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .mbl-price-section .price-currency {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: black;
    padding-top: 10px;
    padding-right: 4px;
  }

  .airlineLogo-show .mbl-price-section .price-Amount {
    margin-bottom: 0px !important;
    font-size: 26px !important;
  }

  .airlineLogo-show .mbl-price-section .content {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .v-btn {
    height: 30px !important;
  }

  >>> .airlineLogo-show .v-btn .v-btn__content {
    font-size: 15px !important;
  }

  .date-section-hide {
    display: none;
  }

  .date-section-show {
    display: block;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }

  .fare-detail-mobile {
    display: block;
  }

  .fare-detail-mobile .v-card-text {
    color: rgb(51, 102, 204);
    font-weight: 500;
    padding-left: 0 !important;
    text-align: left;
    width: auto !important;
  }

  .fare-detail-mobile .v-icon {
    font-size: 24px !important;
    height: 18px !important;
    width: 24px !important;
    position: relative !important;
    right: 60px !important;
    top: 2px !important;
  }
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 38px;
}

.input-styles-1 {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
  width: 100%;
}

.form-card {
  width: 90%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none !important;
}

.back-bg {
  margin: 20px;
  width: 500px;
  height: 500px;
  border-radius: 30px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.round-mode,
.oneway-mode {
  padding: 10px 10px;
  overflow: unset;
  box-shadow: none;
  border: 2px solid lightgray;
  border-radius: 5px !important;
}

/* Traveller Pax Dropdown section */

>>> .input-styles .select-1 {
  --v-field-padding-top--plain-underlined: 12px;
}

>>> .input-styles .select-1 .v-input__control {
  height: 36px;
}

>>> .input-styles .select-1 .v-field__input {
  padding-top: 2;
  align-items: baseline;
}

>>> .input-styles .select-1 .v-field__append-inner {
  padding-top: 0;
}

>>> .select-2 .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}

>>> .select-2 .v-input--density-default {
  top: 0;
}

>>> .select-2 .v-input__control {
  height: 28px;
}

>>> .select-2 .v-field__input {
  padding-top: 0px;
  align-items: baseline;
}

>>> .select-2 .v-field__append-inner {
  padding-top: 0px;
}

/* Traveller Pax Dropdown section End */

.p-float-label .p-inputwrapper-focus ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 600;
}

.p-float-label .p-inputwrapper-filled ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 600;
}

.p-inputtext {
  font-size: 16px !important;
  font-weight: 600px !important;
}

.pax-fonts span {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

>>> .dp__month_year_select {
  pointer-events: none;
}

>>> .dp__today {
  border: 1px solid #ff6b0a !important;
}

>>> .dp__date_hover:hover {
  background: #ff6b0a;
  color: white;
}

>>> .dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
  top: -10px !important;
  left: -50px !important;
  transform: none !important;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.recment {
  border: 1px solid rgb(31 206 229);
  background-color: #bae9e45e;
}

.normal-rec {
  border: 1px solid rgb(163, 161, 161) !important;
}

.top_filterprice {
  font-size: 18px;
  font-weight: 500;
}

.top_filterTitle {
  font-size: 14px;
}
.mutilti_data {
  border-radius: 10px;
  padding: 2px 4px;
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}
</style>
