<template>
    <div v-if="ResetBox">
        <div class="trip">
            Create Your Password
        </div>
        <div>
            <v-form v-model="valid" ref="form" class="form_styles" @submit.prevent="resetSubmit">
                <div class="pre-card">
                    <v-card style="width: 550px;padding: 30px;">
                        <div class="col-12">
                            <v-text-field v-model="resetDetails.newPassword" :rules="uPass" label="New Password"
                                placeholder="Enter New password" variant="underlined" required
                                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                style="height:60px;font-size: 15px !important"></v-text-field>
                        </div>

                        <div class="col-12">
                            <v-text-field v-model="resetDetails.conPassword" :rules="uPass" label="Confirm Password"
                                placeholder="Enter Confirm password" variant="underlined" required
                                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                style="height:60px;font-size: 15px !important"></v-text-field>
                        </div>

                        <div class="d-flex mt-3">
                            <div class="col-9 d-flex justify-end">
                                <v-btn style="background-color:#ff6b0a; color:white" width="60%" :disabled="btnDisabled"
                                    type="submit">Reset</v-btn>
                            </div>
                            <div class="col-3 ps-1 d-flex justify-end">
                                <v-progress-circular v-if="loadRest" :width="3" style="color:navy"
                                    indeterminate></v-progress-circular>
                            </div>
                        </div>
                    </v-card>
                </div>
            </v-form>
        </div>
    </div>



    <v-dialog v-model="dialogSuccess">
        <v-card class="w-50 m-auto py-4">
            <div class="d-flex justify-center flex-wrap">
                <h2>Password updated successfully.</h2>
            </div>

            <v-btn width="160px" color="#ff6b0a" class="m-auto mt-4" to="/" @click="this.dialogSuccess = false">OK</v-btn>
        </v-card>
    </v-dialog>

    <div v-if="overlay">
        <dataloader></dataloader>
    </div>
</template>


<script>
import axios from 'axios'
import dataloader from "@/components/dataLoder.vue";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

// import axios from 'axios'

export default {

    components: {
        dataloader
    },

    data() {
        return {
            ResetBox: false,
            terms: false,
            visible: false,
            loadRest: false,
            btnDisabled: false,

            loginshow: true,
            resetshow: false,


            resetDetails: {
                newPassword: "",
                conPassword: "",
            },

            eMail: [
                v => !!v || 'Please enter your email',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            uPass: [
                v => !!v || 'Please enter your Password'
            ],

            getId: "",
            getMail: "",

            userRequest: {
                "request_type": "reset_password",
                "email": "irwogms@gmail.com",
                "enkey": "qt3cU5nWjV2OPeQBEYkwD4adr8oy9I",
                "user_id": "736473",
                "portal_id": "port8787"
            },

            resetRequest: {
                "request_type": "change_password",
                "new_password": "ekfjhury84r8",
                "confirm_password": "ekfjhury84r8",
                "email": "irwogms@gail.com",
                "user_id": "736473",
                "portal_id": "port8787"
            },

            checkPassword: "",
            updatePassword: "",
        }
    },
    methods: {

        checkingReset() {
            this.userRequest.enkey = this.getId
            this.userRequest.email = this.getMail
            this.userRequest.user_id = this.getMail

            this.userRequest.portal_id = this.portalId

            console.log(this.userRequest, 'this.userRequestthis.userRequest')

            axios.post(this.checkPassword, this.userRequest, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

                .then(response => {
                    console.log(response, "dfsdvdff")
                    if (response.data.reset_password_response == 'success') {
                        this.ResetBox = true
                    }
                    else if (response.data.reset_password_response == 'fail') {
                        this.ResetBox = false
                        this.$router.push('/')
                    }
                    else {
                        this.ResetBox = false
                        this.$router.push('/')
                    }
                })
                .catch(errors => {
                    console.log(errors, "eeeerrrrroooossssss......")
                    this.ResetBox = false
                    this.$router.push('/')
                })

        },


        resetSubmit() {
            this.overlay = true;

            if (this.valid) {
                this.resetRequest.email = this.getMail
                this.resetRequest.user_id = this.getMail
                this.resetRequest.new_password = this.resetDetails.newPassword
                this.resetRequest.confirm_password = this.resetDetails.conPassword

                this.resetRequest.portal_id = this.portalId

                console.log(this.resetRequest, 'resetRequest:....')

                axios.post(this.updatePassword, this.resetRequest, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                    .then(response => {
                        console.log(response, "response for reset pass.....")

                        setTimeout(() => {
                            this.overlay = false;
                            this.dialogSuccess = true
                        }, 2000)

                    })
                    .catch(errors => {
                        console.log(errors, "dfsdvdff")
                    })
            }
        },

        getConfig() {
            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData1 = JSON.parse(portalLocal)
            const getConfigData1 = getConfigDataFromLocalStorage();
            if (getConfigData1) {
                this.checkPassword = getConfigData1.payload.portal_configuration.API_endpoints.check_password
                this.updatePassword = getConfigData1.payload.portal_configuration.API_endpoints.update_password

                this.portalId = getConfigData1.payload.portal_configuration.portal_id
            }
        },
    },

    mounted() {
        document.getElementById("food").style.cssText = `position: relative; bottom: 0; width: 100%;`;
    },

    created() {
        this.getConfig();

        this.getId = this.$route.query.enkey
        this.getMail = this.$route.query.email
        console.log(this.getId, this.getMail, 'gggggIIiiddi')

        this.checkingReset()
    }




}
</script>



<style scoped>
.trip {
    justify-content: center;
    display: flex;
    background-color: #f3d8cf;
    padding: 30px;
    font-size: 25px;
    font-weight: 600;
}

.pre-card {
    display: flex;
    justify-content: center;
    border-radius: 15px;
}

/* Login Page */

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
    background-color: transparent;
    margin: 5px;
    padding: 2px;
    box-shadow: none;
}

.btn-active {
    background-color: blueviolet;
    color: white;
}

.v-checkbox .v-input__details {
    display: none;
}

>>>.v-label--clickable {
    cursor: pointer;
    font-size: 12px;
}

>>>.v-input__details {
    display: block !important;
}

.forgotStyle {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
}

/* Login Page End */


.form_styles {
    height: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
}




@media only screen and (max-width: 992px) {

    .form-btn {
        padding: 10px 0px 0px 0px !important;
        /* color: white; */
    }

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}
</style>
