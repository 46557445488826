<template>
  <div>
    <button @click="printPage">Print</button>
    <div id="printableContent">
      <!-- Your content to be printed -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    printPage() {
      // Create an iframe element
      let iframe = document.createElement('iframe');
      iframe.style.height = '0';
      iframe.style.width = '0';
      iframe.style.position = 'absolute';
      document.body.appendChild(iframe);

      // Get the content to print
      let printContent = document.getElementById('printableContent').innerHTML;

      // Write content into the iframe
      let doc = iframe.contentWindow.document;
      doc.open();
      doc.write(printContent);
      doc.close();

      // Print the iframe
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove the iframe from the DOM after printing
      setTimeout(function() {
        document.body.removeChild(iframe);
      }, 1000);
    }
  }
}
</script>
