<template>
  <header :class="{ 'sticky': isSticky }" style="background-color: rgb(247 239 241 / 64%)"
    v-click-outside="onClickOutside">

    <div class="container">

      <nav class="navbar navbar-expand-lg px-3 navbar-fixed-top">

        <div>
          <a href="https://buymytrip.com">
            <v-img :src="portalLogo" style="height: 55px; cursor: pointer; width: 200px;"  @click="$router.push('/')" />
          </a>
        </div>
        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
              </button> -->
        <v-spacer></v-spacer>

        <div class="align-center mx-2 contact-section-mobile" v-if="headerMenus.includes('country')">
          <v-menu location="bottom">
            <template v-slot:activator="{ props }">
              <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer;">
                <div>
                  <v-img :src="display_flagLogo" width="30px" height="30px" style="border-radius:50%;" />
                </div>
              </v-card>
            </template>
            <v-list style="cursor: pointer">
              <v-list-item class="hoverList">
                <div v-for="(country, index) in countryOptions" :key="index">
                  <div v-if="country.currency !== currencyData.display">
                    <a :href="country.portal_url" style="text-decoration: none;">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="country.flag_logo" width="30px" height="30px" style="border-radius:50%;" />
                        </div>
                        <span class="ps-1" style="font-size: 15px;font-weight:   ;color:black">{{ country.languages
                        }}</span>
                        <span class="ps-1" style="font-size: 15px;font-weight:   ;color:black">{{ country.currency }}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>



        <div class="dropdown navbar-toggler" id="dropdownExample">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" style="width:50px; height:30px;">
            <span class="navbar-toggler-icon" style="height:15px;"></span>
          </button>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
            <div class="dropdown-item ps-2" v-if="headerMenus.includes('signin')">
              <v-btn @click="login" depressed color="#ff6b0a" height="35px" rounded="1"
                style="font-size: 12px !important; color: white; text-transform: none !important;">
                Sign in/up
              </v-btn>
            </div>

            <div v-if="menuData.includes('mybookings')">
              <v-btn @click="$router.push('/mybooking')" depressed color="#ff6b0a" height="35px" rounded="1"
                style="font-size: 12px !important; color: white; text-transform: none !important;">
                My Bookings
              </v-btn>
              <!-- <span @click="$router.push('/mybooking')" class="booking">My Bookings</span> -->
            </div>


          

            <div class="contact-section-collapse">
              <v-btn v-if="headerMenus.includes('email')" :href="'mailto:' + emailPortal"
                class="shadow-none contact-box mt-3 mx-2" rounded="2"><v-icon class="me-2" left>mdi-email</v-icon>
                <span class="text-lowercase">{{ emailPortal }}</span></v-btn>
              <!-- <v-btn v-if="headerMenus.includes('phone')" :href="'tel:' + phonePortal"
                class="shadow-none contact-box mx-2 mt-3 mb-3" rounded="2"><v-icon class="pe-2" left>mdi-phone</v-icon>
                <span>{{ phonePortal }}</span></v-btn> -->
            </div>
          </div>
        </div>




                <!-- 
                          <div class="dropdown mt-2" id="dropdownExample">
              <button class="btn btn-secondary dropdown-toggle  navbar-toggler" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                
              </button>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
              </div>
          </div> -->




        <!-- ------------- Desktop Show ----------- -->

        <div class="justify-end contact-section-large" v-for="(menus, index) in headerMenus" :key="index">
          <div class="contact-section">
            <v-btn v-if="menus == 'email'" :href="'mailto:' + emailPortal" class="shadow-none contact-box"
              rounded="2"><v-icon class="me-2" left>mdi-email</v-icon>
              <span class="text-lowercase">{{ emailPortal }}</span></v-btn>
            <!-- <v-btn v-if="menus == 'phone'" :href="'tel:' + phonePortal" class="shadow-none contact-box mx-2"
              rounded="2"><v-icon class="pe-2" left>mdi-phone</v-icon>
              <span>{{ phonePortal }}</span></v-btn> -->
          </div>




          <div class="d-flex align-center mx-3" v-if="menus == 'country'">
            <v-menu location="bottom" open-on-hover>
              <template v-slot:activator="{ props }">
                <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer;">
                  <div class="d-flex align-center ps-3 pe-3">
                    <div>
                      <v-img :src="display_flagLogo" width="30px" height="30px" style="border-radius:50%;" />
                    </div>
                    <span class="ps-1 currency-text" style="font-size: 15px;font-weight: 400;color:black">{{ portalLanguage
                    }}</span>
                    <span class="ps-1 currency-text" style="font-size: 15px;font-weight: 400;color:black">{{
                      currencyData.display }}</span>
                  </div>
                </v-card>
              </template>
              <v-list style="cursor: pointer">
                <v-list-item class="hoverList">
                  <div v-for="(country, index) in countryOptions" :key="index">
                    <div v-if="country.currency !== currencyData.display">
                      <a :href="country.portal_url" style="text-decoration: none;">
                        <div class="d-flex align-center">
                          <div>
                            <v-img :src="country.flag_logo" width="30px" height="30px" style="border-radius:50%;" />
                          </div>
                          <span class="ps-1" style="font-size: 15px;font-weight:   ;color:black">{{ country.languages
                          }}</span>
                          <span class="ps-1" style="font-size: 15px;font-weight:   ;color:black">{{ country.currency
                          }}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          

          <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="menus == 'signin'">
            <div v-if="menuData.includes('mybookings')">
              <span  @click="$router.push('/mybooking')" class="booking">My Bookings</span>
            </div>
            <div v-if="userDetails">
              <div style="margin-right:50px;cursor: pointer;" @click="signIn">
                <div style="margin-left:20px;" class="booking" :class="{ 'booking-active': loginform }">
                  <span>
                    <v-icon>mdi-account</v-icon>
                  </span>
                  <span> Sign in </span>
                </div>
              </div>
            </div>

            <div v-if="userProfile" style="width: 18%; margin-left: 35px; margin-right: 35px;">
              <v-menu style="cursor: pointer" min-width="200px" rounded open-on-hover>
                <template v-slot:activator="{ props }">
                  <div class="d-flex align-center">
                    <v-btn icon v-bind="props">
                      <v-avatar color="light" size="large">
                        <v-icon icon="mdi-account-circle"></v-icon>
                      </v-avatar>
                    </v-btn>

                    <!-- <h5 class="text-light px-2 m-0">My Profile</h5> -->
                  </div>
                </template>

                <v-card>
                  <v-card-text>
                    <div class="mx-auto text-center">
                      <v-avatar color="brown">
                        <v-icon icon="mdi-account-circle"></v-icon>
                        <!-- <span class="text-h5">{{ user.initials }}</span> -->
                      </v-avatar>

                      <p style="margin-bottom:1px !important"> {{ userEmail }}</p>
                      <p style="margin-bottom:1px !important">{{ userName }}</p>
                      <!-- <p class="text-caption mt-1">
                        {{ user.email }}
                      </p> -->
                      <v-divider v-if="menuData.includes('viewbookings')" class="my-3"></v-divider>
                      <v-btn v-if="menuData.includes('viewbookings')" rounded variant="text" @click="booking()"> View Bookings</v-btn>
                      <div>
                      </div>
                      <v-divider class="my-3"></v-divider>
                      <v-btn rounded variant="text" @click="deleteItem()">
                        Log Out
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>

        </div>
      </nav>

    </div>


    <div v-if="loginform">
      <loginCom @close="closeLogin()"></loginCom>
    </div>
    <!-- <div v-if="registerform">
      <registerCom @close="closeRegister()"></registerCom>
    </div> -->
  </header>


  <!-- <div v-if="loginform">
    <loginCom @close="closeLogin()"></loginCom>
  </div>
  <div v-if="registerform">
    <registerCom @close="closeRegister()"></registerCom>
  </div> -->
</template>

<script>
// import loginCom from '@/components/loginCom.vue'
import loginCom from "@/components/loginCom.vue";
// import registerCom from "@/components/registerCom.vue";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';


// import axios from "axios";
// import { onMounted } from 'vue';


// import { onMounted } from 'vue';
// import { mapGetters } from 'vuex';
// import { useStore } from 'vuex';

// setTimeout(() => {
//   onMounted(() => {
//     // this won't work.
//     console.log(this.$store.state.data,'head')
//   })
// }, 100)

export default {
  components: {
    // registerCom,
    loginCom,

  },
  data() {
    return {
      loginform: false,
      registerform: false,
      selectedCountry: null,
      isSticky: false,
      conDetails: true,
      menuData:"",
      // CurrencyItems: [
      //   { name: "EN CA$", src: require("@/assets/CA.svg"), link: "https://buymytrip.ca/" },
      //   // { name: "EN AU$", src: require("@/assets/AU.svg"), link:"https://buymytrip.com.au/"},
      //   // { name: "EN US$", src: require("@/assets/US.svg"), link:"https://buymytrip.com/"},
      //   // { name: "EN UK£", src: require("@/assets/GB.svg"), link:"https://buymytrip.co.uk/"},
      // ],

      PortMail: "us-bookings@buymytrip.com",
      PortContact: "+1-659 251 2000",

      portCurrency: "EN US$",
      portCurrencyLogo: require('@/assets/US.svg'),


      hostName: "",


      userDetails: true,
      userProfile: false,
      userEmail: "",
      userName: "",
      portalLogo: "",
      emailPortal: '',
      phonePortal: '',
      countryOptions: [],
      languagePortal: '',
      currencyPortal: '',




      user: {
        initials: "",
        // fullName: 'John Doe',
        email: "",
      },


      // configReq: {
      //   "service_type": "GetPortalConfigRQ",
      //   "api_ver": "1",
      //   "payload": {
      //     "domain_name": "buymytrip.com",
      //     "env": "CERT",
      //     "language": "EN",
      //     "token": "jsdjOJDKJ7675"
      //   }
      // },


      headerMenus: [],
      // menuEmail:"",
      // menuPhone:"",
    };
  },
  methods: {


    portalLink(countryOptions) {
      return countryOptions[0].portal_url
    },
    portallogo(countryOptions) {
      return countryOptions[0].flag_logo
    },
    // portalLanguage(countryOptions) {
    //   return countryOptions[0].languages
    // },
    // portalcurrency(currencyData) {
    //   console.log(currencyData,'currencyDatacu')
    //   return currencyData.display
    // },

    handleScroll() {
      this.isSticky = window.scrollY > 90;
    },
    signIn() {
      this.loginform = !this.loginform;
      // this.registerform = false;
    },


    onClickOutside() {
      this.loginform = false
      // console.log(this.loginform, "666666")
    },


    closeLogin() {
      this.loginform = false;
      // console.log(this.loginform, "4444444")
    },
    register() {
      this.registerform = !this.registerform; // Toggle the value of registerform
      this.loginform = false; // Close the login form if open
    },
    closeRegister() {
      this.registerform = false;
    },

    getUser() {
      let userData = JSON.parse(localStorage.getItem("value"));
      // console.log(userData, "user....");
      if (userData) {
        this.userDetails = false;
        this.userProfile = true;
      } else {
        this.userDetails = true;
        this.userProfile = false;
      }
    },
    booking() {
      this.$router.push('/dashboard')
    },
    deleteItem() {
      localStorage.removeItem("value");
      setInterval(() => {
        location.reload();
      }, 500);
      this.userDetails = true;
      this.userProfile = false;
      this.$router.push('/')
    },

    getLoginDatas() {
      JSON.parse(localStorage.getItem("loginDatas"));
      // console.log(userItems, "loginddddd......");
      // this.user.email = userItems.userEmail
      // this.user.email = userItems.userPass
    },
    getlogo(data) {
      console.log(data, 'duifuisddsddjik')
    },




    getConfigFunc() {


      // Parse the decrypted data as JSON
      const getConfigData = getConfigDataFromLocalStorage();




      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)

      console.log(getConfigData, 'head...1')

      // let getConfigData = this.$store.state.data

      if (getConfigData) {

        let Menus = ""
        Menus = getConfigData.payload.portal_configuration.menus.enabled.header
        this.menuData=getConfigData.payload.portal_configuration.menus.enabled.header
        // console.log(this.menuData.includes('viewbookings'),'MenusMenus3333')
        this.headerMenus = Menus.split(', ')

        // console.log(this.headerMenus, "MenusMenusMenusMenus")

        // getConfigData.payload.portal_configuration.siteTitle
        // this.menuEmail = this.Menus.header.email
        // this.menuPhone = this.Menus.header.phone
        // console.log(this.menuEmail,"pmpmpmpmpm...1")
        // console.log(this.menuPhone,"pmpmpmpmpm....2")

        this.portalLogo = getConfigData.payload.portal_configuration.logo_path
        this.phonePortal = getConfigData.payload.portal_configuration.phone
        this.emailPortal = getConfigData.payload.portal_configuration.email
        this.currencyData = getConfigData.payload.portal_configuration.currency
        this.portalLanguage = getConfigData.payload.portal_configuration.language
        this.countryOptions = getConfigData.payload.portal_configuration.country_options

        this.countryOptions.forEach(v => {
          if (v.currency == this.currencyData.display) {
            this.display_flagLogo = v.flag_logo
            // console.log(this.display_flagLogo, "currencyDatacurrencyData")
          }
        })

      }
    }

  },


  created() {
    // this.envPortal();
    this.getConfigFunc();
    let $useresult = JSON.parse(localStorage.getItem('loginuser'))
    // console.log($useresult, '$useresult $useresult ')
    if ($useresult) {
      this.userEmail = $useresult.user.email
      this.userName = $useresult.user.name
    }



  },

  mounted() {

    // setTimeout(() => {
    // this.getConfigFunc();
    // }, 500)



    window.addEventListener("scroll", this.handleScroll);
    this.getUser();
    this.getLoginDatas();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },


};
</script>

<style>
/* navbar */
.logo {
  width: 180px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8f4f5 !important;
  z-index: 999;
  animation: fadeInDown .6s ease-in-out 0s 1 normal none running;
  transition: .6s;
  box-shadow: 0px 0px 20px 0px black;

  /* Add any other styles you need for the sticky header */
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-18);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-400);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

.v-btn__content {
  /* color: #FF5400; */
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}

.bgimg {
  background-image: url("@/assets/CA.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}


.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  /* color: white; */
}

.btn-active {
  background-color: blueviolet;
  /* color: white; */
}

.v-checkbox .v-input__details {
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-toggler {
  border: none !important;
}

.dropdown-toggle {
  white-space: nowrap;
  border: 1px solid !important;
}

/* .v-app-bar {
  height: 100px;
  background-image: url('../assets/flight.jpg');
  background-size: 100% 100%;
} */

/* .btn-box,
.pipe {
  color: white !important;
} */


.contact-box {
  box-shadow: none;
  background: none;
  border: 1px solid lightgray;
}

.contact-box span {
  font-size: 15px;
}

.hoverList:hover {
  background-color: lightgrey;
}

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;

}

.booking:hover {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

.booking-active {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

@media only screen and (max-width:1200px) {
  .currency-text {
    display: none;
  }
}

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;
}

@media only screen and (min-width:992px) {
  .contact-section-mobile {
    display: none !important;
  }

  .contact-section-large {
    display: flex !important;
  }

  .contact-section-collapse {
    display: none !important;
  }
}




@media only screen and (max-width:991px) {
  .contact-section-mobile {
    display: flex !important;
  }

  .contact-section-large {
    display: none !important;
  }

  .contact-section-collapse {
    display: block !important;
  }
}



@media only screen and (max-width:480px) {
  .myBooking{
    display: none !important;
  }
}
</style>
