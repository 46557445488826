<template>
  <v-container :tabindex="focusTop" style="max-width: 1300px !important">
    <v-form v-model="valid" ref="form" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-md-12">
          <v-timeline direction="horizontal" line-inset="12">
            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-magnify</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-airplane</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-account</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="primary">
              <template v-slot:icon>
                <v-icon color="white" size="20">mdi-cash-multiple</v-icon>
              </template>
            </v-timeline-item>
          </v-timeline>
        </div>

        <div class="col-lg-9 col-md-9 col-sm-12 mx-0 px-0">
          <div class="ps-sm-4 ps-0 pe-0">
            <div class="fare-show">
              <v-card rounded="2" max-width="400px" max-height="500px" class="pa-1">
                <div class="d-flex justify-content-between m-1">
                  <div>
                    <p class="m-0" style="font-size: 26px; font-weight: 500">
                      Total Price
                    </p>
                  </div>
                  <div>
                    <p style="font-size: 26px; font-weight: 500">
                      {{ currency }} {{ totalAmountpassenger }}
                    </p>
                  </div>
                </div>

                <div class="row mt-2 px-1">
                  <div class="col-md-12">
                    <div class="row justify-content-between">
                      <div class="col-6">
                        <span style="font-size: 14px; font-weight: 500">Passengers</span>
                      </div>
                      <!-- <div class="col-md-4"></div> -->
                      <div class="col-6 text-end" style="font-size: 14px">
                        <span v-if="adult > 0">{{ adult }} x Adult</span><br />
                        <span v-if="child > 0">{{ child }} x Child</span><br />
                        <span v-if="inf > 0">{{ inf }} x Lap Infant</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3 mx-1">
                  <hr class="mt-1 mb-2" />
                  <div class="d-flex align-end mb-2" style="cursor: pointer">
                    <span style="padding-right: 5px" @click="isIcon = !isIcon">
                      <span style="color:0D6EFD; text-decoration:underline;">Fare Summary</span></span>

                    <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                      aria-expanded="true" aria-controls="collapse-Example" v-model="isIcon"
                      style="color: 0D6EFD; overflow-anchor: none">{{
                        isIcon
                        ? "mdi-chevron-up-box-outline"
                        : "mdi-chevron-down-box-outline"
                      }}</v-icon>
                  </div>

                  <hr class="mt-1 mb-2" />



<div >
  <div style="font-size:16px;
    font-weight:500;
    ;">
 Passengers 
  </div>

  <div class="mt-2" v-for="(data,index ) of totalPassData" :key="index">
 <div style="text-transform:uppercase;font-size: 12px;
    font-weight: 500;"> 
  {{ data.salutation }}   {{ data.firstname }}  {{ data.middlename }}  {{ data.lastname }} 
  </div> 
  </div>
</div>        


                  <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example">
                    <div class="row">
                      <div class="col-6">
                        Base Price<br />
                        Taxes & Fees
                      </div>
                      <div class="col-6" align="center">
                        {{ currency }} {{ totalbasePassanger }}<br /> {{ currency }} {{ totalTaxPassanger }}
                      </div>
                    </div>
                    <!-- <br>
                  <p style="font-size: 12px">
                    All fares are quoted in USD. Some airlines may charge baggage fees.
                    Your credit/debit card may be billed in multiple charges totaling
                    the final total price.
                  </p> -->
                  </div>

                  <br />
                  <p style="font-size: 12px">
                    All fares are quoted in USD. Some airlines may charge bag
                    gage fees. Your credit/debit card may be billed in multiple
                    charges totaling the final total price.
                  </p>
                </div>
              </v-card>
            </div>

            <div class="mt-3 p-0" v-for="(res, index) of rountresult" :key="index">
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="pt-1 ps-3">Trip Details</h4>
                    </div>
                  </div>
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-4 mb-2">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    {{ getlogo(res.Depature) }}
                                    <span v-for="(result, index) of logo" :key="index">
                                      <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Depature)
                                      }}</span>
                                      <br />
                                      <span v-for="(result, index) of res.Depature" :key="result">
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
  result.MarketingCarrier.FlightNumber
}}
                                        </span>

                                        <span class="f-size-9" v-if="index < res.Depature.length - 1">
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Depature)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getTime(res.Depature) }}</span>
                                  </div>
                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Depature)
                                    }}</span>
                                    <span class="f-size-16">
                                      ({{ fromname }})</span>
                                    <span class="f-size-13">,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span>
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Depature)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getArrivalTime(res.Depature)
                                    }}</span>
                                  </div>

                                  <div class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{ getArrivalCode(res.Depature) }}
                                    </span>
                                    <span class="f-size-16">
                                      ({{ toname }})</span>
                                    <span class="f-size-13">,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Depature) }} Stops
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span class="f-size-9" v-for="(data, index) of res.Depature" :key="index">
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div class="col-md-2 col-4 d-flex justify-md-center">
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Depaturejourney.Time
                                  }}</span><br />
                                  <span class="f-size-12" style="line-height: 24px">(Flight
                                    {{ depfligtTime(res.Depature) }})</span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                        <br />

                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-4 mb-2">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    {{ getlogo1(res.Return) }}
                                    <span v-for="(result, index) of logo1" :key="index">
                                      <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Return)
                                      }}</span>
                                      <br />
                                      <span v-for="(result, index) of res.Return" :key="result">
                                        <span class="f-size-9 air-id fw-normal">{{
                                          result.MarketingCarrier.AirlineID
                                        }}-{{
  result.MarketingCarrier.FlightNumber
}}</span>
                                        <span class="f-size-9" v-if="index < res.Return.length - 1">
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getreturnDate(res.Return)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getreturnTime(res.Return)
                                    }}</span>
                                  </div>
                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">
                                      {{
                                        getreturnAirportCode(res.Return)
                                      }}</span>
                                    <span class="f-size-16">
                                      ({{ toname }})</span>
                                    <span class="f-size-13">,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span>
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getreturnArrivaldate(res.Return)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{
                                      getreturnArrivalTime(res.Return)
                                    }}</span>
                                  </div>

                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">
                                      {{ getreturnArrivalcode(res.Return) }}
                                    </span>
                                    <span class="f-size-16">
                                      ({{ fromname }})</span>
                                    <span class="f-size-13">,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getReturnStops(res.Return) }} Stops
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span class="f-size-9" v-for="(data, index) of res.Return" :key="index">
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div class="col-md-2 col-4 d-flex justify-md-center">
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Returnjourney.Time
                                  }}</span><br />
                                  <span class="f-size-12" style="line-height: 24px">(Flight
                                    {{ retfligtTime(res.Return) }})</span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>






            <div class="mt-3 p-0" v-for="(res, index) of onewayresult" :key="index">
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="pt-1 ps-3">Trip Details</h4>
                    </div>
                  </div>
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-4 mb-2">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    {{ getlogo(res.Depature) }}
                                    <span v-for="(result, index) of logo" :key="index">
                                      <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Depature)
                                      }}</span>
                                      <br />
                                      <span v-for="(result, index) of res.Depature" :key="result">
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
  result.MarketingCarrier.FlightNumber
}}
                                        </span>

                                        <span class="f-size-9" v-if="index < res.Depature.length - 1">
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Depature)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getTime(res.Depature) }}</span>
                                  </div>
                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Depature)
                                    }}</span>
                                    <span class="f-size-16">
                                      ({{ fromname }})</span>
                                    <span class="f-size-13">,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span>
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Depature)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getArrivalTime(res.Depature)
                                    }}</span>
                                  </div>

                                  <div class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{ getArrivalCode(res.Depature) }}
                                    </span>
                                    <span class="f-size-16">
                                      ({{ toname }})</span>
                                    <span class="f-size-13">,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Depature) }} Stops
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span class="f-size-9" v-for="(data, index) of res.Depature" :key="index">
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div class="col-md-2 col-4 d-flex justify-md-center">
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Depaturejourney.Time
                                  }}</span><br />
                                  <span class="f-size-12" style="line-height: 24px">(Flight
                                    {{ depfligtTime(res.Depature) }})</span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>



            <div class="mt-3 p-0" v-for="(res, index) of multiresult" :key="index">
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="pt-1 ps-3">Trip Details</h4>
                    </div>
                  </div>
                  <div class="row m-1">
                    <div class="col-lg-12">


                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-4 mb-2">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    {{ getlogo(res.Flight1) }}
                                    <span v-for="(result, index) of logo" :key="index">
                                      <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight1)
                                      }}</span>
                                      <br />
                                      <span v-for="(result, index) of res.Flight1" :key="result">
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
  result.MarketingCarrier.FlightNumber
}}
                                        </span>

                                        <span class="f-size-9" v-if="index < res.Flight1.length - 1">
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight1)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getTime(res.Flight1) }}</span>
                                  </div>
                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight1)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getDepAirportName(res.Flight1)
                                      }}</span>
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight1)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getArrivalTime(res.Flight1)
                                    }}</span>
                                  </div>

                                  <div class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{ getArrivalCode(res.Flight1) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getArrAirportName(res.Flight1)
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight1) }} Stops
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span class="f-size-9" v-for="(data, index) of res.Flight1" :key="index">
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div class="col-md-2 col-4 d-flex justify-md-center">
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight1JourneyTime.Time
                                  }}</span><br />
                                  <span class="f-size-12" style="line-height: 24px">(Flight
                                    {{ depfligtTime(res.Flight1) }})</span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                      </div>


                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-4 mb-2">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    {{ getlogo(res.Flight2) }}
                                    <span v-for="(result, index) of logo" :key="index">
                                      <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight2)
                                      }}</span>
                                      <br />
                                      <span v-for="(result, index) of res.Flight2" :key="result">
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
  result.MarketingCarrier.FlightNumber
}}
                                        </span>

                                        <span class="f-size-9" v-if="index < res.Flight2.length - 1">
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight2)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getTime(res.Flight2) }}</span>
                                  </div>
                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight2)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getDepAirportName(res.Flight2)
                                      }}</span>
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight2)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">
                                      {{ getArrivalTime(res.Flight2)
                                      }}</span>
                                  </div>

                                  <div class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{ getArrivalCode(res.Flight2) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getArrAirportName(res.Flight2)
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight2) }} Stops
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span class="f-size-9" v-for="(data, index) of res.Flight2" :key="index">
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div class="col-md-2 col-4 d-flex justify-md-center">
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight2JourneyTime.Time
                                  }}</span><br />
                                  <span class="f-size-12" style="line-height: 24px">(Flight
                                    {{ depfligtTime(res.Flight2) }})</span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                      </div>



                      <div class="mt-2" v-if="res.Flight3 ? true : false">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-4 mb-2">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    {{ getlogo(res.Flight3) }}
                                    <span v-for="(result, index) of logo" :key="index">
                                      <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight3)
                                      }}</span>
                                      <br />
                                      <span v-for="(result, index) of res.Flight3" :key="result">
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
  result.MarketingCarrier.FlightNumber
}}
                                        </span>

                                        <span class="f-size-9" v-if="index < res.Flight3.length - 1">
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight3)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getTime(res.Flight3) }}</span>
                                  </div>
                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight3)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getDepAirportName(res.Flight3)
                                      }}</span>
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight3)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">
                                      {{ getArrivalTime(res.Flight3)
                                      }}</span>
                                  </div>

                                  <div class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{ getArrivalCode(res.Flight3) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getArrAirportName(res.Flight3)
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight3) }} Stops
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span class="f-size-9" v-for="(data, index) of res.Flight3" :key="index">
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div class="col-md-2 col-4 d-flex justify-md-center">
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight3JourneyTime.Time
                                  }}</span><br />
                                  <span class="f-size-12" style="line-height: 24px">(Flight
                                    {{ depfligtTime(res.Flight3) }})</span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                      </div>



                      <div class="mt-2" v-if="res.Flight4 ? true : false">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-4 mb-2">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    {{ getlogo(res.Flight4) }}
                                    <span v-for="(result, index) of logo" :key="index">
                                      <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight4)
                                      }}</span>
                                      <br />
                                      <span v-for="(result, index) of res.Flight4" :key="result">
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
  result.MarketingCarrier.FlightNumber
}}
                                        </span>

                                        <span class="f-size-9" v-if="index < res.Flight4.length - 1">
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight4)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">{{ getTime(res.Flight4) }}</span>
                                  </div>
                                  <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight4)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getDepAirportName(res.Flight4)
                                      }}</span>
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight4)
                                    }}</span>
                                    <span class="f-size-16" style="margin-left: 5px">
                                      {{ getArrivalTime(res.Flight4)
                                      }}</span>
                                  </div>

                                  <div class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px">
                                    <span class="f-size-16 fw-500">{{ getArrivalCode(res.Flight4) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13">,
                                      {{
                                        getArrAirportName(res.Flight4)
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight4) }} Stops
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span class="f-size-9" v-for="(data, index) of res.Flight4" :key="index">
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div class="col-md-2 col-4 d-flex justify-md-center">
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight4JourneyTime.Time
                                  }}</span><br />
                                  <span class="f-size-12" style="line-height: 24px">(Flight
                                    {{ depfligtTime(res.Flight4) }})</span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                      </div>







                    </div>
                  </div>
                </v-card>
              </div>
            </div>














            <div>
              <div class="mt-3">
                <v-card rounded="1" style="font-size: 14px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="ps-3" style="padding-top: 20px">
                        Payment Details
                      </h4>
                    </div>
                  </div>

                  <div class="p-4">
                    <div class="row">
                      <div class="col-md-4 col-sm-6">
                        <!-- <p class="mb-1">Card Number <span class="text-danger">*</span></p> -->
                        <v-text-field :rules="cardNumberValid" v-model="billingDetails.cardnumber" @paste.prevent
                          @keypress="filter(event)" maxlength="16" label="Card Number" placeholder="xxxx-xxxx-xxxx-xxxx"
                          variant="outlined" tabindex="1"></v-text-field>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <!-- <p class="mb-1">Name on the card <span class="text-danger">*</span></p> -->
                        <v-text-field :rules="cardNameValid" type="text" label="Name on the card"
                          v-model="billingDetails.cardholdername" variant="outlined" tabindex="2"></v-text-field>
                      </div>
                      <div class="col-md-3 col-sm-4 col-8">
                        <!-- <p class="mb-1">Expire Date <span class="text-danger">*</span></p> -->
                        <v-text-field v-model="billingDetails.ExpireDate" :rules="expDate" @keydown="handleInput" @paste.prevent
                          label="Expiry (MM / YY)" @input="formatExpireDate" variant="outlined" @keydown.tab="focusNext"
                          tabindex="3"></v-text-field>
                      </div>
                      <div class="col-md-2 col-sm-3 col-4">
                        <!-- <p class="mb-1">CVV <span class="text-danger">*</span></p> -->
                        <v-text-field :rules="[(v) => !!v || 'cvv is required']" label="cvv" @keypress="filter(event)"
                          v-model="billingDetails.cvvnumber" maxlength="3" variant="outlined" name="cvv" @paste.prevent 
                          @keydown.tab="focusNext" tabindex="4"></v-text-field>
                      </div>
                    </div>

                    <div>
                      <div class="d-flex flex-sm-row flex-column mt-2">
                        <div>We accept all major Credit Card :</div>
                        <div class="d-flex ps-2">
                          <v-img :src="require('../assets/american_express.png')" width="65px" />
                          <v-img :src="require('../assets/master_card.png')" width="42px" />
                          <v-img :src="require('../assets/visa_card.png')" width="65px" />
                          <v-img :src="require('../assets/jcb.png')" width="42px" />
                          <v-img :src="require('../assets/discover.png')" width="46px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div class="mt-3">
                <v-card style="font-size: 14px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="ps-3" style="padding-top: 20px">
                        Billing Details
                      </h4>
                    </div>
                  </div>

                  <div class="p-4">
                    <div class="row ps-2">
                      <div class="col-md-4">
                        <!-- <p class="mb-2">Full Name<span class="text-danger"> *</span></p> -->
                        <v-text-field v-model="billingDetails.name" label="Full Name" :rules="[
                          (v) => !!v || 'Full Name is required',
                          (v) =>
                            /^[a-zA-Z -]+$/.test(v) ||
                            'Only alphabets, hyphens, and spaces are allowed',
                        ]" variant="outlined"></v-text-field>
                      </div>

                      <div class="col-md-4">
                        <!-- <p class="mb-2">Country<span class="text-danger"> *</span></p> -->
                        <v-autocomplete :items="countriesData" item-title="name" variant="outlined"
                          :rules="[(v) => !!v || 'Country is required']" label="Country" v-model="billingDetails.country"
                          class="country-field">
                        </v-autocomplete>
                      </div>

                      <div class="col-md-4">
                        <v-autocomplete :items="stateData" item-title="name" variant="outlined"
                          :rules="[(v) => !!v || 'Place is required']" label="Region/State/Province"
                          v-model="billingDetails.state" class="country-field">
                        </v-autocomplete>

                      </div>
                    </div>

                    <div class="row ps-2">
                      <div class="col-md-4">
                        <!-- <p class="mb-2">Address Line 2 (Optional)</p> -->

                        <v-text-field :rules="[(v) => !!v || 'Address is required']" v-model="billingDetails.address1"
                          label=" Address Line 1" variant="outlined"></v-text-field>

                      </div>

                      <div class="col-md-4">
                        <!-- <p class="mb-2">Region/State/Province<span class="text-danger"> *</span></p> -->
                        <v-text-field label=" Address Line 2" variant="outlined"
                          v-model="billingDetails.address2"></v-text-field>


                        <!-- <v-select class="v-select" label="State" :items="['America', 'Canada']"
                                                      v-model="billingDetails.state"
                                                      :rules="[v => !!v || 'This field is required']"
                                                      variant="outlined"></v-select> -->
                      </div>

                      <div class="col-md-4">
                        <!-- <p class="mb-2">City<span class="text-danger"> *</span></p> -->
                        <v-text-field :rules="[(v) => !!v || 'City is required']" v-model="billingDetails.city"
                          label=" City" variant="outlined"></v-text-field>
                      </div>
                    </div>

                    <div class="row ps-2">
                      <div class="col-md-4">
                        <!-- <p class="mb-2">Zip Code<span class="text-danger"> *</span></p> -->
                        <v-text-field v-model="billingDetails.zipcode" label="Postal Code"
                          :rules="[(v) => !!v || 'This field is required']" variant="outlined"></v-text-field>
                      </div>

                      <div class="col-md-4">
                        <v-autocomplete :items="countrycode" item-title="name" variant="outlined" width="auto"
                          :rules="[(v) => !!v || 'Country Code is required']" label="Country Code"
                          v-model="billingDetails.coutyCode">
                        </v-autocomplete>
                      </div>

                      <div class="col-md-4">
                        <v-text-field label="Phone Number" variant="outlined" v-model="billingDetails.phonenumber"
                          @country-changed="handleCountryChanged" @input="handleNumericInput"
                          :rules="[(v) => !!v || 'Phone Number is required']"></v-text-field>
                      </div>
                    </div>


                    <div class="row ps-2 mt-2">

                      <div class="col-md-4">
                        <v-text-field label=" Email" :rules="emailRequire" variant="outlined"
                          v-model="billingDetails.email"></v-text-field>
                      </div>



                    </div>

                    <div class="row ps-2">
                      <div class="col-md-4">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-end">
                            <label class="mb-1 d-flex justify-center align-center" style="font-size: 13px">Alternate
                              Contact</label>
                          </div>
                          <v-radio-group class="alternate-Details" inline v-model="radioBtn">
                            <div class="d-flex align-items-end">
                              <v-radio label="Phone" value="1" @click="
                                (altContact = true), (mailAddress = false)
                                "></v-radio>
                              <v-radio label="Email" value="2" @click="
                                (mailAddress = true), (altContact = false)
                                "></v-radio>
                            </div>
                          </v-radio-group>
                        </div>

                        <!-- <vue-tel-input v-model="alterPhone" @input="handleNumericInput1"
                        
                        v-if="altContact"  @country-changed="handleCountryChanged"></vue-tel-input> -->

                        <v-text-field v-model="billingDetails.email1" type="email" label="E-mail" variant="outlined"
                          required v-if="mailAddress"></v-text-field>
                      </div>
                    </div>



                    <div class="row ps-2 mt-2" v-if="altContact">


                      <div class=col-md-4>
                        <v-autocomplete :items="countrycode" item-title="name" variant="outlined" width="auto"
                          label="Country Code" v-model="billingDetails.coutyCode1">
                        </v-autocomplete>
                      </div>



                      <div class=col-md-4>
                        <v-text-field label="Phone Number" variant="outlined" v-model="billingDetails.phonenumber1"
                          @country-changed="handleCountryChanged" @input="handleNumericInput"></v-text-field>
                      </div>

                    </div>

                  </div>
                </v-card>

              </div>

              <div class="mt-5 mb-5">
                <p class="text-center font-weight terms-text">
                  By clicking 'Confirm & Book', I agree that I have read and
                  accepted the <span class="text-primary">Terms of use</span>.
                </p>
                <div class="d-flex justify-center continue-section">
                  <v-btn class="continue-btn text-capitalize" type="submit" color="#5191fa" size="large">Confirm To
                    Book</v-btn>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3" style="margin-bottom: 146px">
          <div class="fare-summery" :style="{ top: topSticky }">
            <v-card max-width="450px" max-height="500px" class="pa-2 m-auto" style="border: 1px solid grey">
              <h5>Fare Details</h5>
              <hr />
              <div class="d-flex justify-content-between m-1">
                <div>
                  <p class="m-0" style="font-size: 16px; font-weight: 500">
                    Total Price
                  </p>
                </div>
                <div>
                  <p class="tot-price m-0">
                    {{ currency }} {{ totalAmountpassenger }}
                  </p>
                </div>
              </div>

              <div class="row px-1">
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="col-md-6">
                      <span style="font-size: 14px; font-weight: 500">Passengers</span>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="col-md-6 text-end" style="font-size: 14px">
                      <span v-if="adult > 0">{{ adult }} x Adult</span><br />
                      <span v-if="child > 0">{{ child }} x Child</span><br />
                      <span v-if="inf > 0">{{ inf }} x Lap Infant</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-1 mx-1">
                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2" style="cursor: pointer">
                  <span style="padding-right: 5px" @click="isIcon = !isIcon">
                    <span style="color:0D6EFD; text-decoration:underline;">Fare Summary</span>
                  </span>

                  <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                    aria-expanded="true" aria-controls="collapse-Example" v-model="isIcon"
                    style="color: 0D6EFD; overflow-anchor: none">{{
                      isIcon
                      ? "mdi-chevron-up-box-outline"
                      : "mdi-chevron-down-box-outline"
                    }}</v-icon>
                </div>

                <hr class="mt-1 mb-2" />


<div >
  <div style="font-size:16px;
    font-weight:500;
    ;">
 Passengers 
  </div>

  <div class="mt-2" v-for="(data,index ) of totalPassData" :key="index">
 <div style="text-transform:uppercase;font-size: 12px;
    font-weight: 500;"> 
  {{ data.salutation }}   {{ data.firstname }}  {{ data.middlename }}  {{ data.lastname }} 
  </div> 
  </div>
</div>






                <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example">
                  <div class="row">
                    <div class="col-6">
                      Base Price<br />
                      Taxes & Fees
                    </div>
                    <div class="col-6" align="center">
                      {{ currency }} {{ totalbasePassanger }}<br /> {{ currency }} {{ totalTaxPassanger }}
                    </div>
                  </div>
                </div>

                <br />
                <p style="font-size: 12px">
                  All fares are quoted in USD. Some airlines may charge baggage
                  fees. Your credit/debit card may be billed in multiple charges
                  totaling the final total price.
                </p>
              </div>

              <p class="font-weight f-size-11">
                By clicking 'Confirm & Book', I agree that I have read and
                accepted the <span class="text-primary">Terms of use</span>.
              </p>
            </v-card>

            <div class="mt-2 mb-2 continue-section" align="center">
              <v-btn height="40px" class="continue-btn-1" type="submit" style="
                    font-size: 16px;
                    font-weight: 500;
                    color: white;
                    text-transform: capitalize;
                    background-color: #ff6b0a;
                  ">
                Continue To Book</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </v-container>

  <!-- <div class="text-center">
    <v-btn
      append-icon="mdi-open-in-new"
      color="deep-purple-accent-4"
      @click="overlay = !overlay"
    >
      Launch Application
    </v-btn>

    <v-overlay
      :model-value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div> -->
  <div class="text-center" v-if="overlayfal">
    <dataloader></dataloader>
  </div>

  <v-dialog v-model="dialog3">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>
          Your request cannot be processed at this moment.

        </h5>
        <h6>Please contact customer support.</h6>
               <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="dialog3=false"  color="#ff6b0a">Try Again</v-btn>

          <v-btn to="/" color="#ff6b0a">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>
  
<script>
import moment from "moment";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import countries from "@/countries.json";
import state from "@/statas.json";
import { ref } from "vue";
import axios from "axios";
import dataloader from "@/components/dataLoder.vue"
import country1 from "@/coundtrycode.json";


import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';



const month = ref({
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
});

export default {

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: {
    dataloader
  },
  data() {
    return {
      overlayfal: false,
      strResult:"123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      dialog3: false,
      portal: false,
      countrycode: [],
      booking_Id: "",
      booking_refId: "",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "26%",
      countriesData: [],
      stateData: [],
      datenumber: false,
      alterPhone: "",
      totalAmountpassenger: [],
      totalTaxPassanger: [],
      totalbasePassanger: [],
      det: {
        name: "",
        email: "",
      },
      multiresult: [],
      paxcontact: [],
      adult: "",
      child: "",
      inf: "",
      from: "",
      to: "",
      fromdate: "",
      todate: "",
      fromname: "",
      toname: "",
      fromcode: "",
      tocode: "",
      basePrice: "",
      taxprice: "",
      city: "",
      month,
      isIcon: false,
      passDeatail: [],
     
      adultDetailData: [],
      childDetailData: [],
      infDetailData: [],
      totalPassData:[],
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infantLength: "",
      classed: "",
      // flow,
      billingDetails: {
        cardnumber: "",
        cardholdername: "",
        ExpireDate: "",
        cvvnumber: "",
        logo: [],
        logo1: [],
        name: "",
        country: null,
        address1: "",
        address2: "",
        state: null,
        city: "",
        zipcode: "",
        phonenumber: "",
        email: "",
        phonenumber1: "",
        coutyCode: null,
        coutyCode1: null,
        email1: "",
      },
      depAirId: [],
      countryCode: "",
      retAirId: [],
      depAirLogo: [],
      retAirLogo: [],
      rountresult: [],
      totalmsg: [],
      bookinginiId: "",
      onewayresult: [],
      roundresult: [],
      currency: "",
      valid: false,
      altContact: true,
      mailAddress: false,
      local_id: "",
      Localoneway_id: "",
      // formatDate: "Month,year",
      emailId: "john@gmail.com",
      phone: "",

      // expDate: [
      //     v => !!v || 'This field is required',
      //     v => /^(0[1-9]|1[0-2])\/\d{2}$/.test(v) || 'Invalid format (MM / YY)',
      //     v => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
      // ],

      expDate: [
        (v) => !!v || "Expiry Date is required",
        (v) => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
        (v) => {
          const [month] = v.split("/");
          const isInvalidMonth = parseInt(month) > 12;
          const isInvalidFormat = !v.match(/^(0[1-9]|1[0-2])\/\d{2}$/);

          if (isInvalidMonth) return "Invalid month";
          if (isInvalidFormat) return "Invalid format (MM / YY)";
          return true;
        },
      ],

      cardNumberValid: [
        (v) => !!v || 'Card Number is required',
        (v) => /^\d+$/.test(v) || 'Only numeric characters are allowed',
        // (v) => v.length === 16 || 'Card number must be 16 digits long'
      ],

      emailRequire: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],

      cardNameValid: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      radioBtn: "1",
      todayDate: "",
      fullName: "",
      selectedCountries: [],
      addressLine: "",

      portalbookingUrl: "",


      userBooking: {
        "booking_ref_id": "",
        "portal_id": "port6789",
        "offer_id": "",
        "pointofsale": "",
        "trip_type": "",
        "hit_id": "",
        "shopping_response_id": "",
        "adult": 1,
        "child": 0,
        "paxInfant": 0,
        "ip_address": "127.0.0.1",
        "search_cabin_type": "",
        "pax_info": [],
        "pax_contact": {
          "countrycode": "",
          "pax_phone": "096002 80890",
          "pax_email": "irwogms@gmail.com"
        },
        "bill_info": {
          "bill_fullname": "",
          "bill_Country": "",
          "bill_state": "",
          "bill_address1": "",
          "bill_address2": "",
          "bill_city": "",
          "bill_zipcode": "",
          "bill_email": "",
          "bill_phone_country_code": "",
          "bill_phone": "0655656569",
          "bill_alternate_contactno": null,
          "bill_alternate_email": null
        },
        "card_info": {
          "card_no": "",
          "card_name": "",
          "card_expiry": "",
          "card_cvc": ""
        }
      },

      getCountryname: '',
      focusTop : 0,




    };
  },
  methods: {

    filter: function (evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('v-text-field, input, select, textarea, button'));
      const index = inputs.indexOf(e.target);

      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
        e.preventDefault();
      }
    },


    handleNumericInput() {
      this.billingDetails.phonenumber = this.billingDetails.phonenumber.replace(/\D/g, '');
    },
    handleNumericInput1() {
      this.billingDetails.alterPhone = this.billingDetails.alterPhone.replace(/\D/g, '');
    },
    handleCountryChanged(countryCode) {
      this.countryCode = '+' + countryCode.dialCode
      console.log('Selected Country Code:', this.countryCode);
    },
    formatExpireDate(event) {
      if (
        this.billingDetails.ExpireDate.length <= 2 &&
        event.inputType === "deleteContentBackward"
      ) {
        this.billingDetails.ExpireDate = this.billingDetails.ExpireDate.slice(
          0,
          3
        );
      } else if (this.billingDetails.ExpireDate.length === 2) {
        this.billingDetails.ExpireDate += "/";
      }
    },

    handleInput(e) {
      if (this.billingDetails.ExpireDate.length === 5 && e.keyCode !== 8) {
        e.preventDefault();
      }
    },

    // isMMYYFormat(value) {
    //     const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    //     return regex.test(value);
    // },


    retfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        // const currentIndex = i;
        // const nextIndex = (i + 1) % data.length;

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
          .toString()
          .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },

    depfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      // console.log(layoverTime, 'layoverTimelayoverTimelayoverTimelayoverTime')
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
          .toString()
          .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },

    getlogo(data) {
      // console.log(data,'ppppppppppppp')
      this.logo = [];
      for (let i = 0; i < data.length; i++) {
        if (!this.logo.includes(data[i].MarketingCarrier.logo)) {
          this.logo.push(data[i].MarketingCarrier.logo);
          break;
        }
      }
      // console.log(this.logo,'logologologologo')
    },
    getlogo1(data) {
      // console.log(data,'ppppppppppppp')
      this.logo1 = [];
      for (let i = 0; i < data.length; i++) {
        if (!this.logo1.includes(data[i].MarketingCarrier.logo)) {
          this.logo1.push(data[i].MarketingCarrier.logo);
          break;
        }
      }
      // console.log(this.logo,'logologologologo')
    },
    getData() {
      let localData = JSON.parse(localStorage.getItem("rountData"));
      this.fromdate = moment(localData.dedate).format("ddd, MMM D, YYYY");
      this.todate = moment(localData.redate).format("ddd, MMM D, YYYY");
      this.city = localData.city;

      if (localData.from) {
        this.from = localData.from.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname = v;
            console.log(v, this.fromname, "tdtdtddtdttdd......");
          }
        });
        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to) {
        this.to = localData.to.split(" ");
        this.to.forEach((v, i) => {
          if (i !== this.to.length - 1) {
            this.toname = v;
            console.log(v, this.toname, "ttsdtsdyusdsdsjd.....");
          }
        });

        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      // ------------->Check this Function<--------------
      if (localData.from.name) {
        this.from = localData.from.name.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname += v;
          }
        });

        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to.name) {
        this.to = localData.to.name.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      } else {
        this.to = localData.to.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }
      // -------------><--------------

      // console.log(this.fromcode, " this.from");
      // console.log(this.tocode, "  this.to");
      // console.log(localData, "localData");
    },

    getadult() {

      this.getData()

      this.rountresult.forEach((v) => {
        console.log(v,'ddsdjvsdb')
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice
        this.totalbasePassanger = v.Baseprice
        this.totalAmountpassenger = v.TotalPrice
          this.adult =v.Adult;
      this.child =v.Child;
      this.inf =v.Inf;

      });
    },

    onewayData() {

      this.getData()
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice
        this.totalbasePassanger = v.Baseprice
        this.totalAmountpassenger = v.TotalPrice
        this.adult =v.Adult;
      this.child =v.Child;
      this.inf =v.Inf;
      });
    },

    getprice1() {
      let priceData = [];
      let rount = JSON.parse(localStorage.getItem("itineryData"));
      let oneway = JSON.parse(localStorage.getItem("onewayItineyData"));
      if (rount) {
        priceData = rount;
      }
      if (oneway) {
        priceData = oneway;
      }
      this.basePrice = priceData.price;
      this.taxPrice = priceData.Taxprice;
      // console.log(priceData, "priceData");
    },

    getprice(res) {
      return (res.price + res.Taxprice).toFixed(2);
    },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },

    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },

    getDate($event) {
      return moment($event[0].Departure.Date).format("MMM DD");
    },
    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getDepAirportName($event) {
      return $event[0].Departure.AirportName;
    },

    getReturnDepAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getArrAirportName($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    getReturnArrAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getDepStops($event) {
      return $event.length - 1;
    },

    getReturnStops($event) {
      return $event.length - 1;
    },

    // getDep1($event) {
    //     return $event[0].Departure.AirportCode;
    // },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event,index,'$event')

      return $event[$event.length - 1].Arrival.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getreturnDate($event) {
      return moment($event[0].Departure.Date).format("MMM DD");
    },
    getdeparDate($event) {
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdated($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getreturnArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },
    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "26%";
      }

      this.lastPosition = window.scrollY;
    },



    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(3);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(3);

      this.booking_refId = randomChars1 + randomNum + randomChars2;
      console.log(this.booking_refId, 'this.booking_refId ')
    },

    generateBookingRefId1() {
      const randomChars1 = this.getRandomChars(6);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(6);

      this.booking_Id = randomChars1 + randomNum + randomChars2;
      console.log(this.booking_Id, 'this.booking_Id')
    },

    multiData() {
      let res = this.local_id.split("-")
      let res1 = res[res.length - 1]
      let data_pass = []
      data_pass = JSON.parse(localStorage.getItem(`${"pass"}-${res1}`));

      this.adultLength = data_pass.adult;
      this.childLength = data_pass.child;
      this.infLength = data_pass.inf;
      this.adult = data_pass.adult;
      this.child = data_pass.child;
      this.inf = data_pass.inf;
      console.log(data_pass, 'data_pass')

      let totalpass = []
      totalpass = this.adultLength + this.childLength + this.infLength
      console.log(totalpass, 'totalpass')




      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice
        this.totalbasePassanger = v.Baseprice
        this.totalAmountpassenger = v.TotalPrice
      });


    },
    passengerDetail() {
this.passDeatail = JSON.parse(localStorage.getItem("TravelerDetails"));
      console.log(this.adult, 'this.adult this.adult ')
  

       
      for (let i = 0; i < this.passDeatail.adultDatas.length; i++) {
          if (i<this.adult) {
             this.adultDetailData.push({
          lastname: "",
          firstname: "",
          middlename: null,
          salutation: "",
          gender: "",
          dob: "",
          pax_type: "Adult",
        });
          }
       
      }
      
     
      this.adultDetailData.forEach((a, i) => {
        this.passDeatail.adultDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
          }
        });
      });

  // console.log(this.adultDetailData,'this.adultDetailDatathis.adultDetailData')
     
      
      
        for (let i = 0; i < this.passDeatail.childDatas.length; i++) {
          if (i < this.child) {
        this.childDetailData.push({
          lastname: "",
          firstname: "",
          middlename: null,
          salutation: "",
          gender: "",
          dob: "",
          pax_type: "Child",
        });
    }
      
      }


   
       this.childDetailData.forEach((a, i) => {
        this.passDeatail.childDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
          }
        });
      });

  

     

      
      for (let i = 0; i < this.passDeatail.infrantDatas.length; i++) {
        if (i < this.inf) {

          this.infDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Infant",
          });
        }
      }


     
      this.infDetailData.forEach((a, i) => {
        this.passDeatail.infrantDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
          }
        });
      });

      this.totalPassData = [...this.adultDetailData, ...this.childDetailData, ...this.infDetailData]
  

console.log(this.totalPassData,'this.totalPassDatathis.totalPassData')

},

    userbook() {

    let Travelcontact=JSON.parse(localStorage.getItem("Travelcontact"))
console.log(Travelcontact,'Travelcontact')
      if (this.booking_refId && this.booking_Id) {
        this.userBooking.booking_ref_id = this.booking_refId;
        this.userBooking.hit_id = `${"us"}-${this.booking_Id}`;

      }
      this.userBooking.whatsapp_opted=Travelcontact.contactCheck
      this.userBooking.newsletter_subscribe=Travelcontact.couponsCheck
      this.userBooking.adult = this.adult;
      this.userBooking.child = this.child;
      this.userBooking.paxInfant = this.inf;

      this.userBooking.pax_contact.pax_phone = this.paxcontact[0].number
      this.userBooking.pax_contact.pax_email = this.paxcontact[0].email;
      let data = this.paxcontact[0].code;
      let countryCode = data.split("(").pop().replace(")", "")
      this.userBooking.pax_contact.countrycode = countryCode;
      this.userBooking.card_info.card_name = this.billingDetails.cardholdername;
      this.userBooking.card_info.card_no = this.billingDetails.cardnumber;
      this.userBooking.card_info.card_cvc = this.billingDetails.cvvnumber;
      this.userBooking.card_info.card_expiry = this.billingDetails.ExpireDate;

      this.userBooking.bill_info.bill_fullname = this.billingDetails.name
      this.userBooking.bill_info.bill_Country = this.billingDetails.country
      this.userBooking.bill_info.bill_state = this.billingDetails.state
      this.userBooking.bill_info.bill_address1 = this.billingDetails.address1
      this.userBooking.bill_info.bill_address2 = this.billingDetails.address2
      this.userBooking.bill_info.bill_city = this.billingDetails.city
      this.userBooking.bill_info.bill_zipcode = this.billingDetails.zipcode
      this.userBooking.bill_info.bill_email = this.billingDetails.email
      this.userBooking.bill_info.bill_phone = this.billingDetails.phonenumber
      this.userBooking.bill_info.bill_alternate_contactno = this.billingDetails.phonenumber1
      this.userBooking.bill_info.bill_alternate_email = this.billingDetails.email1
      let data1 = this.billingDetails.coutyCode;
      let countryCode1 = data1.split("(").pop().replace(")", "")
      this.userBooking.bill_info.bill_phone_country_code = countryCode1


      if (this.rountresult.length > 0) {
        this.userBooking.trip_type = "return"
        this.rountresult.forEach(s => {

          this.userBooking.offer_id = s.selectId
          this.userBooking.shopping_response_id = s.ShoppingResponseId
          this.userBooking.pointofsale = s.currency.split("$")[0]
          this.userBooking.search_cabin_type = s.cabinClass

        });

      }


      if (this.multiresult.length > 0) {
        this.userBooking.trip_type = "multi"
        this.multiresult.forEach(s => {

          this.userBooking.offer_id = s.selectId
          this.userBooking.shopping_response_id = s.ShoppingResponseId
          this.userBooking.pointofsale = s.currency.split("$")[0]
          this.userBooking.search_cabin_type = s.cabinClass

        });

      }







      if (this.onewayresult.length > 0) {
        this.userBooking.trip_type = "oneway"
        this.onewayresult.forEach(s => {
          this.userBooking.offer_id = s.selectId
          this.userBooking.shopping_response_id = s.ShoppingResponseId
          this.userBooking.pointofsale = s.currency.split("$")[0]
          this.userBooking.search_cabin_type = s.cabinClass

        });


      }

      this.adultDetailData.forEach((l) => {
        this.userBooking.pax_info.push(l);
      });

      this.childDetailData.forEach((l) => {
        this.userBooking.pax_info.push(l);
      });

      this.infDetailData.forEach((l) => {
        this.userBooking.pax_info.push(l);
      });
    },


    getConfig() {

      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {

        this.portalbookingUrl = getConfigData1.payload.portal_configuration.API_endpoints.booking

        // console.log(this.makeSearchUrl, 'head...sea..1..')

        // this.getConfigFunc();
      }

    },





    submitForm() {
       let obj={}
      this.generateBookingRefId1()
      this. generateBookingRefId()
      this.userbook()
      console.log(this.userBooking, "this.tocodethis.tocode");
      if (this.billingDetails.phonenumber) {
        this.datenumber = false;
      } else {
        this.datenumber = true;
      }

      if (this.valid && this.billingDetails.phonenumber) {
        this.overlayfal = true
        this.datenumber = false;
        if (this.portal) {
          this.userBooking.source = "env=cert"
        }
        else {
          this.userBooking.source = "portal"
        }

        console.log(this.userBooking, 'this.userBookingthis.userBookingthis.userBooking')


        axios.post(this.portalbookingUrl, this.userBooking, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            console.log(this.userBooking, 'this.userBookingthis.u.......11.1.11.')

  

             obj = {
              booking_Id: response.data.booking_details.booking_id,
              last_name:response.data.booking_details.pax_info[0].lastname
            }
            
            let booking_details = JSON.stringify(obj)
              console.log(obj,booking_details,'pvacatCSAACFAfc')
            if (this.portal) {
              let uniDataId = `${"env"}-${this.local_id}`
              localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData))
           
              this.$router.push({ path: "/bookingView", query: { pass_id: uniDataId, booking_id: booking_details }, })
            }
            else {


              if (this.local_id) {
                setTimeout(() => {
                  this.$router.push({ path: "/bookingView", query: { pass_id: this.local_id, booking_id: booking_details } })
                },2000);
              }
              if (this.Localoneway_id) {
                setTimeout(() => {
                  this.$router.push({ path: "/bookingView", query: { pass_uid: this.Localoneway_id, booking_id: booking_details } })
                },2000);
              }
              // else {
              //   alert("routing error....");
              // }
            }



            console.log(
              response,
              "userBookinguserBookinguserBookinguserBooking"
            );

          })
          .catch((error) => {

            setTimeout(() => {
              this.dialog3 = true
            }, 7000);

            console.log(error, "error mision");

            this.dialog3 = true
          });





      } else {
        // this.datenumber=true
        //   alert(
        //     "Airlines unable to confirm seats. Please try again later or book another itinerary"
        //   );
      }

    },
    countryData() {
      countries.forEach((v) => {
        this.countriesData.push(v);
      });
      // console.log(this.countriesData, 'ppppppppp')
    },
  },

  computed: {
    dotColor() {
      return this.itineraryInfo ? "primary" : "success";
    },
    timeTextcolor() {
      return this.itineraryInfo ? "blue" : "black";
    },
    filteredCardNumber() {
      return this.billingDetails.cardnumber.replace(/\D/g, "");
    },

    //     expDate() {
    //   return [
    //     (v) => !!v || 'This field is required',
    //     (v) => this.isMMYYFormat(v) || 'Invalid format (MM / YY)',
    //   ];
    // },
  },
  watch: {
    "billingDetails.country"(newVal) {

      let result = [];
      this.countriesData.forEach((v) => {
        if (v.name == newVal) {
          result = v.code;
        }
      });
      Object.keys(state).forEach((c) => {
        if (c == result) {
          this.stateData = state[c];
          // console.log(state[c], 'pppppppppppppppppppppppppp');
        }
      });

      this.getCountryname = newVal

      country1.forEach(v => {
        let obj = {
          name: `${v.name}(${v.dial_code})`,
          code: v.code
        }
        this.countrycode.push(obj)

        if (v.name == this.getCountryname) {
          this.countrycode.find(item => {
            this.billingDetails.coutyCode = item.name
            // console.log(this.billingDetails.coutyCode, 'v.namev.namev.namev.name')
          });
        }
      })
    },



    "billingDetails.cvvnumber"(newValue) {
      if (newValue.length > 3) {
        this.billingDetails.cvvnumber = newValue.slice(0, 3);
      }
    },
    "billingDetails.zipcode"(newValue) {
      if (newValue.length > 7) {
        this.billingDetails.zipcode = newValue.slice(0, 7);
      }
    },

    "billingDetails.phonenumber"(newValue) {
      if (newValue.length > 12) {
        this.billingDetails.phonenumber = newValue.slice(0, 12);
      }
    },
    "billingDetails.phonenumber1"(newValue) {
      if (newValue.length > 12) {
        this.billingDetails.phonenumber1 = newValue.slice(0, 12);
      }
    },
    "billingDetails.cardnumber"(newValue) {
      if (newValue.length > 16) {
        this.billingDetails.cardnumber = newValue.slice(0, 16);
      }
    },
  },

  created() {

    this.focusTop = 0;
    console.log(this.focusTop,'this.focusTopthis.focusTopthis.focusTop')

    let $data = localStorage.getItem("delete_Id");

    if ($data) {
      this.Localoneway_id = this.$route.query.pass_uid;
      this.local_id = this.$route.query.pass_id;

      localStorage.removeItem(`${this.Localoneway_id}`);
      localStorage.removeItem(`${this.local_id}`);
    }



    // let $data = localStorage.getItem("loader")
    // if ($data == "true") {
    //   this.$router.push('/')
    // }

    let $paxcontact = localStorage.getItem("passengerContact")
    // console.log($paxcontact, '$paxcontact')
    this.paxcontact = JSON.parse($paxcontact)
    console.log(this.paxcontact[0].number, 'ppppp')

    this.getConfig();
    this.local_id = this.$route.query.pass_id;
    this.Localoneway_id = this.$route.query.pass_uid;
    console.log(this.local_id, 'this.local_id this.local_id ')


    if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(localStorage.getItem(`${this.Localoneway_id}`));

      if (onewaydata_uid) {
        this.onewayresult.push(onewaydata_uid);
        this.onewayData();
        this.itineraryInfo1 = true;
      }
      else {
        this.$router.push('/')
      }
    }



    else if (this.local_id) {

      if (this.local_id.includes("env")) {
        this.portal = true
        let data_uid = []
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        console.log(data_uid, 'data_uiddata_uid')

        // if (!data_uid) {
        //   alert('Something went Wrong...')
        //   this.$router.push('/')
        // }

        if (data_uid) {
          if (data_uid && data_uid[0].Return.length > 0) {
            this.rountresult = data_uid
          }
          else {
            this.onewayresult = data_uid
          }

          data_uid.forEach((v) => {
            this.local_id = v.selectId
            this.currency = v.currency;
            let totalpass = [];
            totalpass = parseFloat(v.adult) + parseFloat(v.child) + parseFloat(v.inf);
            console.log(totalpass, 'totalpass')
            this.totalTaxPassanger = parseFloat(
              parseFloat(v.Taxprice) * totalpass
            ).toFixed(2);
            this.totalbasePassanger = (parseFloat(v.price) * totalpass).toFixed(2);
            this.totalAmountpassenger = (
              parseFloat(this.totalbasePassanger) +
              parseFloat(this.totalTaxPassanger)
            ).toFixed(2);
          })
        }
        else{
          alert('Something went Wrong...')
          this.$router.push('/')
        }
      }

      else if (this.local_id.includes("multi")) {
        let data_uid = []
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
        this.multiresult.push(data_uid)
        this.multiData()
        console.log(data_uid, "multicity")
        }
        else{
          alert('Something went Wrong...')
          this.$router.push('/')
        }
      }

      else if (this.local_id) {
        let data_uid1 = []
        data_uid1 = JSON.parse(localStorage.getItem(`${this.local_id}`));
        console.log(data_uid1, 'data_uid1data_uid1')

        if (data_uid1) {
        this.rountresult.push(data_uid1);
        this.getadult();
        this.itineraryInfo = true;
        }
        else{
          alert('Something went Wrong...')
          this.$router.push('/')
        }
      }
    }

  },



  mounted() {
    this.passengerDetail();
    country1.forEach(v => {

      // v.name = this.getCountryname
      // console.log(v.name,'v.namev.namev.namev.name')
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code
      }
      this.countrycode.push(obj)
    })
    console.log(this.$route.params.id, "  this.$route.params.id");
    this.countryData();
  },
};
</script>
  
<style scoped>
* {
  font-family: system-ui, "-apple-system", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif !important;
}

.v-card {
  overflow: unset;
  z-index: auto;
  border-radius: 10px !important;
}

.v-tool {
  height: 50px;
  display: flex;
  justify-content: center;
}

>>>.dp__action_select {
  color: white !important;
  padding: 0px 7px;
}

>>>.dp__input {
  height: 40px;
  border-color: darkgray;
}

>>>.v-input__control {
  height: 40px;
}

>>>.v-field__field {
  height: 40px;
}

>>>.v-field__input {
  padding-top: 0 !important;
}

.vue-tel-input {
  height: 40px;
}

>>>.alternate-Details .v-input__control {
  height: 0px;
}

>>>.alternate-Details .v-input__details {
  display: none;
}

>>>.alternate-Details .v-selection-control {
  --v-selection-control-size: 22px;
}

>>>.alternate-Details .v-selection-control-group--inline {
  justify-content: end;
}

>>>.alternate-Details .v-selection-control__wrapper {
  font-size: 12px;
  width: 22px;
}

>>>.alternate-Details .v-label--clickable {
  font-size: 14px;
}

>>>.country-field .v-field--variant-outlined input {
  top: 32% !important;
}

.travel-view {
  text-decoration: underline;
  color: darkblue;
  cursor: pointer;
  font-weight: 600;
}

>>>.v-toolbar__content,
.v-toolbar__extension {
  align-items: center;
  height: 30px !important;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  transition: inherit;
  width: 100%;
}

/* Timeline  */

>>>.v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>>.v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>>.v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

>>>.dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

/* Timeline End  */

/* >>>.v-card {
        position: inherit !important;
      } */

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.card-table {
  width: 80% !important;
}

>>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

.fare-summery {
  position: sticky;
  width: 100%;
  z-index: 1;
  transition: top 0.5s;
}

>>>.v-selection-control .v-label {
  white-space: normal;
  word-break: normal;
  height: 100%;
  width: 100%;
}

.v-input--density-default {
  --v-input-control-height: 53px;
}

.v-selection-control--density-default {
  --v-selection-control-size: 30px;
}

.font-weight {
  font-weight: 500;
}

.timeline-vertical {
  display: none;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 42px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
  position: relative;
  top: 28px;
}

.tot-price {
  font-size: 18px;
  font-weight: 600;
}

>>>.continue-section .v-btn__content {
  font-size: 16px;
  font-weight: 500;
}

>>>.continue-section .v-btn__content {
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 481px) {
  .fare-show {
    display: none;
  }

  .continue-btn {
    display: none;
  }

  .terms-text {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .aircode-name .f-size-16 {
    font-size: 12px;
  }

  .aircode-name .f-size-13 {
    font-size: 9px;
  }

  .airline-date .f-size-16 {
    font-size: 12px;
  }

  .airline-date .f-size-11 {
    font-size: 9px;
  }

  .fare-show {
    display: block;
  }

  .fare-summery {
    display: none;
  }

  .card-space {
    margin: 2px;
  }

  .get-logo {
    width: 25px !important;
  }

  .air-name {
    font-size: 9px !important;
  }

  .air-id {
    font-size: 7px !important;
  }

  .airline-logo {
    width: 15px !important;
  }
}

@media only screen and (max-width: 768px) {
  .Timing-section .v-icon {
    font-size: 14 !important;
  }

  .Timing-section .f-size-14 {
    font-size: 11 !important;
  }

  .Timing-section .f-size-12 {
    font-size: 9 !important;
    line-height: normal !important;
  }

  .airline-logo {
    width: 20px !important;
  }

  .timeline-horizontal {
    display: none;
  }

  .timeline-vertical {
    display: inline-grid;
    height: 420px;
  }

  .content-overflow {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .content-overflow table {
    width: 100%;
    min-width: 750px;
    /* Adjust this value according to your table's content */
  }

  .card-table {
    width: 100% !important;
  }

  .air-name {
    font-size: 12px;
  }

  .air-id {
    font-size: 9px;
  }
}

/* Scroll Bar Styles */

.content-overflow::-webkit-scrollbar {
  width: 20px;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 25px;
}

/* minimalist design */
.content-overflow::-webkit-scrollbar {
  width: 10px;
}

.content-overflow::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* striped lines */
.content-overflow::-webkit-scrollbar {
  width: 20px;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

/* animated  */
.content-overflow::-webkit-scrollbar {
  width: 8px !important;
}

.content-overflow::-webkit-scrollbar-track {
  background: darkblue;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: lightgray;
}



>>>.v-autocomplete--single .v-field--variant-outlined input {
  top: 35%;
}





/* Scroll Bar Styles End */
</style>_
