import { createRouter, createWebHistory } from "vue-router";
// import parentCompo from '../components/parentCompo.vue'
import pagenot from "../components/pageNot.vue";
import date from "../components/dateCompo.vue";
import flightInfo from "../components/flightInfo.vue";
import confirmBook from "../components/confirmBook.vue";
// import TelInput from '../components/telInput.vue';
import bookingCofirm from "../components/bookingCofirm.vue";
import hello from "../components/HelloWorld.vue";
import search from "../components/SearchResult.vue";
import loader from '../components/loaderComponent.vue'
import travellerDetail from "@/components/travellerDetails.vue";
import test from "@/components/testCom.vue";
import termUse from "@/components/termUse.vue";
import aboutUs from "@/components/aboutUs.vue";
import privacyPolicy from "@/components/privacyPolicy.vue";
import contactPage from "@/components/contactPage.vue";
import pageError from "@/components/pageError.vue";
import dashBoard from "@/components/dashBoard.vue";
import myBooking from '@/components/myBooking.vue'
import dataloader from "@/components/dataLoder.vue"
import modify from "@/components/modifyCom.vue"
import newPassword from "@/components/newPassword.vue"
import bookingView from "@/components/bookingView.vue"
import liveChat from "@/components/liveChat.vue"

const routes = [
  {
    path: "/",
    component: hello,
  },
  {
    path: "/pageNot",
    component: pagenot,
  },
  {
    path: "/dataloader",
    component: dataloader,
  },
  {
    path: "/modify",
    component: modify,
  },
  {
    path: "/search",
    component: search,
  },
  {
    path: "/loader",
    component: loader,
  },
  {
    path: "/travellerDetail",
    component: travellerDetail,
  },
  {
    path: "/flightInfo",
    component: flightInfo,
  },

  // {
  //     path: '/flightInfo/:pass_id', // Define a dynamic route parameter ':pass_id'
  //     name: 'flightInfo',
  //     component: flightInfo, // Use your FlightInfo component
  //   },
  {
    path: "/confirmBook",
    component: confirmBook,
  },
  {
    path: "/date",
    component: date,
  },
  {
    path: "/test",
    component: test,
  },
  {
    path: "/bookingCofirm",
    component: bookingCofirm,
  },
  {
    path: "/page/terms-of-use",
    component: termUse,
  },
  {
    path: "/aboutUs",
    component: aboutUs,
  },
  {
    path: "/page/privacy-policy",
    component: privacyPolicy,
  },

  {
    path: "/contactPage",
    component: contactPage,
  },

  {
    path: "/pageError",
    component: pageError,
  },
  {
    path: "/dashboard",
    component: dashBoard,
  },
  {
    path: "/mybooking",
    component: myBooking,
  },

  {
    path: "/newPassword",
    component: newPassword,
  },
  {
    path:"/bookingView",
    component: bookingView,
  },
  {
    path:"/liveChat",
    component: liveChat,
  },

  { path: "/:pathMatch(.*)*", name: "NotFound", component: pagenot },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
